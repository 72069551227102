import axios from '../utils/request'
// 发送验证码
export const sendmessage = (phone, event = '') => axios.post('sms/send', { phone, event })
// 短信验证码登录
export const messagelogin = (data) => axios.post('user/login_pwd', data)
// 退出登录的接口
export const loginout = () => axios.post('user/logout')
// 微信登录获取参数 
export const wxloginConfig = () => axios.post('ajax/webappConfig')
// 获取到code之后的接口
export const gettoken = (code) => axios.get('user/scancallback', { code })
// 绑定手机号的接口
export const bindphons = (data) => axios.post('user/bindMobile', data)

//--------------------------
//注册
export const zhuceapi = (data) => axios.post('user/register', data) 
//省市区
export const ajaxarea = (data) => axios.post('ajax/get_area', data)   
//级别 类型
export const jibieleixing = (data) => axios.get('index/systemInfo', data)    
//认证
export const renzheng = (data) => axios.post('user/subQualification', data)    
//重置密码  
export const chongzhi = (data) => axios.post('user/resetpwd', data)      
//微信扫码绑定手机号
export const wxbdshouji = (data) => axios.post('user/bindMobile', data)      
