<template>
  <div class="contactme">
    <div class="contactme-img">
      <img src="@/assets/image/组 1121@2x.png" />
    </div>
    <div class="box v8">
      <div class="box-left"><img src="@/assets/image/组 1122@2x.png" /></div>
      <div class="box-right">
        <!-- 北京 -->
        <div class="box-right-top">
          <div id="bjmap" style="width: 100%; height: 100%"></div>
        </div>
        <!-- 石家庄 -->
        <div class="box-right-bottom">
          <div id="sjzmap" style="width: 100%; height: 1000px"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "contactme",
  data() {
    return {
      map: {},
    };
  },
  created() {},
  mounted() {
    this.bjMap();
    this.sjzMap();
  },
  methods: {
    //北京
    bjMap() {
      var that = this;
      that.map = new AMap.Map("bjmap", {
        //设置地图容器id
        resizeEnable: true, //是否监控地图容器尺寸变化
        zoom: 16,
        zoomEnable: false,
        center: [116.4943, 39.899812922735585], //初始化地图中心点位置 116.49360596958924
      });
      var icon = new AMap.Icon({
        size: new AMap.Size(30, 30), // 图标尺寸
        image: "https://i.hd-r.cn/950e82b0a25abaf6b0ad3a72a704e1f9.png", // Icon的图像
        imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
        imageSize: new AMap.Size(30, 30), // 根据所设置的大小拉伸或压缩图片
      });
      var marker = new AMap.Marker({
        icon,
        position: [116.4932, 39.9004],
      });
      that.map.add(marker);
    },
    //石家庄
    sjzMap() {
      var that = this;
      that.map = new AMap.Map("sjzmap", {
        //设置地图容器id
        resizeEnable: true, //是否监控地图容器尺寸变化
        zoom: 16,
        zoomEnable: false,
        center: [114.477, 38.0617], //初始化地图中心点位置
        //114.476059, 38.0681
      });
      var icon = new AMap.Icon({
        size: new AMap.Size(30, 30), // 图标尺寸
        image: "https://i.hd-r.cn/950e82b0a25abaf6b0ad3a72a704e1f9.png", // Icon的图像
        imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
        imageSize: new AMap.Size(30, 30), // 根据所设置的大小拉伸或压缩图片
      });
      var marker = new AMap.Marker({
        icon,
        position: [114.4759, 38.0688],
      });
      that.map.add(marker);
    },
  },
};
</script>
<style lang="less" scoped>
.contactme {
  .contactme-img {
    width: 100%;
    height: 387px;
    margin-bottom: 40px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  //内容
  .box {
    display: flex;
    .box-left {
      width: 511px;
      height: 479px;
      margin-right: 37px;
      box-shadow: 0px 0px 20px 1px rgba(132, 132, 132, 0.16);
      img {
        width: 100%;
        height: 100%;
      }
    }
    .box-right {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .box-right-top {
        width: 100%;
        height: 226px;
        background-color: pink;
        border-radius: 11px;
        overflow: hidden;
        cursor: pointer;
      }
      .box-right-bottom {
        width: 100%;
        height: 226px;
        background-color: pink;
        border-radius: 11px;
        overflow: hidden;
        cursor: pointer;
      }
    }
  }
}
</style>
