<template>
  <button :style="{ width: widths + 'px' }" @click="toothorpage">
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    widths: {
      default: 240,
      type: Number,
    },
  },
  methods: {
    toothorpage() {
      this.$emit("tothor");
    },
  },
};
</script>

<style lang="less" scoped>
button {
  cursor: pointer;
  display: block;
  margin: 0 auto;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  color: #ffffff;
  border-radius: 20px;
  margin-top: 60px;
  border: 0 none;
  background: linear-gradient(302deg, #4986fe 0%, #2fd2c1 100%);
}
</style>
