
export const regphone = (phone) => {
  const regphon = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
  return regphon.test(phone)
}
export const reguser = (user) => {
  const uPattern = /^[a-zA-Z0-9\u4E00-\u9FA5]{2,12}$/
  return uPattern.test(user)
}
export const regemail = (email) => {
  const uPattern = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
  return uPattern.test(email)
}
export const notblank = (value) => {
  if (value.trim() === '') {
    return false
  }
  return true
}
