<template>
  <div class="contain">
    <h5 class="usertitle">下载历史</h5>
    <!-- 搜索框 -->
    <div class="search">
      <el-input
        size="small"
        v-model="name"
        placeholder="请输入文件名称"
      ></el-input>
      <el-select size="small" v-model="model" placeholder="请选择类型">
        <el-option
          v-for="item in options"
          :key="item.file_type"
          :label="item.name"
          :value="item.file_type"
        >
        </el-option>
      </el-select>
      <el-button @click="search" class="changebg" size="small" type="primary"
        >搜索</el-button
      >
      <el-button size="small" type="info" @click="removeall(1, '', '')"
        >清空下载历史</el-button
      >
    </div>
    <!-- 底下又到了商品的页面 -->
    <!-- v-if="list.length > 0" -->
    <ul class="shops" v-if="list.length > 0">
      <li
        v-for="(item, index) in list"
        :key="item.id"
        @click.stop="todetal(item)"
        class="liebiao-li"
      >
        <!-- <div class="innershop">
          <div class="list_center">
            <img :src="item.doc_info.cover_img" alt="" />
          </div>
          <div class="jieshao">
            <span>{{ item.create_time }}</span>
            <div>
              <span
                style="margin-left: 10px"
                v-for="items in item.doc_info.med_type_list"
                :key="items.id"
                >{{ items.name }}</span
              >
            </div>
          </div>
        </div> -->
        <div class="liebiao">
          <div class="liebiao-img"><img :src="item.doc_info.cover_img" /></div>
          <div class="liebiao-img-box">
            <div class="liebiao-img-box-text">{{ item.doc_info.title }}</div>
            <div class="liebiao-img-box-bottom">
              <div class="liebiao-img-box-bottom-name">
                作者：{{ item.doc_info.file_author_name }}
              </div>
              <div class="liebiao-img-box-bottom-right">
                <div class="liebiao-img-box-bottom-right-img">
                  <img src="../../assets/image/icon/yan.png" />
                </div>
                <div class="liebiao-img-box-bottom-right-num">
                  {{ item.doc_info.view_num }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="tags">
          <span @click.stop="removeitem('', item.doc_id, index)">删除</span>
        </div>
      </li>
    </ul>
    <el-empty :image-size="200" v-else></el-empty>
    <!-- 这里是一个分页 -->
    <div class="changepage">
      <el-pagination
        :hide-on-single-page="true"
        :page-size="per_page"
        layout="prev, pager, next"
        :total="total"
        @current-change="changepage"
      >
      </el-pagination>
    </div>
    <!-- 删除对话框 -->
    <el-dialog
      title="删除"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <span class="poptext">确定要删除该条下载记录吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button class="changecolor" type="primary" @click="removeI()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="删除"
      :visible.sync="dialogVisibleall"
      width="30%"
      :before-close="handleCloseall"
    >
      <span class="poptext">确定要清空所有的下载记录吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleall = false">取 消</el-button>
        <el-button class="changecolor" type="primary" @click="removeA()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { download, clearhistory } from "../../api/user";
export default {
  name: "history",
  data() {
    return {
      options: this.$store.state.classfiy,
      list: [],
      model: "",
      name: "",
      // 对话框
      dialogVisible: false,
      dialogVisibleall: false,
      page: 1,
      total: 0,
      per_page: 0,
      all: "",
      id: "",
      index: "",
    };
  },
  created() {
    this.getdata();
  },
  methods: {
    search() {
      this.page = 1;
      this.getdata();
    },
    async getdata() {
      const res = await download(this.name, this.model, this.page, 9);
      console.log(res);
      if (res.code === 1) {
        this.list = res.data.data;
        this.total = res.data.total;
        this.per_page = res.data.per_page;
      } else {
        this.list = [];
        this.total = 0;
      }
    },
    // 跳转到详情页
    todetal(item) {
      if (item.doc_info.length == 0) {
        this.$message("平台已删除该文件");
      } else {
        this.$router.push("/detail?id=" + item.doc_id);
      }
    },
    async cleardata(all, id) {
      const res = await clearhistory(all, id);
      if (res.code === 1) {
        console.log("clreardata");
        this.getdata();
        this.$message.success("数据删除成功");
      } else {
        this.$message.success("数据删除失败");
      }
    },
    removeI() {
      this.cleardata(this.all, this.id);
      this.dialogVisible = false;
    },
    removeitem(all, id, index) {
      this.all = all;
      this.id = id;
      this.index = index;
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    handleCloseall() {
      this.dialogVisibleall = false;
    },
    removeA() {
      this.cleardata(this.all, this.id);
      this.dialogVisibleall = false;
    },
    removeall(all, id, index) {
      this.dialogVisibleall = true;
      this.all = all;
      this.id = id;
      this.index = index;
    },
    changepage(page) {
      this.page = page;
      this.getdata();
    },
  },
};
</script>

<style lang="less" scoped>
.contain {
  width: 100%;
  .search {
    margin-top: 34px;
    .el-input {
      width: 320px;
    }
    .el-select {
      margin-left: 18px;
    }
    .el-button {
      margin-left: 18px;
    }
    .changebg {
      border: 1px solid #2fd1c2;
      background: linear-gradient(136deg, #2fd1c2 0%, #4986fe 100%);
    }
  }
  .shops {
    width: 100%;
    overflow: hidden;
    margin-top: 40px;
    padding: 30px 27px;
    background-color: #f4f8f8;
    li {
      // width: 30%;
      // margin-right: 4.8%;\
      // margin-right: 64px;
      float: left;
      margin-bottom: 27px;
      transition: 0.3s;
      cursor: pointer;
      &:nth-child(odd) {
        margin-right: 64px;
      }
      &:hover {
        // transform: scale(102%);
        // box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.16);
      }
      // &:nth-child(3n) {
      //   margin-right: 0;
      // }
      .innershop {
        padding: 18px;
        border-radius: 9px;
        background: #ebebeb;
        .list_center {
          width: 100%;
          padding-bottom: 56.45%;
          overflow: hidden;
          position: relative;
          img {
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
          }
        }
        .jieshao {
          display: flex;
          justify-content: space-between;
          margin-top: 10px;
          span {
            color: #bebebe;
          }
        }
      }
      .tags {
        text-align: right;
        vertical-align: middle;
        margin-top: 12px;
        cursor: pointer;
        span {
          display: inline-block;
          width: 44px;
          height: 25px;
          border: 1px solid #db1818;
          border-radius: 3px;
          color: #db1818;
          line-height: 25px;
          text-align: center;
        }
      }
    }
  }
  @import url("../../assets/css/tankuan.less");

  .liebiao {
    width: 478px;
    padding: 18px;
    background-color: #fff;
    display: flex;
    border-radius: 9px 9px 9px 9px;
    .liebiao-img {
      width: 123px;
      height: 169px;
      margin-right: 20px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .liebiao-img-box {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .liebiao-img-box-text {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 7;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .liebiao-img-box-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .liebiao-img-box-bottom-name {
          color: #848484;
        }
        .liebiao-img-box-bottom-right {
          display: flex;
          align-items: center;
          .liebiao-img-box-bottom-right-img {
            width: 18px;
            height: 18px;
            margin-right: 6px;
            img {
              width: 18px;
              height: 18px;
            }
          }
          .liebiao-img-box-bottom-right-num {
            color: #848484;
          }
        }
      }
    }
  }
}

.changepage {
  text-align: center;
  margin-top: 20px;
}
</style>
