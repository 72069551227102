/* eslint-disable no-undef */
<template>
  <div class="loginbox">
    <!-- {{ user }} -->
    <!-- 注册登录 -->
    <div class="logins">
      <!-- <div class="longinout" v-if="!user.user.token">
        <div class="headerpic"><span class="iconfont">&#xe673;</span></div>
        <div class="logintext">
          <span class="lo" @click="loginVisible = true">微信登录</span>
          <span class="line"></span>
          <span class="lo" @click="signVisiblezhuce = true">手机登录</span>
        </div>
      </div> -->
      <div v-if="!user.user.token" class="longinout">
        <div class="longinout-box">
          <div class="longinout-zhuce" @click="xinzhuce = true">注册</div>
          <div class="longinout-heng"></div>
          <div class="longinout-denglu" @click="xinlogin = true">登录</div>
        </div>
      </div>
      <div
        v-else
        class="longinout"
        @mouseenter="userShow = true"
        @mouseleave="userShow = false"
      >
        <div class="headerpic">
          <div class="userpicn">
            <img :src="user.user.avatar" alt="" />
          </div>
        </div>
        <div class="username">{{ user.user.nickname }}</div>

        <!-- 修改部分啊啊啊啊啊啊啊啊 -->
        <div
          class="usercenter"
          :style="{ display: userShow === true ? 'block' : 'none' }"
        >
          <!-- <div class="usercenter" :style="{ display: 'block' }"> -->
          <div class="headerpics">
            <div class="userpic">
              <img :src="user.user.avatar" alt="" />
            </div>
            {{ user.user.nickname }}
          </div>
          <ul class="xin-list">
            <li
              class="xin-list-item"
              v-for="item in usercenter"
              :key="item.name"
              @click="tousercenter(item.path)"
            >
              <span class="iconfont">{{ item.icon }}</span>
              <p class="xin-list-item-name">{{ item.name }}</p>
            </li>
          </ul>
          <div class="xin-exit" @click="sendlonginout">安全退出</div>
        </div>
        <!-- 修改部分啊啊啊啊啊啊啊啊 -->
      </div>
    </div>
    <!-- 注册登录 -->
    <!-- 登录的弹出框 -->
    <el-dialog
      :show-close="false"
      :visible.sync="loginVisible"
      width="30%"
      :close-on-click-modal="false"
    >
      <div class="close iconfont" @click="loginVisible = false">&#xe60a;</div>
      <div class="small_logo">
        <img src="../assets/image/tsg.png" alt="" />
      </div>
      <div class="ercode" v-if="loginVisible">
        <wxlogin
          :appid="app_id"
          self_redirect="false"
          :scope="'snsapi_login'"
          :theme="'black'"
          :redirect_uri="redirect_uri"
          href="data:text/css;base64,
Ci5pbXBvd2VyQm94IC50aXRsZSB7CiBkaXNwbGF5OiBub25lOwp9Ci5pbXBvd2VyQm94IC5zdGF0dXMuc3RhdHVzX2Jyb3dzZXIgewogZGlzcGxheTogbm9uZTsKfQouaW1wb3dlckJveHsKICAgIHdpZHRoOiAxODBweDsKICAgIGhlaWdodDogMTgwcHg7Cn0KLmltcG93ZXJCb3ggLnFyY29kZSB7CiBib3JkZXI6IG5vbmU7CiB3aWR0aDogMTgwcHg7CiBoZWlnaHQ6IDE4MHB4OwogbWFyZ2luLXRvcDowOwp9Ci5pbXBvd2VyQm94IC5zdGF0dXN7CiBkaXNwbGF5OiBub25lCn0="
        >
        </wxlogin>
      </div>
      <div class="ticks">
        <span class="iconfont">&#xe637;</span> 微信扫码登录
      </div>
    </el-dialog>
    <el-dialog
      :show-close="false"
      :visible.sync="signVisiblezhuce"
      width="20%"
      :close-on-click-modal="false"
    >
      <div class="close iconfont" @click="signVisiblezhuce = false">
        &#xe60a;
      </div>
      <div class="small_logo">
        <img src="../assets/image/tsg.png" alt="" />
      </div>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
      >
        <el-form-item prop="phone">
          <el-input
            v-model="ruleForm.phone"
            placeholder="请输入手机号登录/注册"
          ></el-input>
        </el-form-item>
        <el-form-item prop="sms_code">
          <el-input placeholder="请输入验证码" v-model="ruleForm.sms_code">
            <template slot="append">
              <div @click="typeof codetick === 'string' ? setinterval('') : ''">
                {{ codetick === "获取验证码" ? "获取验证码" : codetick + "s" }}
              </div>
            </template>
          </el-input>
        </el-form-item>

        <el-form-item>
          <!-- size="medium" -->
          <el-button
            type="primary"
            round
            style="
              width: 80%;
              background: linear-gradient(122deg, #30d1c3 0%, #4886fd 100%);
              border-color: #fff;
            "
            @click="submitForm('ruleForm')"
            >登录</el-button
          >
        </el-form-item>
      </el-form>
      <!-- 其他登录的方式 -->
      <div class="athorlogin">其他登录方式</div>
      <p class="iconfont weixinlogin" @click="changeothorlogin">&#xe637;</p>
      <!-- 协议的内容 -->
      <el-checkbox v-model="checked"
        >我已阅读并接受 <span>《医信源隐私政策》</span>，<span
          >《医信源用户协议》</span
        ></el-checkbox
      >
    </el-dialog>
    <el-dialog
      :show-close="false"
      :visible.sync="signVisiblephone"
      width="490px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="small_logo">
        <img src="../assets/image/logo.png" alt="" />
      </div>
      <div class="discribtion" v-if="!bindphone">
        <div class="tick">
          <img src="../assets/image/tishi.png" alt="" />
          <p>为了您的账户安全，请绑定您的手机号以便丢失时可以直接找回。</p>
        </div>
        <div class="tobindphone">
          <button @click="bindphone = true">去绑定</button>
        </div>
      </div>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
        v-else
      >
        <div class="bindphpnetitle">绑定手机号</div>
        <el-form-item prop="phone">
          <el-input
            v-model="ruleForm.phone"
            placeholder="请输入要绑定的手机号"
          ></el-input>
        </el-form-item>
        <el-form-item prop="sms_code">
          <el-input placeholder="请输入验证码" v-model="ruleForm.sms_code">
            <template slot="append">
              <div
                @click="
                  typeof codeticks === 'string'
                    ? setintervals('bind_mobile')
                    : ''
                "
              >
                {{
                  codeticks === "获取验证码" ? "获取验证码" : codeticks + "s"
                }}
              </div>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <!-- size="medium" -->
          <el-button
            type="primary"
            round
            style="width: 80%"
            @click="submitphone('ruleForm')"
            >确定</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 登录登录登录登录登录登录登录登录登录登录登录登录登录登录登录登录登录登录登录登录登录登录 -->
    <el-dialog
      :show-close="false"
      :visible.sync="xinlogin"
      width="25%"
      :close-on-click-modal="false"
    >
      <div class="close iconfont" @click="xinlogin = false">&#xe60a;</div>
      <div class="small_logo">
        <img src="../assets/image/tsg.png" alt="" />
      </div>
      <div class="qiehuan">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="账号登录" name="zhanghao"></el-tab-pane>
          <el-tab-pane label="微信登录" name="weixin"></el-tab-pane>
        </el-tabs>
      </div>
      <div v-if="activeName == 'zhanghao'">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          class="demo-ruleForm"
        >
          <el-form-item prop="phone">
            <el-input
              v-model="ruleForm.phone"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>

          <el-form-item prop="password">
            <el-input
              v-model="ruleForm.password"
              show-password
              placeholder="请输入密码"
            ></el-input>
          </el-form-item>
          <div
            style="
              text-align: right;
              color: #4886fd;
              position: relative;
              top: -15px;
              cursor: pointer;
            "
            @click="xiugaimima = true"
          >
            忘记密码？
          </div>
          <!-- <el-form-item>  </el-form-item> -->
          <el-form-item>
            <!-- size="medium" -->
            <el-button
              type="primary"
              round
              style="
                width: 100%;
                background: linear-gradient(122deg, #30d1c3 0%, #4886fd 100%);
                border-color: #fff;
                border-radius: 5px;
              "
              @click="submitForm('ruleForm')"
              >登录</el-button
            >
          </el-form-item>
          <div style="text-align: right; position: relative; top: -15px">
            <span>没有账号？</span>
            <span
              style="color: #4886fd; cursor: pointer"
              @click="xinzhuce = true"
              >去注册</span
            >
          </div>
        </el-form>
        <!-- 其他登录的方式 -->
        <!-- 协议的内容 -->
        <el-checkbox v-model="checked"
          >我已阅读并接受 <span>《医信源隐私政策》</span>，<span
            >《医信源用户协议》</span
          ></el-checkbox
        >
      </div>
      <div v-if="activeName == 'weixin'">
        <div class="ticks">
          <span class="iconfont">&#xe637;</span> 微信扫码登录
        </div>
        <div class="ercode">
          <wxlogin
            :appid="app_id"
            self_redirect="false"
            :scope="'snsapi_login'"
            :theme="'black'"
            :redirect_uri="redirect_uri"
            href="data:text/css;base64,
Ci5pbXBvd2VyQm94IC50aXRsZSB7CiBkaXNwbGF5OiBub25lOwp9Ci5pbXBvd2VyQm94IC5zdGF0dXMuc3RhdHVzX2Jyb3dzZXIgewogZGlzcGxheTogbm9uZTsKfQouaW1wb3dlckJveHsKICAgIHdpZHRoOiAxODBweDsKICAgIGhlaWdodDogMTgwcHg7Cn0KLmltcG93ZXJCb3ggLnFyY29kZSB7CiBib3JkZXI6IG5vbmU7CiB3aWR0aDogMTgwcHg7CiBoZWlnaHQ6IDE4MHB4OwogbWFyZ2luLXRvcDowOwp9Ci5pbXBvd2VyQm94IC5zdGF0dXN7CiBkaXNwbGF5OiBub25lCn0="
          >
          </wxlogin>
        </div>
      </div>
    </el-dialog>
    <!-- 登录登录登录登录登录登录登录登录登录登录登录登录登录登录登录登录登录登录登录登录登录登录 -->
    <!-- 注册注册注册注册注册注册注册注册注册注册注册注册注册注册注册注册 -->
    <el-dialog
      :show-close="false"
      :visible.sync="xinzhuce"
      width="25%"
      :close-on-click-modal="false"
    >
      <div class="close iconfont" @click="xinzhuce = false">&#xe60a;</div>
      <div class="small_logo">
        <img src="../assets/image/tsg.png" alt="" />
      </div>
      <div class="xin-zhuce"><img src="../assets/image/zhuce.png" /></div>

      <el-form
        :model="zhuceForm"
        :rules="zhucerules"
        ref="zhuceForm"
        class="demo-ruleForm"
        style="margin-top: 36px"
      >
        <el-form-item prop="phone">
          <el-input
            v-model="zhuceForm.phone"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>

        <el-form-item prop="password">
          <el-input
            show-password
            v-model="zhuceForm.password"
            placeholder="请输入密码"
          ></el-input>
        </el-form-item>

        <el-form-item prop="checkPass">
          <el-input
            show-password
            v-model="zhuceForm.checkPass"
            placeholder="请再次输入密码"
          ></el-input>
        </el-form-item>

        <el-form-item prop="code">
          <el-input placeholder="请输入验证码" v-model="zhuceForm.code">
            <template slot="append">
              <div
                @click="
                  typeof codetick === 'string'
                    ? setintervalzhuce('register')
                    : ''
                "
              >
                {{ codetick === "获取验证码" ? "获取验证码" : codetick + "s" }}
              </div>
            </template>
          </el-input>
        </el-form-item>

        <el-form-item>
          <!-- size="medium" -->
          <el-button
            type="primary"
            round
            style="
              width: 100%;
              background: linear-gradient(122deg, #30d1c3 0%, #4886fd 100%);
              border-color: #fff;
              border-radius: 5px;
            "
            @click="zhuceFormClick('zhuceForm')"
            >注册</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 注册注册注册注册注册注册注册注册注册注册注册注册注册注册注册注册 -->

    <!--忘记密码忘记密码忘记密码忘记密码忘记密码忘记密码忘记密码忘记密码忘记密码忘记密码 -->
    <el-dialog
      :show-close="false"
      :visible.sync="xiugaimima"
      width="25%"
      :close-on-click-modal="false"
    >
      <div class="close iconfont" @click="xiugaimima = false">&#xe60a;</div>
      <div class="small_logo">
        <img src="../assets/image/tsg.png" alt="" />
      </div>
      <div class="xin-zhuce"><img src="../assets/image/xiugaimima.png" /></div>

      <el-form
        :model="wangjiForm"
        :rules="wangjiFormrules"
        ref="wangjiForm"
        class="demo-ruleForm"
        style="margin-top: 36px"
      >
        <el-form-item prop="phone">
          <el-input
            v-model="wangjiForm.phone"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>

        <el-form-item prop="captcha">
          <el-input placeholder="请输入验证码" v-model="wangjiForm.captcha">
            <template slot="append">
              <div
                @click="
                  typeof codetick === 'string'
                    ? chongzhisetinterval('resetpwd')
                    : ''
                "
              >
                {{ codetick === "获取验证码" ? "获取验证码" : codetick + "s" }}
              </div>
            </template>
          </el-input>
        </el-form-item>

        <el-form-item prop="password">
          <el-input
            v-model="wangjiForm.password"
            placeholder="请输入密码"
            show-password
          ></el-input>
        </el-form-item>

        <el-form-item prop="newpassword">
          <el-input
            v-model="wangjiForm.newpassword"
            placeholder="请再次输入密码"
            show-password
          ></el-input>
        </el-form-item>

        <el-form-item>
          <!-- size="medium" -->
          <el-button
            type="primary"
            round
            style="
              width: 100%;
              background: linear-gradient(122deg, #30d1c3 0%, #4886fd 100%);
              border-color: #fff;
              border-radius: 5px;
            "
            @click="wangjiFormFormClick('wangjiForm')"
            >确认</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 忘记密码忘记密码忘记密码忘记密码忘记密码忘记密码忘记密码忘记密码忘记密码忘记密码  -->

    <!--微信扫码绑定手机 微信扫码绑定手机 微信扫码绑定手机 微信扫码绑定手机 微信扫码绑定手机 -->
    <el-dialog
      :show-close="false"
      :visible.sync="wxbangding"
      width="25%"
      :close-on-click-modal="false"
    >
      <!-- <div class="close iconfont" @click="wxbangding = false">&#xe60a;</div> -->
      <div class="small_logo">
        <img src="../assets/image/tsg.png" alt="" />
      </div>
      <div class="xin-zhuce"><img src="../assets/image/bangding.png" /></div>

      <div class="tongguo">
        <img src="../assets/image/tongguo.png" />
      </div>
      <el-form
        :model="wxbangdingForm"
        :rules="wxbangdingrules"
        ref="wxbangding"
        class="demo-ruleForm"
        style="margin-top: 36px"
      >
        <el-form-item prop="phone">
          <el-input
            v-model="wxbangdingForm.phone"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>

        <el-form-item prop="code">
          <el-input placeholder="请输入验证码" v-model="wxbangdingForm.code">
            <template slot="append">
              <div
                @click="
                  typeof codetick === 'string'
                    ? wxbdsetinterval('bind_mobile')
                    : ''
                "
              >
                {{ codetick === "获取验证码" ? "获取验证码" : codetick + "s" }}
              </div>
            </template>
          </el-input>
        </el-form-item>

        <el-form-item prop="password">
          <el-input
            v-model="wxbangdingForm.password"
            placeholder="请输入密码"
            show-password
          ></el-input>
        </el-form-item>

        <el-form-item prop="checkPass">
          <el-input
            v-model="wxbangdingForm.checkPass"
            placeholder="请再次输入密码"
            show-password
          ></el-input>
        </el-form-item>

        <el-form-item>
          <!-- size="medium" -->
          <el-button
            type="primary"
            round
            style="
              width: 100%;
              background: linear-gradient(122deg, #30d1c3 0%, #4886fd 100%);
              border-color: #fff;
              border-radius: 5px;
            "
            @click="wxbangdingFormClick('wxbangding')"
            >确认</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <!--微信扫码绑定手机 微信扫码绑定手机 微信扫码绑定手机 微信扫码绑定手机 微信扫码绑定手机 -->

    <!--实名认证 实名认证 实名认证 实名认证 实名认证 实名认证 实名认证 实名认证 实名认证-->
    <el-dialog
      :show-close="false"
      :visible.sync="shiming"
      width="25%"
      :close-on-click-modal="false"
    >
      <div class="close iconfont" @click="shiming = false">&#xe60a;</div>
      <div class="small_logo">
        <img src="../assets/image/tsg.png" alt="" />
      </div>
      <div class="xin-zhuce"><img src="../assets/image/shiming.png" /></div>

      <div class="renzheng-box">
        <el-form
          :model="renzhengForm"
          :rules="renzhengFormrules"
          ref="renzhengForm"
          class="demo-ruleForm"
          style="margin-top: 36px"
        >
          <el-form-item prop="name">
            <el-input
              v-model="renzhengForm.name"
              placeholder="请输入用户名"
            ></el-input>
          </el-form-item>

          <el-form-item prop="phone">
            <el-input
              v-model="renzhengForm.phone"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-cascader
              style="width: 100%"
              v-model="renzhenValue"
              placeholder="请选择省份/城市"
              :options="options"
              :props="categorProps"
            ></el-cascader>
          </el-form-item>

          <el-form-item prop="hospital">
            <el-input
              v-model="renzhengForm.hospital"
              placeholder="请输入医院名称"
            ></el-input>
          </el-form-item>

          <el-form-item prop="job">
            <el-input
              v-model="renzhengForm.job"
              placeholder="请输入职业"
            ></el-input>
          </el-form-item>

          <el-form-item prop="hospital_level">
            <el-select
              style="width: 100%"
              v-model="renzhengForm.hospital_level"
              placeholder="请选择医院级别"
            >
              <el-option
                v-for="(item, index) in yiyuanjibie"
                :key="index"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item prop="department">
            <el-input
              v-model="renzhengForm.department"
              placeholder="请输入科室"
            ></el-input>
          </el-form-item>

          <el-form-item prop="job_title">
            <el-input
              v-model="renzhengForm.job_title"
              placeholder="请输入职称"
            ></el-input>
          </el-form-item>

          <el-form-item prop="cert_type" label="证书类型上传：">
            <el-select
              v-model="renzhengForm.cert_type"
              placeholder="请选择证书类型"
            >
              <el-option
                v-for="(item, index) in zhengshuleixing"
                :key="index"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item prop="cert_file" label="上传文件">
            <el-upload
              class="upload-demo"
              :action="action"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :before-remove="beforeRemove"
              :on-success="handleAvatarSuccess"
              multiple
              :limit="10"
              :on-exceed="handleExceed"
              :file-list="fileList"
              :data="uploadData"
              :before-upload="wenBeforeUpload"
              :on-progress="onProgressTest"
            >
              <el-button
                class="renzheng-btn"
                size="small"
                type="primary"
                v-prevent="1500"
                >点击上传</el-button
              >
            </el-upload>
            <!-- <el-upload
              :http-request="uploadfile"
              class="avatar-uploader"
              action=""
              :show-file-list="false"
            >
              <div v-if="!isShowfile_path">
                <img
                  v-if="renzhengForm.cert_file"
                  :src="renzhengForm.cert_file"
                  class="avatar wow bounceInU"
                />
 
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </div>
              <div v-else>
                <el-progress
                  :width="85"
                  type="circle"
                  :percentage="progressPercentfile_path"
                ></el-progress>
              </div>
            </el-upload> -->
          </el-form-item>
          <div
            style="
              margin-top: 160px;
              display: flex;
              align-items: center;
              justify-content: space-between;
            "
          >
            <el-button
              type="primary"
              round
              style="
                width: 40%;
                background: linear-gradient(122deg, #30d1c3 0%, #4886fd 100%);
                border-color: #fff;
                border-radius: 5px;
              "
              @click="renzhengFormClick('renzhengForm')"
              >确认</el-button
            >
            <el-button
              round
              style="
                width: 40%;
                border-radius: 5px;
                background: #f5f5f5;
                border-color: #c2cbde;
                color: #546a8a;
              "
              @click="shiming = false"
              >跳过</el-button
            >
          </div>
        </el-form>
      </div>
    </el-dialog>
    <!--实名认证 实名认证 实名认证 实名认证 实名认证 实名认证 实名认证 实名认证 实名认证 -->
  </div>
</template>
<script>
import { mapState } from "vuex";
import { interval } from "../mixin/index";
import wxlogin from "vue-wxlogin";
import * as qiniu from "qiniu-js";
// 引入七牛云
import { docClass, sevenCloud, submits } from "../api/createCenter";
import {
  sendmessage,
  messagelogin,
  loginout,
  wxloginConfig,
  gettoken,
  bindphons,
  //开始
  zhuceapi,
  ajaxarea,
  jibieleixing,
  renzheng,
  chongzhi,
  wxbdshouji,
  //结束
} from "@/api/login.js";
export default {
  components: {
    wxlogin,
  },
  mounted() {},
  data() {
    // 验证手机号 password
    var validatephone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号码"));
      } else if (
        /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/.test(
          value
        ) === false
      ) {
        callback(new Error("请输入正确手机号"));
      } else {
        callback();
      }
    };
    var validatepassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    //------------------------------------------
    // 注册验证
    var zhucephone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号码"));
      } else if (
        /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/.test(
          value
        ) === false
      ) {
        callback(new Error("请输入正确手机号"));
      } else {
        callback();
      }
    };
    //密码
    var zhucepassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    //密码
    var zhucecheckPass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.zhuceForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    var zhuceCode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入验证码"));
      } else if (/^\d{4,6}$/.test(value) === false) {
        callback(new Error("请输入正确验证码"));
      } else {
        callback();
      }
    };

    //忘记密码 忘记密码
    var wangjiphone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号码"));
      } else if (
        /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/.test(
          value
        ) === false
      ) {
        callback(new Error("请输入正确手机号"));
      } else {
        callback();
      }
    };
    var wangjipassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };

    var wangjicheckPass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.wangjiForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    var wangjiCode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入验证码"));
      } else if (/^\d{4,6}$/.test(value) === false) {
        callback(new Error("请输入正确验证码"));
      } else {
        callback();
      }
    };

    //微信绑定手机号
    var wxphone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号码"));
      } else if (
        /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/.test(
          value
        ) === false
      ) {
        callback(new Error("请输入正确手机号"));
      } else {
        callback();
      }
    };
    var wxpassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    var wxcheckPass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.wxbangdingForm.checkPass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    var wxcode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入验证码"));
      } else if (/^\d{4,6}$/.test(value) === false) {
        callback(new Error("请输入正确验证码"));
      } else {
        callback();
      }
    };
    return {
      //-----------------------------
      action: `https://up-z1.qiniup.com`, //http://up-z1.qiniup.com
      fileList: [],
      uploadData: { key: "", token: "" },
      aaa: "",
      //-----------------------------
      xinlogin: false, //登录弹窗
      xinzhuce: false,
      activeName: "zhanghao",
      //--------------------------
      isShowfile_path: false, //上文件进度条
      progressPercentfile_path: 0, //上文件进度条
      loginVisible: false, // 登录的弹出框
      signVisiblezhuce: false, // 注册的弹出框，
      signVisiblephone: false,
      ruleForm: {
        phone: "",
        password: "",
      },
      // 表单校验规则
      rules: {
        phone: [{ validator: validatephone, trigger: "blur" }],
        password: [{ validator: validatepassword, trigger: "blur" }],
      },
      // 协议的内容
      checked: true,
      usercenter: [
        {
          name: "下载历史",
          icon: "\ue754",
          path: "/user/history",
        },
        {
          name: "我的资源单",
          icon: "\ue609",
          path: "/user/resource",
        },
        {
          name: "个人信息",
          icon: "\ue613",
          path: "/user",
        },
        {
          name: "认证信息",
          icon: "\ue63e",
          path: "/user/certification",
        },
        {
          name: "投稿中心",
          icon: "\ue73c", //65b 628
          path: "/user/createrCenter",
        },
        {
          name: "积分管理",
          icon: "\ue608", //61c
          path: "/user/integral",
        },
      ],
      userShow: false, // 是否展示个人中心
      app_id: "",
      redirect_uri: "",
      bindphone: false, // 绑定手机
      /////////////////////////////////////////////////////////
      //注册
      zhuceForm: {
        phone: "", //手机号
        checkPass: "", //验证密码
        password: "", //密码
        code: "", //短信验证码
      },
      //注册验证
      zhucerules: {
        phone: [{ validator: zhucephone, trigger: "blur" }],
        password: [{ validator: zhucepassword, trigger: "blur" }],
        checkPass: [{ validator: zhucecheckPass, trigger: "blur" }],
        code: [{ validator: zhuceCode, trigger: "blur" }],
      },
      //修改密码 修改密码
      xiugaimima: false,
      wangjiForm: {
        captcha: "",
        newpassword: "",
        password: "",
        phone: "",
      },
      wangjiFormrules: {
        phone: [{ validator: wangjiphone, trigger: "blur" }],
        password: [{ validator: wangjipassword, trigger: "blur" }],
        newpassword: [{ validator: wangjicheckPass, trigger: "blur" }],
        captcha: [{ validator: wangjiCode, trigger: "blur" }],
      },
      //微信扫码绑定
      wxbangding: false,
      wxbangdingForm: {
        code: "",
        checkPass: "",
        password: "",
        phone: "",
      },
      wxbangdingrules: {
        phone: [{ validator: wxphone, trigger: "blur" }],
        password: [{ validator: wxpassword, trigger: "blur" }],
        checkPass: [{ validator: wxcheckPass, trigger: "blur" }],
        code: [{ validator: wxcode, trigger: "blur" }],
      },
      //实名认证
      isShowCover_img: false, //上图图片进度条
      progressPercentCover_img: 0, //上图图片进度条
      config: "", // 七牛云的配置项
      renzhenValue: [],
      categorProps: {
        value: "id",
        label: "name",
        children: "children",
      },
      shiming: false,
      renzhengForm: {
        name: "", //用户名
        phone: "", //手机号
        hospital: "", //医院名称
        job: "", //职业
        hospital_level: "", //医院级别
        department: "", //科室
        job_title: "", //职称
        cert_type: "", //证书
        cert_file: "", //文件
        province_id: "", //省
        city_id: "", //市
        area_id: "", //区
      },
      yiyuanjibie: [], //[]
      zhengshuleixing: [], //[]
      renzhengFormrules: {
        name: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        hospital: [
          { required: true, message: "请输入医院名称", trigger: "blur" },
        ],
        job: [{ required: true, message: "请输入职业", trigger: "blur" }],
        hospital_level: [
          { required: true, message: "请选医院级别", trigger: "change" },
        ],
        department: [
          { required: true, message: "请输入科室", trigger: "blur" },
        ],
        job_title: [{ required: true, message: "请输入职称", trigger: "blur" }],
        cert_type: [
          { required: true, message: "请上传证书类型", trigger: "change" },
        ],
        cert_file: [
          { required: true, message: "请上传文件", trigger: "change" },
        ],
      },
      options: [],
    };
  },
  mixins: [interval],
  computed: {
    ...mapState(["user"]),
  },
  created() {
    this.getconfig();

    // this.shengshiqu(); //省市区
    this.jiebie(); //类型 级别
  },
  watch: {
    $route() {
      if (this.$route.query.code) {
        if (!this.$store.state.user.user) {
          this.wxgettoken(this.$route.query.code);
        }
      }
    },
    //微信扫码绑定手机号
    "$store.state.user.user": {
      handler(newvalue, oldvalue) {
        if (newvalue && newvalue.is_need_bind_mobile) {
          this.xinlogin = false;
          this.wxbangding = true;
        } else {
          this.xinlogin = false;
          this.wxbangding = false;
        }
      },
      immediate: true,
    },
  },
  methods: {
    //------------------------------------------------------------------------------
    //点击每一个上传后的文件
    handlePreview(file) {
      console.log(file);
    },
    //移除后
    handleRemove(file, fileList) {
      this.aaa = "";
      let qiniuUrl = "";
      let apiUrl = "";
      fileList.map((item) => {
        if (item.response != undefined) {
          qiniuUrl += `https://doc.medsky.cn/${item.response.key}` + ",";
        }
        if (item.url) {
          apiUrl += item.url + ",";
        }
      });
      this.aaa = apiUrl + qiniuUrl;
      this.renzhengForm.cert_file = this.aaa;
    },
    //移除文件 提示
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    //文件上传成功
    handleAvatarSuccess(res, file) {
      if (res.key != "" || res.key != undefined || res.key != null) {
        this.aaa += `https://doc.medsky.cn/${res.key}` + ",";
        this.renzhengForm.cert_file += `https://doc.medsky.cn/${res.key}` + ",";
        // this.fileList.push(file)
        this.$message({
          message: "文件上传成功",
          type: "success",
        });
      } else {
        this.$message.error("上传失败");
      }
    },
    //超出长度提示
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 10 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    //上传前
    async wenBeforeUpload(file) {
      const is50M = file.size / 1024 / 1024 < 50;
      if (!is50M) {
        this.$message.error("上传图片大小不能超过 50MB!");
        return false;
      }
      // console.log(file);
      let suffix = file.name.substring(file.name.lastIndexOf(".")); //.txt
      //获取七牛云的token
      const res = await sevenCloud();
      this.uploadData.token = res.data.token;
      //file.uid +
      this.uploadData.key = file.uid + file.name;
      return true;
    },
    //文件上传时
    onProgressTest(event, file, fileList) {
      let index = 0;
      // 只有fileList 跟file 里面的uid是唯一的互相对应的
      fileList.forEach((v, i) => {
        if (v.uid === file.uid) {
          index = i;
        }
      });
      // 获取动态的进度
      let percent = parseInt(event.percent);
      this.$nextTick(() => {
        setTimeout(() => {
          if (percent >= 98) {
            // 当动态的进度超过99就获取dom 让值一直显示99直到上传成功
            let dom = document.getElementsByClassName("el-upload-list__item")[
              index
            ];
            let progressText = dom.getElementsByClassName("el-progress__text");
            if (progressText && progressText.length) {
              progressText[0].style.opacity = 0;
              progressText[0].innerText = "98%";
              progressText[0].style.opacity = 1;
            }
          }
        });
      });
    },
    //------------------------------------------------------------------------------
    handleClick(tab, event) {
      console.log(tab, event);
    },
    // 调用获取code的接口
    async wxgettoken(code) {
      const res = await gettoken(code);
      console.log("微信扫码微信扫码微信扫码微信扫码微信扫码微信扫码", res);
      if (res.code === 1) {
        this.$store.commit("user/setUsers", res.data);
        this.loginVisible = false;
      }
    },
    // 微信参数
    async getconfig() {
      const res = await wxloginConfig();
      if (res.code === 1) {
        this.app_id = res.data.app_id;
        console.log("aaa", res);
        this.redirect_uri = encodeURIComponent(res.data.redirect_uri);
      }
    },
    changeothorlogin() {
      const that = this;
      this.signVisiblezhuce = false;
      setTimeout(() => {
        that.loginVisible = true;
      }, 200);
    },
    //退出
    async sendlonginout() {
      const res = await loginout();
      console.log(res);
      if (res.code === 1) {
        this.$message.success(res.msg);
        this.$store.commit("user/removeuser");
        this.$router.replace("/");
        this.userShow = false;
      }
    },
    // 提交表单
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.checked === false) {
            this.$message({
              message: "请勾选医信源用户协议",
              type: "error",
            });
          } else {
            const res = await messagelogin(this.ruleForm);
            console.log(res);
            if (res.code === 1) {
              this.$store.commit("user/setUsers", res.data.userinfo);
              this.xinlogin = false;
              this.$message.success(res.msg);
              // 注册成功将返回 qualification字段 :
              // 认证状态;
              // 0=未提交资料
              // 1=已提交待认证
              // 2=认证通过
              // 3=认证驳回
              if (res.data.userinfo.qualification == 0) {
                this.shengshiqu();
                this.jiebie();
                this.getsevencload(); //七牛云
                this.shiming = true;
              }
            } else {
              // this.xinlogin = false;
              this.$message({
                message: res.msg,
                type: "error",
              });
            }
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    submitphone(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await bindphons(this.ruleForm);
          if (res.code === 1) {
            if (res.data.is_new_token) {
              this.$store.commit("user/setUsers", res.data.data);
            } else {
              const username = this.$store.state.user.user;
              username.is_need_bind_mobile = false;
              this.$store.commit("user/setUsers", username);
            }
            this.$message({
              message: "手机号绑定成功",
              type: "success",
            });
            this.ruleForm.sms_code = "";
            this.ruleForm.phone = "";
          } else {
            // this.signVisiblezhuce = false
            this.$message({
              message: res.msg,
              type: "error",
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    tousercenter(path) {
      this.$router.push(path);
    },
    //-------------------------------------------------
    //省市区
    async shengshiqu() {
      const res = await ajaxarea();
      this.options = res.data;
    },
    //上传文件
    uploadfile(el) {
      this.isShowfile_path = true;
      this.upload(el, "file_path");
    },
    upload(el, code) {
      const that = this;
      const observable = qiniu.upload(el.file, el.file.name, this.config.token);
      const observer = {
        next(res) {
          //上传文件
          if (code === "file_path") {
            that.progressPercentfile_path = 0;
            that.progressPercentfile_path = Math.trunc(
              Number(((res.total.loaded / res.total.size) * 100).toFixed(2))
            );
          }
        },
        error(err) {
          console.log("失败了吗", err);
        },
        complete(res) {
          console.log("测试测试测试", that.config.cdn + res.key);
          that.renzhengForm.cert_file = that.config.cdn + res.key;
          that.animation();
          that.$message.success("文件上传成功");
        },
      };
      observable.subscribe(observer);
    },
    //注册
    zhuceFormClick(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          //成功
          const res = await zhuceapi(this.zhuceForm);
          console.log("注册注册注册注册", res.data.userinfo);
          if (res.code == 1) {
            this.$store.commit("user/setUsers", res.data.userinfo);
            this.xinzhuce = false;
            this.$message.success(res.msg);
            if (res.data.userinfo.qualification == 0) {
              this.$store.commit("user/setUsers", res.data.userinfo);
              this.shengshiqu();
              this.getsevencload(); //七牛云
              this.shiming = true;
            }
          } else {
            this.$message({
              message: res.msg,
              type: "error",
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //忘记密码
    wangjiFormFormClick(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.chongzhiApi();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //忘记密码接口
    async chongzhiApi() {
      const res = await chongzhi(this.wangjiForm);
      if (res.code == 1) {
        this.xiugaimima = false;
        this.$message.success(res.msg);
      } else {
        this.$message.error(res.msg);
      }
    },
    //微信绑定确定
    wxbangdingFormClick(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log("微信登录走到这里了");
          this.wxbdshoujiApi();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //微信绑定手机确定
    async wxbdshoujiApi() {
      const res = await wxbdshouji(this.wxbangdingForm);
      console.log("确认手机号返回的数据", res);
      if (res.code === 1) {
        this.$store.commit("user/setUsers", res.data.data);
        this.wxbangding = false;
        this.$message.success(res.msg);
        if (res.data.data.qualification == 0) {
          this.shengshiqu();
          this.jiebie();
          this.shiming = true;
        }
      } else {
        // this.wxbangding = false;
        this.xinlogin = false;
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
    //认证
    renzhengFormClick(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.renzhengForm.cert_file = this.aaa.substring(
            0,
            this.aaa.length - 1
          );
          this.renzhengForm.province_id = this.renzhenValue[0];
          this.renzhengForm.city_id = this.renzhenValue[1];
          this.renzhengForm.area_id = this.renzhenValue[2];
          this.renzhengApi();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //认证接口
    async renzhengApi() {
      const res = await renzheng(this.renzhengForm);
      if (res.code == 1) {
        console.log(res);
        this.shiming = false;
        this.$message.success(res.msg);
      } else {
        this.$message.error(res.msg);
      }
    },

    //微信验证码
    wxbdsetinterval(type) {
      if (this.wxbangdingForm.phone) {
        console.log(typeof this.codetick);
        this.codetick = 60;
        clearInterval(this.inter);
        this.inter = setInterval(() => {
          this.codetick -= 1;
          if (this.codetick < 1) {
            clearInterval(this.inter);
            this.codetick = "获取验证码";
          }
        }, 1000);
        this.getcodewxbd(type);
      } else {
        this.$message.warning("请输入手机号");
      }
    },
    // 微信验证码
    async getcodewxbd(type) {
      const res = await sendmessage(this.wxbangdingForm.phone, type);
      console.log("发送验证码", res);
      if (res.code === 1) {
        this.$message({
          message: res.msg,
          type: "success",
        });
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },

    //重置验证码
    chongzhisetinterval(type) {
      if (this.wangjiForm.phone) {
        console.log(typeof this.codetick);
        this.codetick = 60;
        clearInterval(this.inter);
        this.inter = setInterval(() => {
          this.codetick -= 1;
          if (this.codetick < 1) {
            clearInterval(this.inter);
            this.codetick = "获取验证码";
          }
        }, 1000);
        this.getcodechongzhi(type);
      } else {
        this.$message.warning("请输入手机号");
      }
    },
    // 重置验证码
    async getcodechongzhi(type) {
      const res = await sendmessage(this.wangjiForm.phone, type);
      console.log("发送验证码", res);
      if (res.code === 1) {
        this.$message({
          message: res.msg,
          type: "success",
        });
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },

    //注册验证码
    setintervalzhuce(type) {
      if (this.zhuceForm.phone) {
        console.log(typeof this.codetick);
        this.codetick = 60;
        clearInterval(this.inter);
        this.inter = setInterval(() => {
          this.codetick -= 1;
          if (this.codetick < 1) {
            clearInterval(this.inter);
            this.codetick = "获取验证码";
          }
        }, 1000);
        this.getcodezhuce(type);
      } else {
        this.$message.warning("请输入手机号");
      }
    },
    // 注册获取验证码
    async getcodezhuce(type) {
      const res = await sendmessage(this.zhuceForm.phone, type);
      console.log("发送验证码", res);
      if (res.code === 1) {
        this.$message({
          message: res.msg,
          type: "success",
        });
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },

    //级别 类型
    async jiebie() {
      const res = await jibieleixing();
      this.yiyuanjibie = res.data.hospital_level;
      this.zhengshuleixing = res.data.cert_file_type;
    },
    // 引入七牛云的token
    async getsevencload() {
      const res = await sevenCloud();
      if (res.code === 1) {
        this.config = res.data;
      }
    },
    //上传封面图
    uploadpic(el) {
      this.isShowCover_img = true;
      this.upload(el, "cover_img");
    },
    //上传文件
    animation() {
      this.$nextTick(() => {
        new this.$wow.WOW({
          boxClass: "wow",
          animateClass: "animated",
          offset: 0,
          mobile: true,
          live: true,
        }).init();
        this.isShowfile_path = false; //上传文件
        this.$refs.renzhengForm.validateField("cert_file"); //上传文件后对表单从新验证
      });
    },
  },
};
</script>

<style lang="less" scoped>
.loginbox {
  height: 100%;
}
.logins {
  width: 100%;
  height: 100%;
  .longinout {
    display: flex;
    align-items: center;
    justify-content: right;
    width: 100%;
    height: 100%;
    position: relative;
    min-width: 100px;
    cursor: pointer;
    // background: red;
    .usercenter {
      position: absolute;
      width: 331px;
      // height: 287px;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
      border-radius: 5px;
      top: 60px;
      right: -40px;
      z-index: 10000;
      background: #fff;
      display: none;
      .headerpics {
        height: 109px;
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #363636;
        background: #fff8f8;
        padding: 20px;
        .userpic {
          width: 39px;
          height: 39px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 10px;
          img {
            width: 100%;
          }
        }
      }
      .classfiy {
        display: flex;
        align-items: center;
        height: 129px;
        padding: 4px;
        border-bottom: 1px solid #f1f1f1;
        cursor: pointer;
        li {
          text-align: center;
          flex: 1;
          padding: 10px 0px;
          border-radius: 8px;
          &:hover {
            background: #f1f1f1;
            transition: all 1s;
          }
          span {
            font-size: 18px;
          }
          p {
            font-size: 13px;
            color: #929292;
            margin-top: 4px;
          }
        }
      }
      .exit {
        text-align: right;
        padding: 0 35px;
        color: #929292;
        padding-top: 15px;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .headerpic {
      cursor: pointer;
      color: #d5d5d5;
      border: 1px solid #d5d5d5;
      height: 36px;
      width: 36px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      .userpicn {
        width: 30px;
        height: 30px;
        overflow: hidden;
        border-radius: 50%;
        img {
          width: 30px;
        }
      }
      span {
        font-size: 30px;
      }
    }
    .username {
      font-size: 14px;
      color: #333;
      margin-left: 10px;
    }
    .logintext {
      flex: 1;
      width: 130px;
      height: 33px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      border: 1px solid #4d4d4d;
      margin-left: 11px;
      .lo {
        flex: 1;
        text-align: center;
        cursor: pointer;
      }
      .line {
        width: 1px;
        height: 16px;
        background: #4d4d4d;
      }
    }

    //新的注册登录
    .longinout-box {
      padding: 7px 11px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      border: 1px solid #4d4d4d;
      margin-left: 11px;
    }
    .longinout-zhuce {
      color: #4d4d4d;
      cursor: pointer;
      &:hover {
        color: #4987fe;
      }
    }
    .longinout-heng {
      margin: 0 6px;
      width: 1px;
      height: 12px;
      background-color: #4d4d4d;
    }
    .longinout-denglu {
      color: #4d4d4d;
      cursor: pointer;
      &:hover {
        color: #4987fe;
      }
    }
  }
}
/deep/.el-dialog__header {
  display: none;
}
/deep/ .el-dialog__body {
  position: relative;
  .close {
    // color: #fff;
    color: #000;
    position: absolute;
    right: 15px;
    top: 15px;
    // right: -44px;
    // top: -20px;
    cursor: pointer;
  }
  .small_logo {
    width: 142px;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }
  .discribtion {
    margin: 35px;
    .tick {
      display: flex;
      align-items: flex-start;
      img {
        width: 38px;
      }
      p {
        flex: 1;
        margin-left: 8px;
        color: #212529;
        font-size: 20px;
        line-height: 39px;
      }
    }
    .tobindphone {
      text-align: center;
      margin-top: 64px;
      button {
        width: 136px;
        height: 48px;
        background: linear-gradient(122deg, #30d1c3 0%, #4886fd 100%);
        border-radius: 24px;
        font-size: 18px;
        color: #ffffff;
        border: 0 none;
      }
    }
  }
  .ercode {
    width: 200px;
    height: 200px;
    padding: 7px;
    border: 1px solid #ebebeb;
    border-radius: 6px;
    margin: 30px auto 30px;
    img {
      width: 100%;
    }
  }
  .ticks {
    color: #9f9f9f;
    text-align: center;
    margin-top: 30px;
    span {
      color: #5ac43a;
    }
  }
}
// 里边的表单样式
.el-form {
  box-sizing: border-box;
  padding: 0 10%;
  margin-top: 52px;
  /deep/ input,
  /deep/ .el-input-group__append {
    border: 0 none;
    border-bottom: 1px solid #dfdfdf;
    border-radius: 0;
    background: #fff;
  }
  /deep/ .el-input-group__append {
    cursor: pointer;
    div {
      width: 60px;
      color: #4886fd;
      text-align: center;
    }
  }
  /deep/ .el-form-item__content {
    text-align: center;
    button {
      margin-top: 40px;
    }
  }
}
.athorlogin {
  color: #bababa;
  text-align: center;
  margin-top: 47px;

  &::after,
  &::before {
    content: "";
    width: 41px;
    height: 1px;
    background: #e2e2e2;
    display: inline-block;
    vertical-align: middle;
    margin: 0 10px;
  }
}
.weixinlogin {
  text-align: center;
  font-size: 30px;
  margin-top: 31px;
  color: #5ac43a;
  cursor: pointer;
}
.el-checkbox {
  text-align: center;
  display: block;
  margin-top: 34px;
  /deep/ .el-checkbox__label {
    color: #bababa;
    font-size: 12px;
  }
}
.is-checked {
  /deep/ .el-checkbox__label {
    color: #bababa !important;
  }
}
.bindphpnetitle {
  color: #212529;
  font-size: 18px;
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  &::before {
    content: "";
    display: inline-block;
    width: 3px;
    height: 19px;
    background: #06c585;
    margin-right: 8px;
  }
}
.xin-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 0 35px;
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 30px;
  .xin-list-item {
    // width: 33.3%;
    width: 27%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    cursor: pointer;
    background-color: #fff;
    &:nth-child(1) {
      margin-top: 20px;
    }
    &:nth-child(2) {
      margin-top: 20px;
    }
    &:nth-child(3) {
      margin-top: 20px;
    }
    .xin-list-item-name {
      color: #929292;
      font-size: 13px;
      margin-top: 10px;
    }
    &:hover {
      color: #4986fe;
      .xin-list-item-name {
        color: #4986fe;
      }
    }
  }
}
.xin-exit {
  text-align: right;
  padding: 0 35px;
  color: #929292;
  padding-top: 15px;
  cursor: pointer;
  padding-bottom: 19px;
  &:hover {
    text-decoration: underline;
  }
}
/deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #4886fd !important;
  border-color: #4886fd !important;
}

//新的样式
.qiehuan {
  position: relative;
  top: 0;
  left: 32%;
  width: 50%;
  margin-top: 36px;
  /deep/ .el-tabs__nav-wrap::after {
    z-index: -10;
  }
  /deep/ .el-tabs__item.is-active {
    color: #4886fd;
  }
  /deep/ .el-tabs__active-bar {
    background-color: #4886fd;
  }
  /deep/ .el-tabs__item {
    &:hover {
      color: #303133;
      cursor: pointer;
    }
  }
}

.xin-zhuce {
  width: 73px;
  height: 33px;
  margin: 0 10%;
  margin-top: 36px;
  img {
    width: 100%;
    height: 100%;
  }
}

.tongguo {
  margin: 0 10%;
  width: 80%;
  margin-top: 17px;
  img {
    width: 100%;
    height: 100%;
  }
}

.renzheng-box {
  max-height: 400px;
  overflow: auto;
}

//设置滚动条宽度
.renzheng-box::-webkit-scrollbar {
  width: 4px;
}
//设置滚动条颜色
.renzheng-box::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #ccc;
}
//设置滚动条底部背景色
.renzheng-box::-webkit-scrollbar-track {
  border-radius: 3px;
}

.avatar-uploader /deep/.el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  // width: 85px;
  // height: 48px;
  width: 115px;
  height: 74px;
  line-height: 74px;
  text-align: center;
}
.avatar {
  // width: 85px;
  // height: 48px;
  width: 115px;
  height: 74px;
  display: block;
}
/deep/ .el-dialog__header {
  background: linear-gradient(180deg, #dbffef 0%, #ffffff 100%);
  color: #fff;
  overflow: hidden;
  border-radius: 16px 16px 0 0;
}

.upload-demo {
  text-align: left;
}
.renzheng-btn {
  margin-top: 0 !important;
}
</style>
