<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  created() {
    document.title = "图书馆";
  },
};
</script>
<style lang="less"></style>
