<template>
  <div class="contain">
    <h5 class="usertitle">创作者中心</h5>
    <div class="tabs">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="待审核" name="1"></el-tab-pane>
        <el-tab-pane label="已通过" name="2"></el-tab-pane>
        <el-tab-pane label="审核驳回" name="3"></el-tab-pane>
      </el-tabs>
      <div class="addbutton" @click="addfile">+添加文件</div>
    </div>
    <ul class="list_box" v-if="list.length > 0">
      <li
        class="list"
        v-for="item in list"
        :key="item._id"
        @click="todetails(item._id)"
      >
        <!-- <div class="titles">
          <div class="picAndContent">
            <div class="pics">
              <img :src="item.cover_img" alt="" />
            </div>
            <div class="content ht">{{ item.title }}</div>
          </div>
          <div
            class="type"
            :style="{ color: type[Number(item.apply_status) - 1].color }"
            @mouseenter="item.apply_status === '3' ? (item.show = true) : ''"
            @mouseleave="item.apply_status === '3' ? (item.show = false) : ''"
          >
            <i
              class="iconfont"
              :style="{ color: type[Number(item.apply_status) - 1].iconColor }"
              >{{ type[Number(item.apply_status) - 1].icon }}</i
            >
            {{ type[Number(item.apply_status) - 1].name }}
            <div class="showToast" v-if="item.show === true">
              {{ item.check_remark }}
            </div>
          </div>
        </div>
        <ul class="ticks" v-if="item.typelist === true">
          <li
            class="ticksitem"
            v-for="items in item.med_type_list"
            :key="items.id"
          >
            {{ items.name }}
          </li>
        </ul>
        <div class="classfiy">
          <ul>
            <li
              @mouseenter="item.typelist = true"
              @mouseleave="item.typelist = false"
            >
              类别
              <i class="iconfont" style="color: #4789fa; font-size: 10px"
                >&#xe617;</i
              >
            </li>
            <li>{{ typelist[item.file_type] }}</li>
            <li>{{ item.lang }}</li>
            <li>{{ item.year }}</li>
            <li>阅读量：{{ item.view_num }}</li>
            <li>下载量：{{ item.download_num }}</li>
          </ul>
          <div class="buttons"> 
            <button 
              class="change"
              @click.stop="updataData(item._id)"
              v-if="item.apply_status !== '2'"
            >
              修改
            </button>
            <button
              class="change remove"
              v-if="item.apply_status !== '2'"
              @click.stop="remove(item._id)"
            >
              <i class="iconfont">&#xe8ae;</i>
            </button>
          </div>
        </div> -->
        <div class="addliebiao">
          <div class="addliebiao-img"><img :src="item.cover_img" /></div>
          <div class="addliebiao-right">
            <div class="addliebiao-right-top">
              <!-- / -->
              <div class="addliebiao-right-top-text">
                {{ item.title }}
              </div>
              <div
                class="addliebiao-right-top-text-shenhe"
                :style="{ color: type[Number(item.apply_status) - 1].color }"
                @mouseenter="
                  item.apply_status === '3' ? (item.show = true) : ''
                "
                @mouseleave="
                  item.apply_status === '3' ? (item.show = false) : ''
                "
              >
                <i
                  class="iconfont"
                  :style="{
                    color: type[Number(item.apply_status) - 1].iconColor,
                  }"
                  >{{ type[Number(item.apply_status) - 1].icon }}</i
                >
                {{ type[Number(item.apply_status) - 1].name }}
                <div class="showToast" v-if="item.show === true">
                  {{ item.check_remark }}
                </div>
              </div>
              <!-- / -->
            </div>
            <!-- 类别弹窗 -->
            <ul class="ticks" v-if="item.typelist === true">
              <li
                class="ticksitem"
                v-for="items in item.med_type_list"
                :key="items.id"
              >
                {{ items.name }}
              </li>
            </ul>
            <!-- 类别弹窗 -->
            <ul class="addliebiao-right-ul">
              <li
                class="addliebiao-right-ul-li"
                @mouseenter="item.typelist = true"
                @mouseleave="item.typelist = false"
              >
                <span>
                  <span>类别</span>
                  <i class="iconfont" style="color: #4789fa; font-size: 10px">
                    &#xe617;
                  </i>
                </span>
              </li>
              <li class="addliebiao-right-ul-li">
                作者：{{ item.file_author_name }}
              </li>
              <li class="addliebiao-right-ul-li">
                {{ typelist[item.file_type] }}
              </li>
              <li class="addliebiao-right-ul-li">{{ item.lang }}</li>
              <li class="addliebiao-right-ul-li">{{ item.year }}</li>
              <li class="addliebiao-right-ul-li">
                阅读量：{{ item.view_num }}
              </li>
              <li class="addliebiao-right-ul-li">
                下载量：{{ item.download_num }}
              </li>
            </ul>
            <div class="addliebiao-right-bottom">
              <div
                class="addliebiao-right-bottom-xiugai"
                @click.stop="updataData(item._id)"
                v-if="item.apply_status !== '2'"
              >
                修改
              </div>
              <div
                class="addliebiao-right-bottom-shanchu"
                v-if="item.apply_status !== '2'"
                @click.stop="remove(item._id)"
              >
                <i class="iconfont" style="color: #ff3d3d">&#xe8ae;</i>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>

    <!-- 空状态 -->
    <el-empty v-else description="暂无数据"></el-empty>
    <div class="changepages">
      <el-pagination
        :hide-on-single-page="true"
        :current-page="this.page"
        :page-size="limit"
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="changepage"
      >
      </el-pagination>
    </div>
    <!-- 这里是一个弹窗 -->
    <el-dialog
      title="删除"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <span class="poptext">确定要删除上传的文件吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button class="changecolor" type="primary" @click="removeA()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { createList, deleteItem } from "../../api/createCenter.js";
export default {
  name: "createrCenter",
  data() {
    return {
      activeName: "1",
      typelist: JSON.parse(localStorage.getItem("class2")) || {},
      type: [
        {
          name: "待审核",
          icon: "\ue88e",
          color: "#FF913D",
          iconColor: "#FF913D",
        },
        {
          name: "已通过",
          icon: "\ue88e",
          color: "#4789FA",
          iconColor: "#FF3D3D",
        },
        {
          name: "审核驳回",
          icon: "\ue601",
          color: "#FF3D3D",
          iconColor: "#A2A2A2",
        },
      ],
      show: false,
      dialogVisible: false,
      list: [],
      total: 0,
      limit: 8,
      // 分页
      page: 1,
      id: "",
    };
  },
  created() {
    this.getlist();
  },
  methods: {
    // 获取列表数据
    async getlist() {
      const res = await createList(this.activeName, this.limit, this.page);
      if (res.code === 1) {
        res.data.datalist.forEach((element) => {
          element.show = false;
          element.typelist = false;
        });
        this.list = res.data.datalist;
        this.total = res.data.total;
        this.$store.commit("getsum", this.total);
      }
    },
    // 修改分页
    changepage(page) {
      this.page = page;
      // chrome
      document.body.scrollTop = 0;
      // firefox
      document.documentElement.scrollTop = 0;
      // safari
      window.pageYOffset = 0;
      // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
      this.getlist();
    },
    // 切换分类
    handleClick() {
      this.page = 1;
      this.getlist();
    },
    async removeA() {
      const res = await deleteItem(this.id);
      if (res.code === 1) {
        this.$message.success("删除成功");
        this.getlist();
        this.dialogVisible = false;
      }
    },
    addfile() {
      this.$router.push("/contribute");
      // this.$router.push("/user/centerEditor");
    },
    updataData(id) {
      this.$router.push("/user/centerEditor?id=" + id);
    },
    handleClose() {
      this.dialogVisible = false;
    },
    remove(id) {
      this.id = id;
      this.dialogVisible = true;
    },
    todetails(id) {
      this.$router.push("/detail?id=" + id);
    },
  },
};
</script>

<style lang="less" scoped>
@import url("../../assets/css/tankuan.less");
.contain {
  width: 100%;
  .tabs {
    display: flex;
    .el-tabs {
      flex: 1;
      margin: 20px;
      /deep/ .el-tabs__nav-wrap {
        .el-tabs__item:hover {
          color: #323232;
        }
        .is-active {
          color: #323232;
        }
        &::after {
          height: 0;
        }
      }
    }
    .addbutton {
      width: 92px;
      height: 29px;
      background: linear-gradient(136deg, #57c9fb 0%, #578cfb 100%);
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.16);
      border-radius: 4px;
      font-size: 13px;
      color: #fff;
      text-align: center;
      line-height: 29px;
      font-weight: bold;
      cursor: pointer;
    }
  }

  .list_box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .list {
      // width: 49%;
      width: 49%;
      background: #fff;
      margin-bottom: 37px;
      box-shadow: 0px 3px 40px rgba(136, 136, 136, 0.16);
      padding: 18px 16px;
      position: relative;
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        transform: scale(102%);
        box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.16);
      }
      .showToast {
        padding: 8px;
        border: 1px solid #4789fa;
        border-radius: 4px;
        position: absolute;
        right: 18px;
        top: 45px;
        color: #4789fa;
        background: #fff;
        max-width: 300px;
        max-height: 100px;
        overflow-y: auto;
        font-size: 13px;
        z-index: 1;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      }
      .titles {
        display: flex;
        justify-content: space-between;
        .picAndContent {
          margin-top: 12px;
          flex: 1;
          display: flex;
          align-items: center;
          .pics {
            width: 85px;
            height: 48px;
            overflow: hidden;
            img {
              width: 100%;
            }
          }
          .content {
            margin-left: 10px;
            line-height: 20px;
            flex: 1;
            font-size: 15px;
            color: #323232;
            -webkit-line-clamp: 2;
          }
        }
        .type {
          font-size: 14px;
          color: #ff913d;
          margin-left: 20px;
          i {
            cursor: pointer;
          }
        }
      }

      .classfiy {
        display: flex;
        align-items: flex-end;
        ul {
          flex: 1;
          overflow: hidden;
          li {
            float: left;
            text-align: center;
            height: 25px;
            line-height: 25px;
            padding: 0 16px;
            font-size: 14px;
            color: #82929b;
            border-radius: 13px;
            background: #f3f7fa;
            margin-right: 20px;
            margin-top: 20px;
            &:nth-child(1) {
              cursor: pointer;
            }
          }
        }
      }
      .ticks {
        position: absolute;
        // top: 121px;
        // left: 36px;
        top: 115px;
        left: 180px;
        width: 233px;
        background: #fff;
        border: 1px solid #4789fa;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        border-radius: 4px;
        overflow: hidden;
        padding-bottom: 9px;
        z-index: 10;
        .ticksitem {
          height: 25px;
          line-height: 25px;
          padding: 0 10px;
          border-radius: 13px;
          background: #f3f7fa;
          color: #82929b;
          font-size: 14px;
          float: left;
          margin: 9px 4px 0;
        }
      }
      .buttons {
        width: 120px;
        .change {
          width: 58px;
          height: 32px;
          background: linear-gradient(180deg, #ecf1fe 0%, #cbd9fe 100%);
          box-shadow: 0px 1px 3px #b7cafc;
          color: #7599f5;
          font-size: 14px;
          border: 0 none;
          cursor: pointer;
        }
        .remove {
          width: 32px;
          height: 32px;
          background: linear-gradient(180deg, #fee6e6 0%, #fecaca 100%);
          margin-left: 24px;
          i {
            color: #fd484a;
          }
        }
      }
    }
  }
  .changepages {
    margin-top: 20px;
    text-align: center;
  }
}
/deep/ .el-dialog__header {
  background: linear-gradient(180deg, #dbffef 0%, #ffffff 100%);
  color: #fff;
  overflow: hidden;
  border-radius: 16px 16px 0 0;
}
/deep/ .el-tabs__active-bar {
  background-color: #4789fa !important;
}

//---------------
.addliebiao {
  display: flex;
  .addliebiao-img {
    width: 127px;
    height: 180px;
    margin-right: 20px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .addliebiao-right {
    flex: 1;
    .addliebiao-right-top {
      display: flex;
      justify-content: space-between;
      // align-items: center;
      margin-bottom: 25px;
      .addliebiao-right-top-text {
        width: 323px;
        font-size: 14px !important;
        color: #1c1c1c !important;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .addliebiao-right-top-text-shenhe {
        color: #ff913d;
      }
    }
    .addliebiao-right-ul {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .addliebiao-right-ul-li {
        background-color: #f3f7fa;
        color: #82929b;
        font-size: 14px !important;
        padding: 3px 11px 3px 15px;
        margin-right: 25px;
        border-radius: 13px;
        margin-bottom: 20px;
      }
    }
    .addliebiao-right-bottom {
      position: absolute;
      bottom: 31px;
      right: 19px;
      display: flex;
      z-index: 999;
      // align-items: center;
      .addliebiao-right-bottom-xiugai {
        padding: 7px 15px;
        color: #7599f5;
        background: linear-gradient(180deg, #ecf1fe 0%, #cbd9fe 100%);
        box-shadow: 0px 1px 3px 1px #b7cafc;
        margin-right: 24px;
      }
      .addliebiao-right-bottom-shanchu {
        padding: 7px 15px;
        background: linear-gradient(180deg, #fee6e6 0%, #fecaca 100%);
        box-shadow: 0px 1px 3px 1px #f6c3c3;
      }
    }
  }
}
</style>
