import { setUser, getUser, clearUser } from '@/utils/localstore.js'
export default {
  namespaced: true,
  state: {
    user: getUser() ? getUser() : ''
  },
  mutations: {
    // 存储user
    setUsers (state, data) {
      state.user = JSON.parse(JSON.stringify(data))
      setUser(data)
    },
    // 删除user
    removeuser (state) {
      state.user = ''
      clearUser()
    }
  }
}
