
import axios from '../utils/request'
// 发送验证码
// eslint-disable-next-line camelcase
export const createList = (apply_status, limit, page) => axios.post('doc/list', { apply_status, limit, page })
// 删除个人中心
export const deleteItem = (_id) => axios.get('doc/delete', { _id })
// 获取医学类别的接口
export const docClass = () => axios.get('doc/medCategory')
// 获取七牛云的配置参数
export const sevenCloud = () => axios.get('doc/qiniu_config')
// 提交表单
export const submits = (data) => axios.post('doc/subdoc', data)
// 获取积分列表
export const jifenlist = (page) => axios.get('user/scorelist', { page })

// 获取推荐列表
export const tuijianlist = (data) => axios.post('doc/list ',data)  