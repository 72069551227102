<template>
  <div class="contain">
    <el-card class="v8">
      <div>
        <h1 class="title">高级搜索设置</h1>
        <div class="input_box">
          <div class="items" style="padding-right: 100px">
            <h5>标题搜索:</h5>
            <div class="right_item">
              <div class="inputs">
                <el-input
                  placeholder="请输入标题"
                  v-model="title"
                  class="chilren"
                  size="small"
                >
                </el-input>
              </div>
            </div>
          </div>
          <div class="items">
            <h5>关键字搜索:</h5>
            <div class="right_item">
              <div class="inputs" v-for="(item, index) in list" :key="index">
                <el-input
                  placeholder="请输入关键字"
                  v-model="item.input"
                  class="chilren"
                  size="small"
                >
                </el-input>
                <div class="buttons">
                  <el-button
                    size="small"
                    type="primary"
                    @click="additems(index)"
                    >+</el-button
                  >
                  <el-button
                    type="info"
                    size="small"
                    v-if="index !== 0"
                    @click="removeitem(index)"
                    >-</el-button
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="items">
            <h5>文件类型</h5>
            <ul class="inputs">
              <li
                :class="typeindex === '' ? 'active' : ''"
                @click="changetype('', '')"
                style="cursor: pointer"
              >
                全部
              </li>
              <li
                :class="index === typeindex ? 'active' : ''"
                v-for="(item, index) in classify"
                :key="index"
                @click="changetype(index, item.file_type)"
                style="cursor: pointer"
              >
                {{ item.name }}
              </li>
            </ul>
          </div>
          <div class="items">
            <h5>年份</h5>
            <div class="inputs">
              <!-- <el-select v-model="model" placeholder="选择年份" size="small">
                <el-option v-for="item in years" :key="item" :label="item" :value="item">
                </el-option>
              </el-select> -->
              <!-- 这里变成一个年份的插件 -->
              <el-date-picker
                v-model="model"
                value-format="yyyy"
                type="year"
                placeholder="请选择年份"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="el-bottom">
            <el-button type="info" @click="reset">重置选择</el-button>
            <el-button
              type="info"
              @click="toresolve"
              style="
                background: linear-gradient(136deg, #2fd2c1 0%, #4788fb 100%);
                border-color: #2fd2c1;
              "
              >高级检索</el-button
            >
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "search",
  data() {
    return {
      // 标题
      title: "",
      // 年份
      model: "",
      list: [{ input: "" }],
      classify: this.$store.state.classfiy,
      typeindex: "",
      typeId: "",
      years: [],
    };
  },
  created() {
    // 获取分类
    this.getyear();
  },
  methods: {
    reset() {
      this.title = "";
      this.list = [{ input: "" }];
      this.model = "";
      this.typeindex = "";
      this.typeId = "";
    },
    // 获取年份
    getyear() {
      const data = new Date();
      var year = data.getFullYear();
      for (var i = 0; i < 5; i++) {
        this.years.push(year);
        year -= 1;
      }
    },
    changetype(index, id) {
      this.typeindex = index;
      this.typeId = id;
    },
    // 添加数值
    additems(index) {
      const res = this.list.filter((element) => {
        return element.input === "";
      });
      if (res.length > 0) {
        this.$message.warning("填写完搜索条件再添加");
        return;
      }
      console.log(this.list);
      this.list.splice(index + 1, 0, { input: "" });
      console.log(this.list);
    },
    removeitem(index) {
      this.list.splice(index, 1);
    },
    toresolve() {
      var changelist = [];
      this.list.forEach((element) => {
        changelist.push(element.input);
      });
      var data = {
        file_type: this.typeId,
        year: this.model,
        title: this.title,
        list: "",
      };
      if (changelist.length > 0 && changelist[0] !== "") {
        data.list = JSON.stringify(changelist);
      }
      console.log("这个是什么", data);
      this.$router.push({ name: "resolve", query: data });
    },
  },
};
</script>

<style lang="less" scoped>
@import url("../../assets/css/title.less");
.v8 {
  margin-top: 50px;
  padding-bottom: 80px;
  .title {
    margin-top: 18px;
    text-align: center;
    color: #4f91e8;
    font-size: 22px;
  }
  .input_box {
    margin: 0 auto;
    margin-top: 100px;
    width: 50%;
    .items {
      display: flex;
      // margin-top: 38px;
      margin-top: 15px;
      h5 {
        width: 100px;
        line-height: 32px;
      }
      .right_item {
        flex: 1;
      }
      .inputs {
        width: 100%;
        display: flex;
        margin-bottom: 30px;
        .chilren {
          flex: 1;
          margin-right: 20px;
        }
        li {
          margin-right: 20px;
          height: 29px;
          line-height: 29px;
          padding: 0 14px;
          border-radius: 9px;
          color: #5a5d5a;
        }
        .active {
          color: #ffffff;
          background: linear-gradient(136deg, #3fd7e8 0%, #1eb8c9 100%);
        }
      }
      .buttons {
        width: 100px;
        .el-button--primary {
          background-color: #4f91e8;
          border-color: #4f91e8;
        }
      }
    }
    .el-bottom {
      width: 100%;
      display: flex;
      justify-content: space-around;
      margin-top: 94px;
    }
  }

  // .el-button:focus,
  // .el-button:hover {
  //   color: #fff;
  //   border-color: #3fd7e8;
  //   background-color: #e6f9f3;
  // }
}
</style>
