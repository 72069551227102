/*
 * @Author: lsh lsh@163.com
 * @Date: 2022-05-06 14:54:18
 * @LastEditors: lsh lsh@163.com
 * @LastEditTime: 2022-06-21 17:13:32
 * @FilePath: /yixinyuan/src/api/detail.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from '../utils/request'
// 详情的接口
export const detail = (_id) => axios.post('doc/detail', { _id })
// 获取评论的列表
// eslint-disable-next-line
export const commentList = (doc_id, page) => axios.post('comment/list', { doc_id, page })
// 发表评论
// eslint-disable-next-line
export const sendComment = (comment, doc_id) => axios.post('comment/poster', { comment, doc_id })
// 添加到收藏夹
// eslint-disable-next-line
export const addfavorite = (_id, collect_id = 0) => axios.post('user/add2Collect', { _id, collect_id })
// eslint-disable-next-line
export const getcounts = (_id) => axios.post('doc/countdown', { _id })
