<template>
  <div class="contribute">
    <div class="head"><img src="@/assets/image/组 1117@2x.png" /></div>
    <div class="v8 box">
      <!-- 左侧 -->
      <div class="left">
        <div class="left-img"><img src="@/assets/image/组 1118@2x.png" /></div>
        <el-form ref="form" :model="form" label-width="80px" :rules="rules">
          <!-- 标题 -->
          <el-form-item label="文件标题" prop="title">
            <el-input
              v-model="form.title"
              placeholder="请输入文件标题"
            ></el-input>
          </el-form-item>

          <!-- 作者 -->
          <el-form-item label="作者" prop="file_author_name">
            <el-input
              v-model="form.file_author_name"
              placeholder="请输入作者"
            ></el-input>
          </el-form-item>

          <!-- 封面图 -->
          <el-form-item label="封面图" prop="cover_img">
            <el-upload
              :http-request="uploadpic"
              class="avatar-uploader"
              action=""
              :show-file-list="false"
            >
              <div v-if="!isShowCover_img">
                <img
                  v-if="form.cover_img"
                  :src="form.cover_img"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </div>
              <div v-else>
                <el-progress
                  :width="85"
                  type="circle"
                  :percentage="progressPercentCover_img"
                ></el-progress>
              </div>
            </el-upload>
          </el-form-item>

          <!-- 上传文件 -->
          <el-form-item label="上传文件" prop="file_path">
            <el-upload
              :http-request="uploadfile"
              class="avatar-uploader"
              action=""
              :show-file-list="false"
            >
              <div v-if="!isShowfile_path">
                <img
                  v-if="form.file_path"
                  src="../../assets/image/uploadfile.jpg"
                  class="avatar wow bounceInU"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </div>
              <div v-else>
                <el-progress
                  :width="85"
                  type="circle"
                  :percentage="progressPercentfile_path"
                ></el-progress>
              </div>
            </el-upload>
          </el-form-item>
          <!-- 文件类型 -->
          <el-form-item label="文件类型" prop="file_type">
            <el-select v-model="form.file_type" placeholder="请选择文件类型">
              <el-option
                :label="item.name"
                :value="item.file_type"
                v-for="item in file_type"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- 医学类别 -->
          <el-form-item label="医学类别" class="classfhy" prop="med_type_ids">
            <el-select
              v-model="form.med_type_ids"
              placeholder="请选择医学类别"
              multiple
            >
              <el-option
                v-for="item in classfiy"
                :key="item.id"
                :label="item.nickname"
                :value="item.id"
              >
                <span style="float: left">{{ item.nickname }}</span>
              </el-option>
            </el-select>
          </el-form-item>

          <!-- 年份 -->
          <el-form-item label="年份" prop="year">
            <el-date-picker
              v-model="form.year"
              type="year"
              value-format="yyyy"
              placeholder="请选择年份"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="语言" prop="lang">
            <el-input v-model="form.lang" placeholder="请输入语言"></el-input>
          </el-form-item>
          <!-- 文件大小 -->
          <el-form-item label="文件大小">
            <el-input
              v-model="form.file_size"
              disabled
              placeholder="文件大小"
            ></el-input>
          </el-form-item>
          <!-- 摘要 -->
          <el-form-item label="摘要">
            <el-input
              :rows="7"
              type="textarea"
              v-model="form.content_desc"
              placeholder="请输入摘要"
            ></el-input>
          </el-form-item>
          <el-form-item style="text-align: center">
            <el-button
              style="
                background: linear-gradient(127deg, #2fd1c2 0%, #4986fe 100%);
                border-color: #2fd1c2;
              "
              type="primary"
              @click="onSubmit('form')"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <!-- 左侧 -->
      <!-- 右侧 -->
      <div class="right">
        <div class="right-head">
          <div class="right-head-img">
            <img src="@/assets/image/组 1119@2x.png" />
          </div>
        </div>

        <div class="right-box" v-if="tuijianLsit.length != 0">
          <ul class="right-box-ul">
            <li
              class="right-box-ul-li"
              v-for="(item, index) in tuijianLsit"
              :key="index"
              @click="$router.push('/detail?id=' + item._id)"
            >
              <div class="right-box-ul-li-img">
                <img :src="item.cover_img" />
              </div>
              <div class="right-box-ul-li-right">
                <div class="right-box-ul-li-right-text">
                  {{ item.title }}
                </div>
                <div class="right-box-ul-li-right-bottom">
                  <div class="right-box-ul-li-right-bottom-zuo">
                    作者：{{ item.file_author_name }}
                  </div>
                  <div class="right-box-ul-li-right-bottom-num">
                    <div class="right-box-ul-li-right-bottom-num-img">
                      <img src="@/assets/image/icon/yan.png" />
                    </div>
                    <div class="right-box-ul-li-right-bottom-num-name">
                      {{ item.view_num }}
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="zanwushuju" v-else>暂无数据</div>
      </div>
      <!-- 右侧 -->
    </div>
    <!-- 弹窗 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <span class="poptext"
        >您的文件已经提交，我们会尽快审核！<br />文件审核通过，您会得到相应的积分奖励</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button
          style="
            background: linear-gradient(127deg, #2fd1c2 0%, #4986fe 100%);
            border-color: #2fd1c2;
          "
          class="changecolor"
          type="primary"
          @click="IKnows"
          >好的，我知道了</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { detail } from "../../api/detail";
import {
  docClass,
  sevenCloud,
  submits,
  tuijianlist,
} from "../../api/createCenter";
// 引入七牛云
import * as qiniu from "qiniu-js";
export default {
  name: "contribute",
  data() {
    return {
      tuijianLsit: [],
      form: {
        _id: this.$route.query.id || "",
        title: "", // 标题
        cover_img: "", // 封面图
        file_path: "", // 文件路径
        file_type: "", // 文件类型
        med_type_list: [], // 文件类型数组
        year: "", // 年份
        lang: "", // 语言
        file_size: "", // 文件大小
        content_desc: "", // 摘要
        med_type_ids: [], // 类型的id
        file_author_name: "", //作者
      },
      //上传图片
      progressPercentCover_img: 0, //上图图片进度条
      isShowCover_img: false, //上图图片进度条
      //预览文件
      progressPercentpreview_path: 0, //预览文件进度条
      isShowpreview_path: false, //预览文件进度条
      //上传文件
      progressPercentfile_path: 0, //上文件进度条
      isShowfile_path: false, //上文件进度条
      classfiy: [],
      value: "",
      file_type: JSON.parse(localStorage.getItem("class")) || [],
      config: "", // 七牛云的配置项
      dialogVisible: false,
      rules: {
        file_author_name: [
          { required: true, message: "请输入作者", trigger: "blur" },
        ],
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        file_type: [
          { required: true, message: "请选择文件类型", trigger: "change" },
        ],
        med_type_ids: [
          { required: true, message: "请选择医学类别", trigger: "change" },
        ],
        year: [{ required: true, message: "请选择日期", trigger: "change" }],
        lang: [{ required: true, message: "请输入语言", trigger: "blur" }],
        file_size: [
          { required: true, message: "请选择文件大小", trigger: "change" },
        ],
        cover_img: [
          { required: true, message: "请上传封面图", trigger: "change" },
        ],
        file_path: [
          { required: true, message: "请上传文件", trigger: "change" },
        ],
      },
    };
  },
  watch: {
    "form.med_type_ids": {
      handler(newValue) {
        newValue.forEach((el) => {
          console.log(el);
        });
        const arrat = [];
        for (var i = 0; i < this.classfiy.length; i++) {
          for (var j = 0; j < newValue.length; j++) {
            if (this.classfiy[i].id === newValue[j]) {
              arrat.push({
                id: this.classfiy[i].id,
                name: this.classfiy[i].nickname,
              });
            }
          }
        }
        console.log(arrat);
        this.form.med_type_list = arrat;
      },
      immediate: true,
    },
  },
  async created() {
    if (this.$route.query.id) {
      this.getdetail();
    }
    await this.docClass();
    this.getsevencload();
    this.tuijianlistApi();
  },
  methods: {
    //弹窗
    IKnows() {
      this.dialogVisible = false;
      this.$message.success("提交成功");
      this.$router.push("/user/createrCenter");
    },
    handleClose() {
      this.dialogVisible = false;
    },
    // 引入七牛云的token
    async getsevencload() {
      const res = await sevenCloud();
      if (res.code === 1) {
        this.config = res.data;
      }
    },
    // 获取医学类别
    async docClass() {
      const res = await docClass();
      if (res.code === 1) {
        this.classfiy = res.data;
      }
    },
    // 回显数据
    async getdetail() {
      const res = await detail(this.$route.query.id);
      if (res.code === 1) {
        const data = res.data.detail;
        this.form.title = data.title;
        this.form.file_author_name = data.file_author_name;
        this.form.cover_img = data.cover_img;
        this.form.file_path = data.file_path;
        this.form.file_type = data.file_type;
        this.form.med_type_list = data.med_type_list;
        this.form.year = data.year;
        this.form.lang = data.lang;
        this.form.file_size = data.file_size;
        this.form.content_desc = data.content_desc;
        data.med_type_ids.forEach((element) => {
          this.form.med_type_ids.push(Number(element));
        });
      }
    },
    onSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await submits(this.form);
          console.log(res);
          if (res.code === 1) {
            this.dialogVisible = true;
            // this.getdetail()
          } else {
            this.$message.error(res.msg);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //上传封面图
    uploadpic(el) {
      this.isShowCover_img = true;
      this.upload(el, "cover_img");
    },
    //预览文件
    uploadviewfile(el) {
      this.isShowpreview_path = true;
      this.upload(el, "preview_path");
    },
    //上传文件
    uploadfile(el) {
      this.isShowfile_path = true;
      this.upload(el, "file_path");
    },
    animation() {
      this.$nextTick(() => {
        new this.$wow.WOW({
          boxClass: "wow",
          animateClass: "animated",
          offset: 0,
          mobile: true,
          live: true,
        }).init();
        this.isShowCover_img = false; //上传图片进度条
        this.isShowpreview_path = false; //预览文件
        this.isShowfile_path = false; //上传文件
        this.$refs.form.validateField("cover_img"); //上传图片后对表单从新验证
        this.$refs.form.validateField("preview_path"); //预览文件对表单从新验证
        this.$refs.form.validateField("file_path"); //上传文件后对表单从新验证
      });
    },
    upload(el, code) {
      const that = this;
      const observable = qiniu.upload(el.file, el.file.name, this.config.token);
      const observer = {
        next(res) {
          //上传图片
          if (code === "cover_img") {
            that.progressPercentCover_img = 0;
            that.progressPercentCover_img = Math.trunc(
              Number(((res.total.loaded / res.total.size) * 100).toFixed(2))
            );
          }
          //预览文件
          if (code === "preview_path") {
            that.progressPercentpreview_path = 0;
            that.progressPercentpreview_path = Math.trunc(
              Number(((res.total.loaded / res.total.size) * 100).toFixed(2))
            );
          }
          //上传文件
          if (code === "file_path") {
            that.form.file_size = Math.round(res.total.size / 1024) + "kB";
            that.progressPercentfile_path = 0;
            that.progressPercentfile_path = Math.trunc(
              Number(((res.total.loaded / res.total.size) * 100).toFixed(2))
            );
          }
        },
        error(err) {
          console.log("失败了吗", err);
          that.$message.error("上传失败");
        },
        complete(res) {
          that.form[code] = that.config.cdn + res.key;
          that.animation();
          that.$message.success("文件上传成功");
        },
      };
      observable.subscribe(observer);
    },
    //推荐列表
    async tuijianlistApi() {
      const res = await tuijianlist({ is_rec: 1, limit: 5, use_to_rec: "1" });
      this.tuijianLsit = res.data.datalist;
    },
  },
};
</script>
<style lang="less" scoped>
.contribute {
  .head {
    width: 100%;
    height: 359px;
    margin-bottom: 44px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  //内容
  .box {
    display: flex;
    margin-bottom: 100px;
    .left {
      width: 1076px;
      margin-right: 40px;
      background-color: #ffffff;
      border: 1px solid #dce7ef;
      border-radius: 9px;
      // height: calc(100% + 42px);
      height: 1189;
      padding: 27px 34px 36px 67px;
      .left-img {
        width: 975px;
        height: 43px;
        margin-bottom: 30px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .right {
      flex: 1;
      .right-head {
        margin-bottom: 15px;
        .right-head-img {
          width: 72px;
          height: 27px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .right-box {
        background-color: #f4f8f8;
        border: 1px solid #dce7ef;
        border-radius: 9px;
        padding: 20px;

        //列表
        .right-box-ul {
          .right-box-ul-li {
            background-color: #ffffff;
            padding: 18px;
            border-radius: 9px;
            display: flex;
            margin-bottom: 20px;
            cursor: pointer;
            transition: 0.3s;
            &:hover {
              transform: scale(102%);
              box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.16);
            }
            &:last-child {
              margin-bottom: 0px !important;
            }
            .right-box-ul-li-img {
              width: 123px;
              height: 169px;
              margin-right: 20px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .right-box-ul-li-right {
              flex: 1;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              .right-box-ul-li-right-text {
                // font-size: 14px;
                color: #1c1c1c;

                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 7;
                text-overflow: ellipsis;
                overflow: hidden;
              }
              .right-box-ul-li-right-bottom {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .right-box-ul-li-right-bottom-zuo {
                  color: #999999;
                }
                .right-box-ul-li-right-bottom-num {
                  display: flex;
                  align-items: center;
                  .right-box-ul-li-right-bottom-num-img {
                    width: 18px;
                    height: 18px;
                    margin-right: 10px;
                    img {
                      width: 100%;
                      height: 100%;
                    }
                  }
                  .right-box-ul-li-right-bottom-num-name {
                    color: #999999;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.el-form {
  // margin-top: 60px;
  width: 88%;
  /deep/ .el-form-item {
    // margin-bottom: 60px;
    margin-bottom: 40px;
    .el-input {
      width: 100%;
    }
    .el-select {
      width: 100%;
    }
  }
}
.avatar-uploader /deep/.el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 85px;
  height: 48px;
  line-height: 48px;
  text-align: center;
}
.avatar {
  width: 85px;
  height: 48px;
  display: block;
}
/deep/ .el-dialog__header {
  background: linear-gradient(180deg, #dbffef 0%, #ffffff 100%);
  color: #fff;
  overflow: hidden;
  border-radius: 16px 16px 0 0;
}

.zanwushuju {
  margin-top: 50px;
  margin-left: 30px;
}
</style>
