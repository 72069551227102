<template>
  <div class="contain">
    <h5 class="usertitle">基本信息</h5>
    <ul class="forms">
      <!-- 头像 -->
      <li>
        <el-col :span="3">
          <div class="headname">头像</div>
        </el-col>
        <el-col :span="16">
          <el-upload
            action="#"
            class="avatar-uploader"
            :http-request="uploadfile"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
          >
            <img v-if="userdata.avatar" :src="userdata.avatar" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-col>
      </li>
      <!-- 昵称 -->
      <li>
        <el-col :span="3">
          <div class="headname">昵称</div>
        </el-col>
        <el-col :span="16">
          <el-input
            type="text"
            v-model="userdata.nickname"
            ref="nickname"
            :disabled="nicknameflag"
          ></el-input>
        </el-col>
        <el-col :span="5">
          <button v-if="nicknameflag === true" @click="canwritenickname">
            重新绑定
          </button>
          <button
            class="surechange"
            v-if="nicknameflag === false"
            @click="changenickname"
          >
            确认修改
          </button>
        </el-col>
      </li>
      <!-- ID -->
      <li>
        <el-col :span="3">
          <div class="headname">ID</div>
        </el-col>
        <el-col :span="16">
          <el-input v-model="userdata.id" type="text" disabled></el-input>
        </el-col>
      </li>
      <!-- 用户手机 -->
      <li>
        <el-col :span="3">
          <div class="headname">用户手机</div>
        </el-col>
        <el-col :span="16">
          <el-input v-model="userdata.mobile" type="text" disabled></el-input>
        </el-col>
        <el-col :span="5">
          <button @click="changeshow">
            {{ phonflag === true ? "取消绑定" : "重新绑定" }}
          </button>
        </el-col>
        <div class="phons" v-if="phonflag">
          <!-- 这里是一个表单 -->
          <el-form ref="ruleForm" :rules="rules" :model="form">
            <el-form-item prop="phone">
              <el-input
                v-model="form.phone"
                placeholder="请输入手机号"
              ></el-input>
            </el-form-item>
            <el-form-item prop="code">
              <el-input
                v-model="form.code"
                placeholder="请输入验证码"
              ></el-input>
              <el-button
                class="sendcodebutton"
                @click="
                  typeof codetick === 'string'
                    ? setintervals('change_mobile')
                    : ''
                "
              >
                {{ codetick === "获取验证码" ? "获取验证码" : codetick + "s" }}
              </el-button>
            </el-form-item>
            <el-form-item>
              <button type="primary" @click="submitForm('ruleForm')">
                确 定
              </button>
            </el-form-item>
          </el-form>
        </div>
      </li>
      <li>
        <el-col :span="3">
          <div class="headname">用户邮箱</div>
        </el-col>
        <el-col :span="16">
          <el-input
            v-model="userdata.email"
            type="text"
            ref="email"
            :disabled="emailflag"
            placeholder="请输入邮箱"
          ></el-input>
        </el-col>
        <el-col :span="5">
          <button v-if="emailflag === true" @click="canwriteemail">
            重新绑定
          </button>
          <button
            class="surechange"
            v-if="emailflag === false"
            @click="changeemail"
          >
            确认修改
          </button>
        </el-col>
      </li>
      <li style="margin-top: 32px">
        <el-col :span="3">
          <div class="headname">研究兴趣</div>
        </el-col>
        <el-col :span="16">
          <el-input
            v-model="userdata.bio"
            ref="detail"
            type="textarea"
            :rows="6"
            :disabled="detailflag"
            placeholder="请填写您的内容"
          ></el-input>
        </el-col>
        <el-col :span="5">
          <button v-if="detailflag === true" @click="canwritedetail">
            重新编辑
          </button>
          <button
            class="surechange"
            v-if="detailflag === false"
            @click="changedetail"
          >
            确认修改
          </button>
        </el-col>
      </li>
    </ul>
  </div>
</template>

<script>
import { sendmessage } from "../../api/login";
import { reguser, regemail, notblank } from "../../utils/reg";
// import { interval } from '../../mixin/index'
import { getuser, chengeuser, upload } from "../../api/user";
export default {
  // mixins: [interval],
  created() {
    this.getusers();
  },
  data() {
    var validatephone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号码"));
      } else if (
        /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/.test(
          value
        ) === false
      ) {
        callback(new Error("请输入正确手机号"));
      } else {
        callback();
      }
    };
    var validateCode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入验证码"));
      } else if (/^\d{4}$/.test(value) === false) {
        callback(new Error("请输入正确验证码"));
      } else {
        callback();
      }
    };
    return {
      codetick: "获取验证码",
      inter: "",
      userdata: {
        avatar: "",
        nickname: "",
        id: "",
        mobile: "",
        email: "",
        bio: "",
      },
      nicknameflag: true,
      phonflag: false,
      emailflag: true,
      detailflag: true,
      model: "",
      form: {
        phone: "",
        code: "",
      },
      rules: {
        phone: [{ validator: validatephone, trigger: "blur" }],
        code: [{ validator: validateCode, trigger: "blur" }],
      },
    };
  },
  methods: {
    setintervals(type) {
      if (this.form.phone) {
        this.codetick = 60;
        clearInterval(this.inter);
        this.inter = setInterval(() => {
          this.codetick -= 1;
          if (this.codetick < 1) {
            clearInterval(this.inter);
            this.codetick = "获取验证码";
          }
        }, 1000);
        this.getcode(type);
      } else {
        this.$message.warning("请输入手机号");
      }
    },
    // 上传头像
    async uploadfile(el) {
      var form = new FormData();
      form.append("file", el.file);
      const res = await upload(form);
      if (res.code === 1) {
        this.changeusers("", "", "", "", "", res.data.fullurl);
      } else {
        this.$message.file("上传头像失败");
      }
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
    // 获取用户信息
    async getusers() {
      const res = await getuser();
      if (res.code === 1) {
        this.$store.commit("user/setUsers", res.data.user_info);
        this.userdata = res.data.user_info;
        // 存储到本地

        console.log("aaaa", "什么时候执行这里");
        // localStorage.setItem('user', JSON.stringify(res.data.user_info))
      }
      console.log(res);
    },
    // eslint-disable-next-line camelcase
    async changeusers(nickname, email, bio, mobile, sms_code, avatar) {
      const res = await chengeuser(
        nickname,
        email,
        bio,
        mobile,
        sms_code,
        avatar
      );
      if (res.code === 1) {
        this.getusers();
        this.$message.success("改修成功");
      } else {
        this.$message.error("改修失败");
      }
    },
    // 修改昵称
    canwritenickname() {
      this.nicknameflag = false;
      this.$nextTick(() => {
        this.$refs.nickname.focus();
      });
    },
    async changenickname() {
      if (!notblank(this.userdata.nickname)) {
        this.$message.error("昵称不能为空");
        return;
      } else if (!reguser(this.userdata.nickname)) {
        this.$message.error("包含英文，数字，下划线，中文的2-12位字符");
        this.userdata.nickname = "";
        return;
      }
      this.changeusers(this.userdata.nickname, "", "");
      this.nicknameflag = true;
    },
    // 修改邮箱
    changeemail() {
      if (!notblank(this.userdata.email)) {
        this.$message.error("邮箱不能为空");
        return;
      } else if (!regemail(this.userdata.email)) {
        this.$message.error("请输入正确邮箱");
        this.userdata.email = "";
        return;
      }
      this.changeusers("", this.userdata.email, "");
      this.emailflag = true;
    },
    canwriteemail() {
      this.emailflag = false;
      this.$nextTick(() => {
        this.$refs.email.focus();
      });
    },
    changedetail() {
      if (!notblank(this.userdata.bio)) {
        this.$message.error("内容不能为空");
        return;
      }
      this.changeusers("", "", this.userdata.bio);
      this.detailflag = true;
    },
    canwritedetail() {
      this.detailflag = false;
      this.$nextTick(() => {
        this.$refs.detail.focus();
      });
    },
    // 重新绑定和取消绑定
    changeshow() {
      this.phonflag = !this.phonflag;
      this.form.phone = "";
      this.form.code = "";
    },
    // 提交表单
    submitForm(formName) {
      const that = this;
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await chengeuser(
            "",
            "",
            "",
            this.form.phone,
            this.form.code
          );
          console.log("提交完了有数据啦吗", res);
          if (res.code === 1) {
            this.$message({
              message: "修改手机号成功",
              type: "success",
            });
            this.getusers();
            that.phonflag = false;
          } else {
            this.$message({
              message: res.msg,
              type: "error",
            });
          }
        } else {
          console.log("手机号或验证码输入错误");
          return false;
        }
      });
    },
    // 获取验证码
    async getcode(type) {
      const res = await sendmessage(this.form.phone, type);
      if (res.code === 1) {
        this.$message({
          message: res.msg,
          type: "success",
        });
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.contain {
  width: 100%;
  // 上传头像
  /deep/ .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 58px;
    height: 58px;
    line-height: 58px;
    text-align: center;
  }
  .avatar {
    width: 58px;
    height: 58px;
    display: block;
  }
  .forms {
    li {
      overflow: hidden;
      line-height: 58px;
      // margin-top: 42px;
      margin-top: 22px;
      .headname {
        // font-size: 18px;
        font-size: 15px;
        color: #b4b4b4;
      }
      // .header {
      //   width: 58px;
      //   height: 58px;
      //   overflow: hidden;
      //   border-radius: 50%;
      //   img {
      //     width: 100%;
      //   }
      // }
      input {
        border: 0 none;
        height: 51px;
        box-sizing: border-box;
        width: 100%;
        background: #fbfbfb;
        font-size: 18px;
        padding-left: 20px;
        color: #212121;
        margin-right: 10px;
      }
      button {
        width: 110px;
        height: 34px;
        border: 1px solid #4986fe;
        border-radius: 4px;
        font-size: 14px;
        color: #4986fe;
        background: #fff;
        margin-left: 30px;
        // line-height: 34px;
      }
      .sendcodebutton {
        width: 110px !important;
        height: 40px;
      }
      .surechange {
        // background: #4986fe;
        background: linear-gradient(133deg, #2fd2c1 0%, #4788fb 100%);
        border-color: #2fd2c1;
        color: #fff;
      }

      .phons {
        width: 50%;
        margin-left: 12.5%;
        float: left;
        margin-top: 30px;
        .el-input {
          width: 70%;
        }
        .el-button {
          width: 30%;
        }
      }
    }
  }
}
.el-input__inner:focus {
  outline: 0;
  border-color: #4986fe !important;
}
</style>
