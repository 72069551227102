<template>
  <div class="contain">
    <header class="header">
      <!-- logo -->
      <div class="logo" @click="toIndex">
        <img src="../assets/image/tgsmg.png" alt="" />
      </div>
      <!-- 导航 -->
      <ul class="nav">
        <li v-for="item in navList" :key="item.path">
          <router-link :to="item.path">{{ item.name }}</router-link>
        </li>
      </ul>
      <!-- 登录注册 -->
      <login></login>
    </header>
    <router-view></router-view>
    <footer class="footer">
      <router-link
        v-for="item in footlist"
        class="links"
        :key="item.name"
        :to="item.path + '?id=' + item.id"
        >{{ item.name }}</router-link
      >
      <!-- <p>版权声明 | 联系我们 | 网站投稿 | 商务合作</p> -->
      <!-- <p>{{ba.copyright}}</p> -->
      <p>
        <a class="link" target="_blank" href="https://beian.miit.gov.cn/">{{
          ba.beian
        }}</a>
      </p>
    </footer>
  </div>
</template>

<script>
import login from "../components/login.vue";
import { getmessage } from "../api/contect";
export default {
  data() {
    return {
      footlist: [
        {
          name: "版权声明",
          path: "/copyright",
          id: 0,
        },
        {
          name: "网站投稿",
          path: "/copyright",
          id: 2,
        },
        {
          name: "联系我们",
          path: "/copyright",
          id: 1,
        },
        {
          name: "商务合作",
          path: "/copyright",
          id: 3,
        },
      ],
      navList: [
        {
          path: "/",
          name: "首页",
        },
        {
          path: "/yxy",
          name: "图书馆",
        },
        {
          path: "/contribute",
          name: "上传文章",
        },
        {
          path: "/contactme",
          name: "联系我们",
        },
      ],
      ba: "",
    };
  },
  created() {
    this.getmessages();
  },
  methods: {
    toIndex() {
      this.$router.push("/");
    },
    async getmessages() {
      const res = await getmessage();
      if (res.code === 1) {
        this.ba = res.data.concat_us;
      }
    },
  },
  components: {
    login,
  },
};
</script>

<style lang="less" scoped>
.contain {
  width: 100%;
  .header {
    width: 100%;
    height: 68px;
    padding: 0 95px 0 79px;
    display: flex;
    align-items: center;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    .logo {
      height: 58px;
      cursor: pointer;
      img {
        height: 100%;
      }
    }
    .nav {
      flex: 1;
      margin-left: 164px;
      display: flex;
      height: 100%;
      li {
        font-size: 16px;
        height: 100%;
        position: relative;
        a {
          color: #333;
          padding: 0 33px;
          display: block;
          height: 100%;
          line-height: 68px;
        }
        .router-link-exact-active {
          color: #a61f23;
          position: relative;
          &::after {
            content: "";
            width: 27px;
            height: 4px;
            background: #a61f23;
            border-radius: 2px;
            display: inline-block;
            position: absolute;
            left: 50%;
            transform: translate(-50%);
            bottom: 10px;
          }
        }
        .line {
          position: absolute;
          bottom: 10px;
          left: 50%;
          transform: translate(-50%);
          width: 27px;
          height: 4px;
          background: #a61f23;
          border-radius: 2px;
        }
      }
    }
  }
  .footer {
    height: 120px;
    background: #fbfbfb;
    padding-top: 50px;
    text-align: center;
    margin-top: 60px;
    .link {
      color: #747674;
    }
    .links {
      color: #747674;
      // margin-top: 12px;
      &:nth-child(-n + 3):after {
        content: "";
        display: inline-block;
        width: 1px;
        height: 20px;
        background: #747674;
        vertical-align: middle;
        margin: 0 20px;
      }
    }
    p {
      color: #747674;
      margin-top: 12px;
    }
  }
}
</style>
