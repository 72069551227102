<template>
  <div>
    <div class="v8">
      <div class="count">
        根据您的选择一共查询出<span>{{ total }}</span
        >条数据
      </div>
    </div>
    <dl class="classfiy v8">
      <dt>当前分类：</dt>
      <div v-if="id">
        <dd>
          {{ name }}
        </dd>
      </div>
      <div v-else>
        <div v-if="label_list.length > 0">
          <dd v-for="(item, index) in label_list" :key="index">
            {{ item }}
          </dd>
        </div>
      </div>
    </dl>
    <div class="waibiankuang">
      <div class="shops" v-if="shoplist.length > 0">
        <shops :shopList="shoplist" :show="true"></shops>
      </div>
      <el-empty :image-size="200" v-else></el-empty>
    </div>

    <div class="v8">
      <!-- 这里是一个分页 -->
      <div class="pages">
        <el-pagination
          :hide-on-single-page="true"
          :page-size="16"
          layout="prev, pager, next"
          :total="total"
          @current-change="changepage"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import shops from "../../components/goods.vue";
import { centerList } from "../../api/index";
export default {
  data() {
    return {
      shoplist: [],
      total: 0,
      id: "",
      file_type: "",
      year: "",
      title: "",
      page: 1,
      label_list: [],
      name: "",
    };
  },
  created() {
    this.file_type = this.$route.query.file_type || "";
    this.year = this.$route.query.year || "";
    this.title = this.$route.query.title || "";
    if (this.$route.query.list) {
      this.label_list = JSON.parse(this.$route.query.list) || [];
      console.log("这个是什么内容", this.label_list);
    }
    if (this.$route.query.name) {
      this.name = this.$route.query.name;
    }
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
    }
    this.getlist();
  },
  methods: {
    async getlist() {
      const res = await centerList(
        "",
        this.id,
        this.file_type,
        this.year,
        this.title,
        this.page,
        this.label_list
      );
      if (res.code === 1) {
        if (res.code === 1) {
          this.shoplist = res.data.datalist;
          this.total = res.data.total;
        } else {
          this.shoplist = [];
          this.total = 0;
        }
      }
    },
    // 切换页码
    changepage(page) {
      this.page = page;
      this.getlist();
    },
  },
  components: {
    shops,
  },
};
</script>

<style lang="less" scoped>
.waibiankuang {
  background-color: #f4f8f8;
  margin-top: 40px;
  padding-top: 50px;
  padding-bottom: 30px;
}
.v8 {
  margin-top: 50px;
  .count {
    color: #b4b4b4;
    text-align: right;
    span {
      color: #2ae1ee;
    }
  }
  .classfiy {
    display: flex;
    margin-top: 26px;
    dt {
      color: #060606;
    }
    dd {
      color: #5a5d5a;
      margin-left: 16px;
    }
  }
  .shops {
  }
  .pages {
    margin-top: 10px;
    text-align: center;
  }
}
</style>
