<template>
  <div class="contain">
    <h5 class="usertitle">
      我的资源单<span>（{{ count }}个文件）</span>
    </h5>
    <ul class="lists clearfix">
      <li
        v-for="item in list"
        :key="item.id"
        @click="tolist(item.id, item.total_doc_num)"
      >
        <div class="center_pic">
          <div class="pic">
            <img src="../../assets/image/coverimg.jpg" alt="" />
          </div>
        </div>
        <div class="defult">
          <span>{{ item.name }}</span>
          <span>{{ item.total_doc_num }}个文件</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mysave } from "../../api/user";
export default {
  data() {
    return {
      list: [],
      count: 0,
    };
  },
  created() {
    this.getmysave();
  },
  methods: {
    tolist(id, num) {
      this.$router.push("/user/list?id=" + id + "&num=" + num);
    },
    async getmysave() {
      const res = await mysave();
      if (res.code === 1) {
        this.list = res.data;
        this.count = res.msg;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.contain {
  width: 100%;
  .usertitle {
    span {
      color: #999;
      font-weight: normal;
    }
  }
  .lists {
    margin-top: 30px;
    width: 100%;
    li {
      margin-bottom: 30px;
      width: 20%;
      margin-right: 6.6%;
      padding: 14px 11px;
      float: left;
      border-radius: 5px;
      cursor: pointer;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      transition: 0.3s;
      &:hover {
        transform: scale(102%);
        box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.16);
      }
      &:nth-child(4n) {
        margin-right: 0;
      }
      .center_pic {
        width: 100%;
        border: 7px solid #ebebeb;
        margin-bottom: 18px;
        .pic {
          position: relative;
          width: 100%;
          padding-bottom: 56%;
          overflow: hidden;
          img {
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      }
      .defult {
        display: flex;
        justify-content: space-between;
        span {
          &:first-child {
            color: #66686b;
            font-size: 14px;
          }
          &:last-child {
            color: #66686b;
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
