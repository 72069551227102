<template>
  <div class="detail">
    <search :distance="42"></search>
    <div class="detail_box">
      <div class="box_top v8">
        <div class="left">
          <div class="center">
            <img :src="detail.cover_img" alt="" />
          </div>
        </div>
        <div class="cent">
          <h4 class="ht">
            {{ detail.title }}
          </h4>
          <ul class="zd">
            <li style="width: 100%">
              <span>类别：</span>
              <router-link
                style="margin-right: 20px"
                :to="{
                  name: 'resolve',
                  query: { name: item.name, id: item.id },
                }"
                v-for="(item, index) in detail.med_type_list"
                :key="index"
              >
                {{ item.name }}
              </router-link>
            </li>
            <!-- <li><span>文件大小：</span>{{ detail.file_size }}</li>
            <li><span>年份：</span>{{ detail.year }}</li>
            <li><span>语言：</span>{{ detail.lang }}</li>
            <li><span>阅读量：</span>{{ detail.view_num }}</li>
            <li><span>下载量：</span>{{ detail.download_num }}</li> -->
            <li>作者：</span>{{ detail.file_author_name }}</li>
            <li>年份：</span>{{ detail.year }}</li>
            <li>语言：</span>{{ detail.lang }}</li>
            <li>阅读量：</span>{{ detail.view_num }}</li>
            <li>下载量：</span>{{ detail.download_num }}</li>
            <li>文件大小：</span>{{ detail.file_size }}</li>
            <li>文件类型：</span>{{ detail.file_type }}</li>
          </ul>
          <div class="buttons">
            <button @click="readonly">
              <i class="iconfont">&#xe72c;</i>
              <div class="tishi">预览并且下载文件内容</div>
              预览/下载
            </button>
            <!-- linear-gradient(136deg, #ffd52d 0%, #ffb22d 100%) -->
            <button
              class="shou"
              @click="addfavorites"
              :style="{
                background:
                  is_collect === 1
                    ? 'linear-gradient(136deg, #999 0%, #999 100%)'
                    : 'linear-gradient(136deg, #ffd52d 0%, #ffb22d 100%)',
              }"
            >
              <i class="iconfont">&#xe72c;</i>
              <div class="tishi">收藏文件内容</div>
              {{ is_collect === 0 ? "收藏" : "已收藏" }}
            </button>
          </div>
        </div>
        <div class="right">
          <h6 class="share">分享</h6>
          <div id="qrcode" class="ercode" ref="qrcode"></div>
          <button class="sharebutton" @click="shareVisible = true">分享</button>
        </div>
      </div>
      <div class="digest">
        <h4>摘要</h4>
        <div>
          {{ detail.content_desc }}
        </div>
      </div>
      <h4 class="boxtitle v8" style="margin-top: 70px; margin-bottom: 24px">
        <i class="iconfont" style="color: #3e90f7">&#xe607;</i>
        兴趣推荐
      </h4>
      <!-- 这里还是引入图片 -->
      <div class="xingqu">
        <shops :shopList="shoplist"></shops>
      </div>
      <!-- 评论专区 -->
      <div class="addpinglun v8">
        <h4 class="boxtitle v8">
          <i class="iconfont" style="color: #dc4830">&#xe697;</i>
          评论专区
        </h4>
        <button class="addpingluns" @click="addping">
          <i class="iconfont">&#xe695;</i>发表评论
        </button>
      </div>
      <ul class="item_box v8" v-if="commentList.length > 0">
        <li v-for="item in commentList" :key="item.id">
          <div class="header">
            <img :src="item.header" alt="" />
          </div>
          <div class="content">
            <h4>{{ item.nickname }}</h4>
            <p>
              {{ item.comment }}
            </p>
            <span>{{ item.create_time }}</span>
          </div>
        </li>
      </ul>
      <el-empty :image-size="200" v-else></el-empty>
      <!-- 这里是一个分页 -->
      <div class="changepage">
        <el-pagination
          :hide-on-single-page="true"
          :page-size="per_page"
          layout="prev, pager, next"
          :total="total"
          @current-change="changepage"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 这里嵌入一个富文本输入框 -->
    <el-dialog title="发表评论" width="30%" :visible.sync="dialogFormVisible">
      <el-input
        type="textarea"
        :autosize="false"
        :rows="10"
        v-model="comment"
        autocomplete="off"
      ></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button  type="info" @click="dialogFormVisible = false">取 消</el-button>
        <el-button class="sure" type="primary" @click="sendcomment"
          >提 交</el-button
        >
      </div>
    </el-dialog>
    <el-dialog title="预览" :visible.sync="readonlyVisible" top='6vh'> 
      <iframe
        :src="priviewUrl"
        frameborder="0"
        width="920px"
        height="600px"
      ></iframe>
      <div slot="footer" class="dialog-footer">
        <el-button
          class="sure"
          type="primary"
          @click="downloads(detail,detail.file_path, detail.title)"
          >查看全部/下载</el-button > 
      </div> 
    </el-dialog>
    <!-- 分享对话框 -->
    <el-dialog title="分享" :visible.sync="shareVisible" width="26%">
      <div class="share_box">
        <div class="shares">
          <div class="share_left">
            <div class="share_logo">
              <img :src="detail.cover_img" alt="" />
            </div>
            <img class="share_img" src="../../assets/image/logo.png" alt="" />
          </div>
          <div class="share_right">
            {{ detail.title }}
          </div>
        </div>
        <div class="share_bottom">
          <ul class="share_box_item">
            <li @click="copy">
              <div class="share_pictor">
                <i class="iconfont"> &#xe63d;</i>
              </div>
              <p>复制链接</p>
            </li>
            <li @click="sharewb">
              <div class="share_pictor">
                <img src="../../assets/image/wb.png" alt="" />
              </div>

              <p>微博好友</p>
            </li>
            <li @click="shareqq">
              <div class="share_pictor">
                <img src="../../assets/image/qq.png" alt="" />
              </div>
              <p>QQ好友</p>
            </li>
          </ul>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 获取detail的接口
import { download } from "../../mixin/index";
import {
  detail,
  commentList,
  sendComment,
  addfavorite,
} from "../../api/detail.js";
import { getUser } from "../../utils/localstore";
import shops from "../../components/goods.vue";
import QRCode from "qrcodejs2";
export default {
  components: {
    shops,
  },
  mixins: [download],
  data() {
    return {
      comment: "",
      dialogFormVisible: false,
      readonlyVisible: false,
      shareVisible: false,
      id: 0,
      shoplist: [],
      detail: "",
      page: 1,
      commentList: [], // 评论的列表数据
      total: 0,
      windowUrl: window.location.href,
      priviewUrl: "",
      per_page: 0,
      is_collect: 0,
      // 分享到qq
      title: "分享测试",
      desc: "测试描述",
      qrcode: "",
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  watch: {
    $route(to, from) {
      this.id = this.$route.query.id;
      this.windowUrl = window.location.href;
      this.page = 1;
      this.getdetail();
      this.getcommentList();
      this.$nextTick(() => {
        this.qrcodes();
      });
    },
  },
  created() {
    this.id = this.$route.query.id;
    this.getdetail();
    this.getcommentList();
    this.$nextTick(() => {
      this.qrcodes();
    });
  },
  methods: {
    // 生成二维码
    qrcodes() {
      const that = this;
      document.getElementById("qrcode").innerHTML = "";
      this.qrcode = new QRCode("qrcode", {
        width: 170, // 二维码宽度，单位像素
        height: 170, // 二维码高度，单位像素 
        text: "http://m.netloop.cn/#/?id=" + that.$route.query.id, // 生成二维码的链接
      });
    },
    // 添加到收藏夹
    async addfavorites() {
      if (this.is_collect === 1) {
        this.$message.warning("已收藏");
      } else {
        const res = await addfavorite(this.id);
        if (res.code === 1) {
          this.is_collect = 1;
          console.log(this.detail);
          this.$message.success("收藏成功");
        } else {
          this.$message.error(res.msg);
        }
      }
    },
    // 获取detail
    async getdetail() {
      const res = await detail(this.id);
      console.log("这个是什么", res);
      if (res.code === 1) {
        this.detail = res.data.detail;
        this.shoplist = res.data.rec_list;
        this.is_collect = res.data.is_collect;
      }
    },
    sharewb() {
      window.open(
        "https://service.weibo.com/share/share.php?url=" +
          encodeURIComponent(this.windowUrl) +
          "&title=" +
          this.title +
          "&pic=" +
          "../../assets/image/ercode.png" +
          "&searchPic=true"
      );
    },
    shareqq() {
      // window.open(
      //   `http://connect.qq.com/widget/shareqq/index.html?url=${encodeURIComponent(
      //     this.windowUrl
      //   )}&sharesource=qzone`
      // )
      window.open(
        "https://connect.qq.com/widget/shareqq/index.html?url=" +
          encodeURIComponent(this.windowUrl) +
          "&title=" +
          this.title +
          "&desc=" +
          this.desc
      );
    },
    // 发表评论
    addping() {
      if (getUser()?.token) {
        this.dialogFormVisible = true;
      } else {
        this.$message.error("先登录再发表评论");
      }
    },
    // 预览pdf文件
    readonly() {
      this.readonlyVisible = true;
      const index = this.detail.preview_path.lastIndexOf(".");
      const ext = this.detail.preview_path.substr(index);
      console.log(ext);
      if (
        ext === ".docx" ||
        ext === ".xlsx" ||
        ext === ".pptx" ||
        ext === ".doc" ||
        ext === ".xls" ||
        ext === ".ppt" ||
        // ext === '.pdf' ||
        ext === ".txt"
      ) {
        this.priviewUrl =
          "https://view.officeapps.live.com/op/view.aspx?src=" +
          this.detail.preview_path;
      } else {
        this.priviewUrl = this.detail.preview_path;
      }
    },
    // 下载文件
    downloadfile() {
      window.location.href = this.detail.file_path;
      this.dialogFormVisible = false;
    },
    // 获取评论的接口
    async getcommentList() {
      const res = await commentList(this.id, this.page);
      if (res.code === 1) {
        this.commentList = res.data.data;
        console.log(this.commentList);
        this.total = res.data.total;
        this.per_page = res.data.per_page;
      }
    },
    //  发表评论
    async sendcomment() {
      if (this.comment.trim() === "") {
        this.$message.error("输入的内容不能为空");
        return;
      }
      const res = await sendComment(this.comment, this.id);
      if (res.code === 1) {
        this.dialogFormVisible = false;
        this.comment = "";
        this.$message.success("发布成功");
        this.getcommentList();
      }
    },
    // 切换页码
    changepage(page) {
      this.page = page;
      this.getcommentList();
    },
    // 点击复制链接
    copy() {
      var domUrl = document.createElement("input");
      domUrl.value = this.windowUrl;
      domUrl.id = "creatDom";
      document.body.appendChild(domUrl);
      domUrl.select(); // 选择对象
      document.execCommand("Copy"); // 执⾏浏览器复制命令
      const creatDom = document.getElementById("creatDom");
      creatDom.parentNode.removeChild(creatDom);
      this.$message({
        message: "复制成功",
        type: "success",
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import url("../../assets/css/title.less");
.br2 {
  border: 1px solid #f1f1f1;
  border-radius: 9px;
}
.detail_box {
  margin-top: 34px;
  width: 100%;
  border-top: 1px solid #f1f1f1;
  .box_top {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left { 
      img {
        width: 100%;
      }
    }
    .cent {
      h4 {
        color: #333333;
        -webkit-line-clamp: 3;
      }
      .zd {
        color: #333333;
        display: flex;
        // justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        li {
          // width: 50%;
          width: 33.33%;
          font-size: 14px;
          span {
            color: #b4b4b4;
          }
        }
      }
      .buttons {
        button {
          cursor: pointer;
          border: 0 none;
          width: 88px;
          height: 29px;
          background: linear-gradient(136deg, #2fd0c2 0%, #4889fb 100%);
          color: #ffffff;
          font-size: 13px;
          border-radius: 9px;
          margin-right: 55px;
          position: relative;

          i {
            position: absolute;
            right: -10px;
            top: -10px;
            font-size: 20px;
          }

          .tishi {
            position: absolute;
            top: -50px;
            left: 0;
            white-space: nowrap;
            background: #fff;
            line-height: 31px;
            color: #098058;
            border: 1px solid #098058;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            padding: 0 10px;
            display: none;
          }
          i:hover + .tishi {
            display: block;
            transition: all 0.5s;
          }
        }
        // .shou {
        //   // background: linear-gradient(136deg, #ffd52d 0%, #ffb22d 100%);
        // }
      }
    }
    .right {
      .share {
        width: 100%;
        height: 43px;
        background: #f6f6f6;
        line-height: 43px;
        color: #333333;
        font-size: 14px;
        text-align: center;
      }

      .ercode {
        width: 200px;
        // margin: 0 auto;
        img {
          width: 100%;
        }
      }
      .sharebutton {
        width: 63px;
        height: 30px;
        background: linear-gradient(136deg, #57b3f9 0%, #4cf0d7 100%);
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.16);
        border-radius: 4px;
        color: #ffffff;
        font-size: 13px;
        line-height: 30px;
        border: 0 none;
        margin: 20px auto 0;
        display: block;
        cursor: pointer;
      }
    }
  }
  .digest {
    width: 1540px;
    margin: 0 auto;
    border: 1px solid #f1f1f1;
    // width: 100%;
    padding: 24px 30px;
    margin-top: 34px;
    border-radius: 9px;
    h4 {
      font-size: 17px;
      color: #333333;
    }
    div {
      color: #747674;
      line-height: 26px;
      margin-top: 30px;
    }
  }
  .addpinglun {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 58px;
    .addpingluns {
      width: 88px;
      height: 29px;
      background: linear-gradient(136deg, #2fd2c1 0%, #498afd 100%);
      border-radius: 4px;
      border: 0 none;
      color: #ffffff;
      font-size: 13px;
      line-height: 29px;
      cursor: pointer;
      i {
        font-size: 13px;
        line-height: 29px;
        color: #ffffff;
        margin-right: 3px;
      }
    }
  }
  .item_box {
    margin-top: 28px;
    li {
      display: flex;
      width: 100%;
      margin-bottom: 60px;
      .header {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
      .content {
        flex: 1;
        margin-left: 20px;
        background: #f9f9f9;
        padding: 14px 22px;
        h4 {
          font-size: 14px;
          color: #333333;
        }
        p {
          font-size: 14px;
          color: #818181;
          margin-top: 19px;
          line-height: 28px;
        }
        span {
          text-align: right;
          display: block;
          margin-top: 20px;
          color: #818181;
        }
      }
    }
  }
}
.share_box {
  margin: 0px 0px;
  .shares {
    display: flex;
    .share_left {
      border: 1px solid #f2f2f2;
      padding: 11px;
      padding-bottom: 22px;
      .share_logo {
        width: 100%;
        height: 100%;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
      .share_img {
        width: 34px;
        margin-top: 4px;
      }
    }
    .share_right {
      margin-left: 20px;
      flex: 1;
      font-size: 13px;
      line-height: 23px;
      color: #2d2d2d;
    }
  }
  .share_bottom {
    margin-top: 40px;
    .share_box_item {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      li {
        text-align: center;
        margin-right: 20px;
        .share_pictor {
          width: 48px;
          height: 48px;
          margin: 0 auto;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #f3f5f7;
          cursor: pointer;
        }
        img {
          height: 26px;
        }
        p {
          margin-top: 6px;
          color: #b4b4b4;
          font-size: 14px;
        }
      }
    }
  }
}
.changepage {
  margin-top: 10px;
  text-align: center;
}
/deep/ .el-dialog__header {
  background: linear-gradient(180deg, #dbffef 0%, #ffffff 100%);
}
/deep/ .dialog-footer {
  display: flex;
  justify-content: space-around;
  button {
    cursor: pointer;
  }
  .sure {
    // background: linear-gradient(136deg, #0ad28f 0%, #00b277 100%);
    // border: 1px solid #00b277;
    background: linear-gradient(136deg, #2FD2C1 0%, #4888FC 100%);
    border: 1px solid #2FD2C1;  
  }
}
@media screen and(min-width:1600px) {
  .detail {
    // width: 1540px;
    margin: 0 auto;
    .left {
      // padding: 30px;
      padding: 24px 30px;
      width: 281px;
      height: 385px;
      // width: 341px;   
      // height: 433px; 
      border: 1px solid #C0D3EE !important; 
      background-color: #F1F7FF; 
      .br2; 
      .center {
        // border: 32px solid #ebebeb;
        border-radius: 9px;
        overflow: hidden;
        width: 100%; 
        height: 100%;
        img{
          width: 100%;
          height: 100%; 
        }
      }
    }
    .cent {
      // width: 492px;
      // height: 444px;
      width: 846px;
      height: 385px;
      margin-left: 34px;
      .br2;
      padding: 30px;
      .ht {
        font-size: 17px;
        line-height: 34px;
      }
      .zd {
        li {
          margin-top: 30px;
        }
      }
      .buttons {
        margin-top: 30px;
      }
    }
    .right {
      width: 293px;
      // height: 444px;
      height: 385px;
      .br2;
      margin-left: 34px;
      .ercode {
        // margin: 40px auto 0;
        margin: 40px auto 0; 
        padding: 15px;
        background-color: #F6F6F6; 
      }
    }
    .share_box {
      .shares {
        .share_left {
          width: 177px;
          height: 124px;
        }
      }
    }
  }
}
@media screen and(max-width:1599px) {
  .detail {
    width: 1200px;
    margin: 0 auto;
    .search {
      width: 50%;
    }
    .left {
      padding: 24px;
      width: 549.6px;
      height: 355.2px;
      border-radius: 7.2px;
      .br2;
      .center {
        // border: 25.6px solid #ebebeb;
        border-radius: 7.2px;
        overflow: hidden;
        height: 100%;
      }
    }
    .cent {
      // width: 393.6px;
      width: 846px
;
      height: 355.2px;
      margin-left: 27.2px;
      .br2;
      padding: 24px;
      .ht {
        font-size: 18px;
        line-height: 32px;
      }
      .zd {
        li {
          margin-top: 20px;
        }
      }
      .buttons {
        margin-top: 20px;
      }
    }
    .right {
      width: 234.4px;
      height: 355.2px;
      .br2;
      margin-left: 27.2px;
      .ercode {
        margin: 40px auto 0;
      }
    }
    .share_box {
      .shares {
        .share_left {
          width: 141px;
          height: 100px;
        }
      }
    }
  }
}

.xingqu {
  background-color: #f4f8f8;
  padding: 50px 0 30px 0;
}
</style>
