import axios from '../utils/request'
// 我的收藏夹
export const mysave = () => axios.post('user/myCollect')
// 查看收藏夹
// eslint-disable-next-line
export const lookshoucang = (collect_id) => axios.post('user/viewCollect', { collect_id })
// 查看下载历史
export const download = (title = '', file_type = '', page, limit) => axios.post('user/downHistory', { title, file_type, page, limit })
// 清空下载记录
export const clearhistory = (is_clear_all = '', doc_id = '') => axios.post('user/clearDownHistory', { is_clear_all, doc_id })
// 删除收藏夹
export const removecollect = (collect_id = '', doc_id = '') => axios.post('user/clearCollect', { collect_id, doc_id })
// 获取个人中心的数据
export const getuser = () => axios.get('user/info')
/**
 *
 * @param {*} nickname 更新昵称
 * @param {*} email 更新邮箱
 * @param {*} bio 更新研究兴趣
 * @returns
 */
// eslint-disable-next-line camelcase
export const chengeuser = (nickname, email, bio, mobile = '', sms_code = '', avatar) => axios.post('user/setInfo', { nickname, email, bio, mobile, sms_code, avatar })
// 上传文件
export const upload = (data) => axios.post('common/upload', data)

 