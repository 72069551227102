<template>
  <div class="contain">
    <div class="swiper_box">
      <div class="swiper_">
        <swiper
          ref="mySwiper"
          :options="swiperOptions"
          v-if="banner.length > 0"
        >
          <swiper-slide v-for="item in banner" :key="item.id">
            <img
              @click="toothorwebsit(item.jump_url)"
              :src="item.banner_img"
              alt=""
            />
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { banners } from "../../../api/index";
export default {
  name: "Home",
  data() {
    return {
      swiperOptions: {
        loop: true,
        autoplay: true,
        slidesPerView: "auto",
        speed: 1000,
      },
      banner: [],
    };
  },
  created() {
    this.getswiper();
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  methods: {
    /* 前一步 */
    async getswiper() {
      const res = await banners();

      if (res.code === 1) {
        this.banner = res.data;
      }
    },
    // 跳转到外部链接
    toothorwebsit(url) {
      if (url) {
        window.open(url);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.swiper_box {
  width: 100%;
  background: #fff;
  /deep/ .swiper-slide {
    width: 100%;
  }
  img {
    width: 100%;
  }
}
</style>
