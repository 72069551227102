import store from '../store/index'
import axios from 'axios'
import router from '../router/index'
import { getUser } from './localstore'
const instance = axios.create({
  // baseURL: 'https://yxy.medsky.cn/api/'
  // baseURL: 'http://yixinyuan.cc/api/'
  // baseURL: 'http://yxytest.medsky.cn/api/'      
  baseURL: 'https://yxy.netloop.cn/api/' 
})         
// 添加请求拦截器 
instance.interceptors.request.use(function (config) {
  if (getUser() && getUser().token) {
    config.headers.token = getUser()?.token
  }

  // 在发送请求之前做些什么
  return config
}, function (error) {
  // 对请求错误做些什么 
  return Promise.reject(error)
})

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  const data = response.data
  // console.log("相应拦截数据",response);
  return data
}, function (error) {
  // console.log("被拦截了",error); 
  store.commit('user/removeuser')
  // router.push('/') 
  // 对响应错误做点什么  
  // this.$router.push('/')
  return Promise.reject(error)
})
// 请求接口
const http = { 
  get (url = '', params = '') {
    return new Promise((resolve, reject) => {
      instance({
        url,
        params,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
        method: 'get'
      }).then((res) => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  post (url = '', params = '') {
    return new Promise((resolve, reject) => {
      instance({
        url,
        data: params,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' },
        method: 'post'
      }).then((res) => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  }
}
export default http
