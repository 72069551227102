<template>
  <div class="search" :style="{ marginTop: distance + 'px' }">
    <div class="search_center">
      <el-input v-model="value" placeholder="全站搜索">
        <el-button slot="append" @click="search" class="acitonButton">
          <!--   icon="el-icon-search" -->
          <div class="btn-img">
            <img src="@/assets/image/icon/sousuo.png" />
          </div>
        </el-button>
      </el-input>
      <el-button style="width: 147px" @click="heightsearch">高级搜索</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: "",
    };
  },
  props: {
    distance: {
      type: Number,
      default: 10,
    },
  },
  methods: {
    search() {
      if (this.value !== "") {
        // this.$router.push('/resolve?title=' + this.value)
        this.$router.push({ name: "resolve", query: { title: this.value } });
      } else {
        this.$message.warning("请输入搜索信息");
      }
    },
    heightsearch() {
      // 跳转到高级搜索
      this.$router.push("/searchs");
    },
  },
};
</script>

<style lang="less" scoped>
.search {
  width: 45%;
  margin: 0 auto;
  .search_center {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .el-input {
    margin-right: 18px;
    box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.16);
    // height: 60px;
    /deep/ input {
      height: 60px;
    }
    .el-button {
      border-radius: 0;
      // width: 60px;
      width: 72px;
      height: 60px;
    }
    /deep/ .el-icon-search {
      &::before {
        color: #fff;
      }
    }
  }
  .btn-img {
    width: 30px;
    height: 30px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .el-button {
    // background-image: linear-gradient(to right, #0ad28f, #00b277);
    background: linear-gradient(136deg, #ff5859 0%, #cb2f2f 100%);
    color: #fff;
    border: 1px solid #ff5859;
  }
}
</style>
