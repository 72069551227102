<template>
  <div class="yxy">
    <search :distance="42"></search>
    <div class="search_box v8">
      <div class="type_list">
        <div class="lables">种类：</div>
        <ul
          class="item_box"
          ref="list_box"
          :style="{ height: show === true ? 'auto' : '52px' }"
        >
          <li
            :class="indexs === '' ? 'active' : ''"
            @click="activeindex('', '')"
            ref="listall"
          >
            全部
          </li>
          <li
            ref="lists"
            :class="index === indexs ? 'active' : ''"
            v-for="(item, index) in list"
            :key="index"
            @click="activeindex(index, item)"
          >
            {{ item.nickname }}
          </li>
          <li
            class="iconfont zhankai"
            v-if="iconshow === true"
            @click="show = !show"
          >
            {{ show === true ? "\ue706" : "\ue62d" }}
          </li>
        </ul>
      </div>
      <!-- 子级 -->
      <ul class="ziji-ul" v-if="ziShow">
        <li
          :class="ziIndex == index ? 'ziji-ul-li-active' : ''"
          class="ziji-ul-li"
          v-for="(item, index) in ziList"
          :key="index"
          @click="zijiClick(item, index)"
        >
          {{ item.name }}
        </li>
      </ul>
      <!-- 子级 -->
      <!-- 子子级 -->
      <ul class="ziziji-ul" v-if="ziziShow">
        <li
          :class="ziziIndex == index ? 'ziziji-ul-li-active' : ''"
          class="ziziji-ul-li"
          v-for="(item, index) in ziziList"
          :key="index"
          @click="zizijiClick(item, index)"
        >
          {{ item.name }}
        </li>
      </ul>
      <!--  子子级-->
      <div class="type_list">
        <div class="lables">文件类型：</div>
        <ul class="item_box">
          <li
            :class="typeindex === '' ? 'active' : ''"
            @click="changetype('', '')"
          >
            全部
          </li>
          <li
            :class="index === typeindex ? 'active' : ''"
            v-for="(item, index) in type"
            :key="index"
            @click="changetype(index, item.file_type)"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
      <div class="type_list">
        <div class="lables">年份：</div>
        <ul class="item_box">
          <li
            :class="yearindex === '' ? 'active' : ''"
            @click="changeyear('', '')"
          >
            全部
          </li>
          <li
            :class="index === yearindex ? 'active' : ''"
            v-for="(item, index) in years"
            :key="item"
            @click="changeyear(index, item)"
          >
            {{ item }}
          </li>
        </ul>
      </div>
      <div class="type_list">
        <div class="lables">其他：</div>
        <div class="input_box">
          <input type="text" v-model="title" placeholder="请输入标题名称" />
          <button @click="clickbutton" style="background: #4089e8">搜索</button>
        </div>
      </div>
    </div>
    <!-- 这里又是商品了 -->
    <div
      style="
        margin-top: 36px;
        background-color: #f4f8f8;
        padding: 50px 0 30px 0;
      "
      v-if="shoplist.length > 0"
    >
      <shops :shopList="shoplist" :show="true"></shops>
    </div>
    <el-empty :image-size="200" v-else></el-empty>
    <div class="pages">
      <el-pagination
        :hide-on-single-page="true"
        :page-size="16"
        layout="prev, pager, next"
        :total="total"
        @current-change="changepage"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { doctor, doctype } from "../../api/yxy";
import { centerList } from "../../api/index";
import shops from "../../components/goods.vue";
export default {
  name: "yxy",
  data() {
    return {
      iconshow: false, // 是否显示图标
      show: false, // 是否下拉
      indexs: "",
      typeindex: "",
      yearindex: "",
      list: [],
      type: [],
      years: [],
      shoplist: [],
      classfiyId: "",
      typeId: "",
      yearId: "",
      title: "",
      page: 1,
      total: 0,
      //种类新增
      ziList: [],
      ziShow: false,
      ziIndex: -1,
      ziziList: [],
      ziziShow: false,
      ziziIndex: -1,
    };
  },
  components: {
    shops,
  },
  async created() {
    await this.getclassfiy();
    await this.getdoctype();
    await this.getyear();
    this.getcenterList();
  },

  methods: {
    // 获取年份
    getyear() {
      const data = new Date();
      var year = data.getFullYear();
      for (var i = 0; i < 5; i++) {
        this.years.push(year);
        year -= 1;
      }
    },
    changelength() {
      var listWidth = 0;
      for (var i = 0; i < this.$refs.lists.length; i++) {
        listWidth += this.$refs.lists[i].offsetWidth;
      }
      // 父亲元素容纳标签的宽度
      var fathorWidth = this.$refs.list_box.offsetWidth - 20;
      // 全部标签的宽度
      var listall = this.$refs.listall.offsetWidth;
      if (fathorWidth * 2 < listall + listWidth) {
        this.iconshow = true;
      } else {
        this.iconshow = false;
      }
    },
    // 获取医信源
    async getclassfiy() {
      const that = this;
      const res = await doctor();
      console.log(res);
      if (res.code === 1) {
        this.list = res.data;
        if (this.$route.params.cataid) {
          this.classfiyId = this.$route.params.cataid;
          this.indexs = this.list.findIndex((element) => {
            return element.id === that.$route.params.cataid;
          });
        }

        console.log("这个是什么索引", this.indexs);
        this.$nextTick(() => {
          this.changelength();
        });
      }
    },
    async getdoctype() {
      const res = await doctype();
      if (res.code === 1) {
        this.type = res.data;
        if (this.$route.params.type) {
          this.typeId = this.$route.params.type;
          this.typeindex = this.type.findIndex((element) => {
            return element.file_type === this.$route.params.type;
          });
        }
      }
    },
    //种类点击
    activeindex(index, item) {
      this.indexs = index;
      this.classfiyId = item.id; //原来点击请求数据
      //点击取到子级数据并展示
      if (item.children != undefined) {
        this.ziShow = true;
        this.ziList = item.children;
        this.ziIndex = -1; //切换父 隐藏子的选中
        this.ziziShow = false; //切换父 隐藏子子的展示
      } else {
        this.ziList = [];
        this.ziziList = [];
        this.ziShow = false;
        this.ziziShow = false; //切换父 隐藏子子的展示
      }
      //全部
      if (index == "" || item == "") {
        //子级
        this.ziList = [];
        this.ziShow = false;
        this.ziIndex = -1;
        //子子级
        this.ziziList = [];
      }
    },
    //子级点击
    zijiClick(item, index) {
      this.ziIndex = index;
      this.ziziIndex = -1;
      this.classfiyId = item.id; //原来点击请求数据
      if (item.children != undefined) {
        this.ziziShow = true;
        this.ziziList = item.children;
      } else {
        this.ziziList = [];
        this.ziziShow = false;
      }
    },
    //子子级
    zizijiClick(item, index) {
      this.ziziIndex = index;
      this.classfiyId = item.id; //原来点击请求数据
    },
    changetype(index, id) {
      this.typeindex = index;
      this.typeId = id;
    },
    changeyear(index, id) {
      this.yearindex = index;
      this.yearId = id;
    },
    animation() {
      this.$nextTick(() => {
        new this.$wow.WOW({
          boxClass: "wow",
          animateClass: "animated",
          offset: 0,
          mobile: true,
          live: true,
        }).init();
      });
    },
    // 获取数据
    async getcenterList() {
      const res = await centerList(
        "",
        this.classfiyId,
        this.typeId,
        this.yearId,
        this.title,
        this.page
      );
      if (res.code === 1) {
        console.log("列表数据", res.data);
        this.shoplist = res.data.datalist;
        this.total = res.data.total;
        this.animation();
      } else {
        this.shoplist = [];
        this.total = 0;
      }
    },
    // 切换页码
    changepage(page) {
      this.page = page;
      this.getcenterList();
      // chrome
      document.body.scrollTop = 0;
      // firefox
      document.documentElement.scrollTop = 0;
      // safari
      window.pageYOffset = 0;
      // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
    },
    clickbutton() {
      this.page = 1;
      this.getcenterList();
    },
  },
};
</script>

<style lang="less" scoped>
.yxy {
  .search_box {
    border: 1px solid #f1f1f1;
    border-left: 0;
    border-right: 0;
    margin-top: 34px;
    padding-bottom: 28px;
    .type_list {
      display: flex;
      margin-top: 17px;
      .lables {
        width: 70px;
        height: 26px;
        line-height: 26px;
        margin-top: 20px;
        color: #060606;
      }
      .item_box {
        flex: 1;
        overflow: hidden;
        position: relative;
        padding-right: 20px;
        li {
          float: left;
          padding: 0 27px;
          height: 26px;
          margin-top: 20px;
          line-height: 26px;
          color: #5a5d5a;
          cursor: pointer;
          border-radius: 15px;
        }
        .active {
          background: linear-gradient(136deg, #3fd7e8 0%, #1eb8c9 100%);
          color: #fff;
        }
      }
      .input_box {
        margin-left: 24px;
        margin-top: 17px;
        input {
          width: 320px;
          height: 29px;
          border: 1px solid #dfdfdf;
          border-radius: 4px;
          padding-left: 20px;
        }
        button {
          width: 60px;
          height: 31px;
          background: linear-gradient(136deg, #0ad28f 0%, #00b277 100%);
          border-radius: 4px;
          border: 0 none;
          color: #ffffff;
          margin-left: 18px;
          cursor: pointer;
        }
      }
      .zhankai {
        position: absolute;
        right: 0px;
        bottom: 0px;
        font-size: 10px;
        color: #05c383;
      }
    }
  }
  .pages {
    margin-top: 10px;
    text-align: center;
  }
}

.ziji-ul {
  width: 95.5%;
  position: relative;
  top: 14px;
  left: 70px;
  background-color: #fff;
  padding: 16px 73px;
  display: flex;
  box-shadow: 0px 0px 6px 1px #d0f1f1;
  .ziji-ul-li {
    margin-right: 37px;
    cursor: pointer;
    padding: 4px 10px;
    border-radius: 3px;
  }
  .ziji-ul-li-active {
    color: #fff;
    background-color: #4285c2;
  }
}

.ziziji-ul {
  width: 95.5%;
  position: relative;
  top: 14px;
  left: 70px;
  background-color: #f5f5f5;
  padding: 16px 73px;
  display: flex;
  // box-shadow: 0px 0px 6px 1px #d0f1f1;
  margin-bottom: 10px;
  .ziziji-ul-li {
    margin-right: 37px;
    cursor: pointer;
    padding: 4px 10px;
    border-radius: 3px;
  }
  .ziziji-ul-li-active {
    // color: #fff;
    // background-color: #4285c2;
    color: #1356f1;
  }
}
</style>
