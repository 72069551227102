import axios from '../utils/request'
// 首页的接口
export const getindex = () => axios.post('doc/index')
// 获取banner的接口
export const banners = () => axios.get('doc/banner')
// 中间部分列表数据
/**
 * @param {*} is_rec 是否是推荐
 * @param {*} cate_id 分类的id
 * @param {*} file_type 文件类型
 * @param {*} year 年份
 * @param {*} title 标题 
 * @param {*} page 页码
 * @returns
 */
export const centerList = (is_rec = '', cate_id = '', file_type = '', year = '', title = '', page = '', label_list = [], limit = 18) => axios.post('doc/list', { is_rec, cate_id, file_type, year, title, page, label_list, limit })
 
//图书馆 
export const tushuguan = (data) => axios.post('ajax/category',data) 
    