<template>
  <div class="librarys">
    <!-- 搜索 -->
    <div class="search">
      <div class="search_center">
        <el-input v-model="value" placeholder="全站搜索">
          <el-button slot="append" @click="search" class="acitonButton">
            <div class="btn-img">
              <img src="@/assets/image/icon/sousuo.png" />
            </div>
          </el-button>
        </el-input>
      </div>
    </div>
    <!-- 搜索 -->
    <!-- 背景 -->
    <div class="librarys-bgc">
      <img src="@/assets/image/tushuguanbgc.png" />
      <div class="librarys-bgc-name">{{ $route.query.name }}</div>
    </div>
    <!-- 背景 -->
    <!-- 文件类型 -->
    <ul class="librarys-tabs">
      <li style="margin-right: 6px">文件类型：</li>
      <li
        class="librarys-tabs-item"
        :class="
          activeIndex == $route.query.pid ? 'librarys-tabs-item-active' : ''
        "
        @click="quanbuClick"
      >
        全部
      </li>
      <li
        class="librarys-tabs-item"
        v-for="(item, index) in tabasList"
        :key="index"
        @click="tabsClick(item, index)"
        :class="index == activeIndex ? 'librarys-tabs-item-active' : ''"
      >
        {{ item.name }}
      </li>
    </ul>
    <!-- 文件类型 -->
    <div>
      <el-empty v-if="list.length == 0" description="暂无数据"></el-empty>
      <!-- 列表 -->
      <ul v-else class="list">
        <li
          class="list-item"
          v-for="(item, index) in list"
          :key="index"
          @click="listClick(item, index)"
        >
          <div class="list-item-img"><img :src="item.cover_img" /></div>
          <div class="list-item-box">
            <div class="list-item-box-text">
              {{ item.title }}
            </div>
            <div class="list-item-box-bootm">
              <div class="list-item-box-bootm-name">
                作者：{{ item.file_author_name }}
              </div>
              <div class="list-item-box-bootm-yan">
                <div class="list-item-box-bootm-yan-img">
                  <img src="@/assets/image/icon/yan.png" />
                </div>
                <div class="list-item-box-bootm-yan-num">
                  {{ item.view_num }}
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <!-- 列表 -->
    </div>
    <!-- 分页 -->
    <div class="pages">
      <el-pagination
        :hide-on-single-page="true"
        :page-size="16"
        layout="prev, pager, next"
        :total="total"
        @current-change="changepage"
      >
      </el-pagination>
    </div>
    <!-- 分页 -->
  </div>
</template>
<script>
import { librarysList, getcid } from "../../api/librarys";
export default {
  name: "librarys",
  data() {
    return {
      value: "",
      tabasList: [],
      list: [],
      page: 1,
      total: 0,
      activeIndex: this.$route.query.pid,
      pid: this.$route.query.pid,
      cid: 0,
    };
  },
  created() {
    this.getcidApi();
    this.librarysListApi();
  },
  methods: {
    //搜索
    search() {
      if (this.value !== "") {
        // this.$router.push({ name: "resolve", query: { title: this.value } });
        this.page = 1;
        this.librarysListApi();
      } else {
        this.$message.warning("请输入搜索信息");
      }
    },
    //全部切换
    quanbuClick() {
      this.activeIndex = this.$route.query.pid;
      this.cid = 0;
      this.page = 1;
      this.librarysListApi();
    },
    //tabs切换
    tabsClick(item, index) {
      this.activeIndex = index;
      this.cid = item.id;
      this.page = 1;
      this.librarysListApi();
    },
    //cid
    async getcidApi() {
      const res = await getcid({
        pid: this.$route.query.pid, //大类别
      });
      this.tabasList = res.data.datalist;
    },
    //数据列表
    async librarysListApi() {
      const res = await librarysList({
        keywords: this.value, //标题
        pid: this.$route.query.pid, //大类别
        cid: this.cid, //小类别
        page: this.page,
      });
      this.total = res.data.total;
      this.list = res.data.data;
    },
    //数据点击
    listClick(item, index) {
      this.$router.push("/detail?id=" + item.doc_id);
    },
    // 切换页码
    changepage(page) {
      this.page = page;
      this.librarysListApi();
      // chrome
      document.body.scrollTop = 0;
      // firefox
      document.documentElement.scrollTop = 0;
      // safari
      window.pageYOffset = 0;
      // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
    },
  },
};
</script>
<style lang="less" scoped>
.librarys {
  position: relative;
  //搜索
  .search {
    width: 45%;
    margin: 0 auto;
    margin-top: 42px;
    .search_center {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .el-input {
      margin-right: 18px;
      box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.16);
      /deep/ input {
        height: 60px;
      }
      .el-button {
        border-radius: 0;
        width: 72px;
        height: 60px;
      }
      /deep/ .el-icon-search {
        &::before {
          color: #fff;
        }
      }
    }
    .btn-img {
      width: 30px;
      height: 30px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .el-button {
      background: linear-gradient(136deg, #ff5859 0%, #cb2f2f 100%);
      color: #fff;
      border: 1px solid #ff5859;
    }
  }
  //图书馆背景图
  .librarys-bgc {
    position: relative;
    width: 100%;
    height: 106px;
    margin: 54px 0;
    img {
      width: 100%;
      height: 100%;
    }
    .librarys-bgc-name {
      color: #164a78;
      font-size: 22px;
      position: absolute;
      top: 38px;
      left: 190px;
    }
  }
  //切换
  .librarys-tabs {
    margin: 0 190px;
    display: flex;
    align-items: center;
    color: #5a5d5a;
    font-size: 13px;
    .librarys-tabs-item {
      padding: 6px 13px;
      background-color: #fff;
      border-radius: 15px;
      margin-right: 27px;
      cursor: pointer;
      // &:hover {
      //   color: #fff;
      //   background: linear-gradient(136deg, #3fd7e8 0%, #4986fe 100%);
      // }
    }
    .librarys-tabs-item-active {
      color: #fff;
      background: linear-gradient(136deg, #3fd7e8 0%, #4986fe 100%);
    }
  }
  //列表
  .list {
    margin-top: 35px;
    padding: 50px 190px;
    background-color: #f4f8f8;
    display: flex;
    flex-wrap: wrap;
    .list-item {
      width: 486px;
      display: flex;
      background-color: #fff;
      padding: 18px;
      border-radius: 9px;
      transition: 0.3s;
      cursor: pointer;
      margin-bottom: 20px;
      &:nth-child(3n + 2) {
        margin: 0 30px;
        margin-bottom: 20px;
      }
      &:hover {
        transform: scale(102%);
        box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.16);
      }
      .list-item-img {
        width: 123px;
        height: 169px;
        margin-right: 20px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .list-item-box {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .list-item-box-text {
          color: #1c1c1c;
          font-size: 14px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 7;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .list-item-box-bootm {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .list-item-box-bootm-name {
            color: #848484;
            font-size: 14px;
          }
          .list-item-box-bootm-yan {
            display: flex;
            align-items: center;
            .list-item-box-bootm-yan-img {
              width: 18px;
              height: 18px;
              margin-right: 9px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .list-item-box-bootm-yan-num {
              color: #848484;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  //分页
  .pages {
    margin: 50px 0;
    text-align: center;
  }
}
</style>
