<template>
  <div class="list">
    <h5 class="usertitle">
      我的资源单<span>（{{ $route.query.num }}个文件）</span>
    </h5>
    <div>
      <div class="box" v-for="(item, index) in list" :key="index">
        <!-- 文献 -->
        <div class="box-lis">
          <div class="box-lis-img"><img :src="item.file_type_icon" /></div>
          <div class="box-lis-text">{{ item.file_type }}</div>
        </div>
        <ul class="dan-ul">
          <li
            class="dan-ul-li"
            v-for="(items, index) in item.doc_list"
            :key="index"
          >
            <div class="dan-ul-li-img"><img :src="items.cover_img" /></div>
            <div class="dan-ul-li-box">
              <div class="dan-ul-li-box-text" v-if="items.title">
                {{ items.title }}
              </div>
              <div class="dan-ul-li-box-bottom">
                <div class="dan-ul-li-box-bottom-name">
                  作者：{{ items.file_author_name }}
                </div>
                <div class="dan-ul-li-box-bottom-box">
                  <div class="dan-ul-li-box-bottom-box-img">
                    <img src="@/assets/image/icon/yan.png" />
                  </div>
                  <div class="dan-ul-li-box-bottom-box-num">
                    {{ items.view_num }}
                  </div>
                </div>
              </div>
            </div>
            <div class="dan-ul-li-shanchu" @click="removeresource(items)">
              删除
            </div>
          </li>
        </ul>
        <!-- 文献 -->
      </div>
    </div>

    <!-- 删除资源单 -->
    <el-dialog
      title="删除"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <span class="poptext">确定要删除该收藏文件吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button class="changecolor" type="primary" @click="removeitem"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="删除"
      :visible.sync="dialogVisibleall"
      width="30%"
      :before-close="handleCloseall"
    >
      <span class="poptext">确定要清空所有的收藏文件吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleall = false">取 消</el-button>
        <el-button
          class="changecolor"
          type="primary"
          @click="dialogVisibleall = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import goods from "../../components/goods.vue";
import { lookshoucang, removecollect } from "../../api/user";
export default {
  name: "list",
  data() {
    return {
      // 对话框
      dialogVisible: false,
      dialogVisibleall: false,
      id: this.$route.query.id,
      list: [],
      removeId: "",
    };
  },
  components: {
    goods,
  },
  created() {
    this.getmysave();
  },
  methods: {
    // 获取我的收藏夹文件
    async getmysave() {
      console.log(this.id);
      const res = await lookshoucang(this.id);
      console.log("是我要的数据吗？", res);
      if (res.code === 1) {
        this.list = res.data;
      } else {
        this.list = [];
      }

      console.log(res);
    },
    // 删除资源单
    handleClose() {
      this.dialogVisible = false;
    },
    handleCloseall() {
      this.dialogVisibleall = false;
    },
    removeall() {
      this.dialogVisibleall = true;
    },
    removeresource(datas) {
      //author_id
      console.log(datas);
      this.removeId = datas._id;
      this.dialogVisible = true;
    },
    // 删除接口
    async removecollect() {
      const res = await removecollect("", this.removeId);
      console.log("删除数据", res);
      if (res.code === 1) {
        this.$message.success(res.msg);
        this.getmysave();
      } else {
        this.$message.error(res.msg);
      }
    },
    removeitem() {
      // 这里调用删除资源单的接口
      this.removecollect();
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.list {
  @import url("../../assets/css/title.less");
  .buttons {
    margin-top: 34px;
  }
  .list_box {
    margin-top: 31px;
    border-top: 1px solid #f1f1f1;
  }
  .v8 {
    width: 100%;
    margin-top: 14px;
  }
  .removeitem {
    position: absolute;
    right: -14px;
    top: 4px;
    i {
      display: block;
      width: 29px;
      height: 29px;
      background: rgba(0, 0, 0, 0.39);
      border-radius: 5px;
      font-size: 18px;
      line-height: 29px;
      text-align: center;
      color: #fff;

      &:hover {
        background: rgba(227, 66, 33, 0.39);
      }
    }
  }
  @import url("../../assets/css/tankuan.less");
}

.zhuangkou {
  overflow: hidden;
  background-color: #f4f8f8;
  padding: 30px 27px 7px 27px;
  margin-top: 27px;
}

//新的
.box {
  margin-top: 28px;
  margin-bottom: 42px;
  .box-lis {
    display: flex;
    align-items: center;
    margin-bottom: 27px;
    .box-lis-img {
      width: 17px;
      height: 17px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .box-lis-text {
      color: #060606;
      font-size: 16px;
      margin-left: 7px;
    }
  }
  .dan-ul {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    background-color: #f4f8f8;
    padding: 30px 27px 0px;
    .dan-ul-li {
      position: relative;
      cursor: pointer;
      margin-right: 64px;
      margin-bottom: 63px;
      background-color: #fff;
      border-radius: 9px;
      padding: 18px;
      display: flex;
      transition: 0.3s;
      .dan-ul-li-img {
        width: 123px;
        height: 169px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .dan-ul-li-box {
        width: 309px;
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .dan-ul-li-box-text {
          //编写数据
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 7;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .dan-ul-li-box-bottom {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .dan-ul-li-box-bottom-name {
          }
          .dan-ul-li-box-bottom-box {
            display: flex;
            align-items: center;
            .dan-ul-li-box-bottom-box-img {
              width: 18px;
              height: 18px;
              margin-right: 10px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .dan-ul-li-box-bottom-box-num {
            }
          }
        }
      }

      .dan-ul-li-shanchu {
        position: absolute;
        bottom: -34px;
        right: 0;
        width: 44px;
        height: 25px;
        color: #db1818;
        border-radius: 3px;
        border: 1px solid #db1818;
        text-align: center;
        line-height: 24px;
      }
      &:hover {
        transform: scale(102%);
        box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.16);
      }
    }
  }
}
</style>
