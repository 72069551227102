<template>
  <div id="certification">
    <h5 class="usertitle">实名认证</h5>
    <div class="v8 certification-box">
      <div v-if="renzheng.user_info">
        <!--certification-head中的数据    v-if="renzheng.user_info.qualification == 2" -->
        <div class="certification-head">
          <div class="certification-head-zhuangtai">
            <!--  -->
            <span>审核状态：</span>
            <span
              class="certification-head-zhuangtai-y"
              v-if="renzheng.user_info.qualification == 1"
              >审核中</span
            >
            <span
              class="certification-head-zhuangtai-red"
              v-if="renzheng.user_info.qualification == 3"
              >审核驳回</span
            >
            <span
              class="certification-head-zhuangtai-r"
              v-if="renzheng.user_info.qualification == 2"
              >审核成功</span
            >
          </div>

          <div
            class="certification-head-bohui"
            v-if="renzheng.user_info.qualification == 3"
          >
            <span>驳回原因：</span>
            <span class="certification-head-zhuangtai-red">
              {{ renzheng.user_info.qualification_remark }}</span
            >
          </div>
        </div>
      </div>
      <!-- 内容 -->
      <div style="width: 90%">
        <el-form ref="form" :model="form" label-width="100px" :rules="rules">
          <!-- 标题 -->
          <el-form-item label="姓名" prop="name">
            <el-input v-model="form.name" placeholder="请输入姓名"></el-input>
          </el-form-item>

          <el-form-item label="手机号" prop="phone">
            <el-input v-model="form.phone" placeholder="请输入姓名"></el-input>
          </el-form-item>

          <el-form-item label="省份/城市" prop="file_type">
            <el-cascader
              style="width: 100%"
              v-model="renzhenValue"
              placeholder="请选择省份/城市"
              :options="options"
              :props="categorProps"
            />
          </el-form-item>

          <el-form-item label="医院名称" prop="hospital">
            <el-input
              v-model="form.hospital"
              placeholder="请输入医院名称"
            ></el-input>
          </el-form-item>

          <el-form-item label="职业" prop="job">
            <el-input v-model="form.job" placeholder="请输入职业"></el-input>
          </el-form-item>

          <el-form-item label="医院级别" prop="hospital_level">
            <el-select
              v-model="form.hospital_level"
              placeholder="请选择医院级别"
            >
              <el-option
                v-for="(item, index) in yiyuanjibie"
                :key="index"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="科室" prop="department">
            <el-input
              v-model="form.department"
              placeholder="请输入科室"
            ></el-input>
          </el-form-item>

          <el-form-item label="职称" prop="job_title">
            <el-input
              v-model="form.job_title"
              placeholder="请输入职称"
            ></el-input>
          </el-form-item>

          <el-form-item label="证书类型" prop="cert_type">
            <el-select v-model="form.cert_type" placeholder="请选择文件类型">
              <el-option
                v-for="(item, index) in zhengshuleixing"
                :key="index"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item prop="cert_file" label="上传文件">
            <el-upload
              class="upload-demo"
              :action="action"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :before-remove="beforeRemove"
              :on-success="handleAvatarSuccess"
              multiple
              :limit="10"
              :on-exceed="handleExceed"
              :file-list="fileList"
              :data="uploadData"
              :before-upload="wenBeforeUpload"
              :on-progress="onProgressTest"
            >
              <el-button size="small" type="primary" v-prevent="1500"
                >点击上传</el-button
              >
            </el-upload>
          </el-form-item>

          <el-form-item
            style="text-align: center"
            v-if="renzheng.user_info.qualification != 2"
          >
            <el-button
              style="
                background: linear-gradient(127deg, #2fd1c2 0%, #4986fe 100%);
                border-color: #2fd1c2;
              "
              type="primary"
              @click="onSubmit('form')"
              >认证</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <!-- 内容 -->
    </div>
  </div>
</template>
<script>
// 引入七牛云
import * as qiniu from "qiniu-js";
// 引入七牛云
import { sevenCloud } from "@/api/createCenter";
import { getuser } from "../../api/user.js";
import { ajaxarea, jibieleixing, renzheng } from "@/api/login.js";
export default {
  name: "certification",
  data() {
    return {
      //------------------------------------------
      action: `https://up-z1.qiniup.com`, //http://up-z1.qiniup.com
      fileList: [],
      uploadData: { key: "", token: "" },
      aaa: "",
      //------------------------------------------
      isShowfile_path: false, //上文件进度条
      progressPercentfile_path: 0, //上文件进度条
      renzheng: {},
      form: {
        // _id: this.$route.query.id || "",
        name: "", //用户名
        phone: "", //手机号
        hospital: "", //医院名称
        job: "", //职业
        hospital_level: "", //医院级别
        department: "", //科室
        job_title: "", //职称
        cert_type: "", //证书
        cert_file: "", //文件
        province_id: "", //省
        city_id: "", //市
        area_id: "", //区
      },
      renzhenValue: [],
      categorProps: {
        value: "id",
        label: "name",
        children: "children",
      },
      //上传图片
      progressPercentCover_img: 0, //上图图片进度条
      isShowCover_img: false, //上图图片进度条
      classfiy: [],
      value: "",
      file_type: JSON.parse(localStorage.getItem("class")) || [],
      config: "", // 七牛云的配置项
      dialogVisible: false,
      rules: {
        name: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        hospital: [
          { required: true, message: "请输入医院名称", trigger: "blur" },
        ],
        job: [{ required: true, message: "请输入职业", trigger: "blur" }],
        hospital_level: [
          { required: true, message: "请选医院级别", trigger: "change" },
        ],
        department: [
          { required: true, message: "请输入科室", trigger: "blur" },
        ],
        job_title: [{ required: true, message: "请输入职称", trigger: "blur" }],
        cert_type: [
          { required: true, message: "请上传证书类型", trigger: "change" },
        ],
        cert_file: [
          { required: true, message: "请上传文件", trigger: "change" },
        ],
      },
      options: [],
      yiyuanjibie: [],
      zhengshuleixing: [],
    };
  },
  created() {
    this.getuserApi();
    this.shengshiqu();
    this.jiebie();
  },
  methods: {
    //------------------------------------------------------------------------------
    //点击每一个上传后的文件
    handlePreview(file) {
      console.log(file);
    },
    //移除后
    handleRemove(file, fileList) {
      this.aaa = "";
      let qiniuUrl = "";
      let apiUrl = "";

      fileList.map((item) => {
        if (item.response != undefined) {
          qiniuUrl += `https://doc.medsky.cn/${item.response.key}` + ",";
        }
        if (item.url) {
          apiUrl += item.url + ",";
        }
      });
      this.aaa = apiUrl + qiniuUrl;
      this.form.cert_file = this.aaa;
    },
    //移除文件 提示
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    //文件上传成功
    handleAvatarSuccess(res, file) {
      if (res.key != "" || res.key != undefined || res.key != null) {
        this.aaa += `https://doc.medsky.cn/${res.key}` + ",";
        this.form.cert_file += `https://doc.medsky.cn/${res.key}` + ",";
        // this.fileList.push(file)
        this.$message({
          message: "文件上传成功",
          type: "success",
        });
      } else {
        this.$message.error("上传失败");
      }
    },
    //超出长度提示
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 10 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    //上传前
    async wenBeforeUpload(file) {
      const is50M = file.size / 1024 / 1024 < 50;
      if (!is50M) {
        this.$message.error("上传图片大小不能超过 50MB!");
        return false;
      }
      // console.log(file);
      let suffix = file.name.substring(file.name.lastIndexOf(".")); //.txt
      //获取七牛云的token
      const res = await sevenCloud();
      this.uploadData.token = res.data.token;
      //file.uid +
      this.uploadData.key = file.uid + file.name;
      return true;
    },
    //文件上传时
    onProgressTest(event, file, fileList) {
      let index = 0;
      // 只有fileList 跟file 里面的uid是唯一的互相对应的
      fileList.forEach((v, i) => {
        if (v.uid === file.uid) {
          index = i;
        }
      });
      // 获取动态的进度
      let percent = parseInt(event.percent);
      this.$nextTick(() => {
        setTimeout(() => {
          if (percent >= 98) {
            // 当动态的进度超过99就获取dom 让值一直显示99直到上传成功
            let dom = document.getElementsByClassName("el-upload-list__item")[
              index
            ];
            let progressText = dom.getElementsByClassName("el-progress__text");
            if (progressText && progressText.length) {
              progressText[0].style.opacity = 0;
              progressText[0].innerText = "98%";
              progressText[0].style.opacity = 1;
            }
          }
        });
      });
    },
    //------------------------------------------------------------------------------
    //级别 类型
    async jiebie() {
      const res = await jibieleixing();
      this.yiyuanjibie = res.data.hospital_level;
      this.zhengshuleixing = res.data.cert_file_type;
    },
    //省市区
    async shengshiqu() {
      const res = await ajaxarea();
      this.options = res.data;
    },
    //获取认证信息
    async getuserApi() {
      // 注册成功将返回 qualification字段 : 认证状态;
      // 0=未提交资料
      // 1=已提交待认证
      // 2=认证通过
      // 3=认证驳回
      this.fileList = [];
      const res = await getuser();
      this.renzheng = res.data;
      console.log("测试", this.renzheng);
      if (
        res.data.user_info.qualification == 1 ||
        res.data.user_info.qualification == 2 ||
        res.data.user_info.qualification == 3
      ) {
        this.form = res.data.user_qualification;
        this.renzhenValue = res.data.user_qualification.ele_area;
        // this.form._id = this.$route.query.id || "";

        //文件//文件//文件//文件//文件//文件//文件//文件//文件//文件//文件//文件//文件//文件//文件//文件//文件//文件//文件
        let casd = res.data.user_qualification.cert_file.split(",");
        casd.map((i) => {
          this.fileList.push({ url: i, name: i });
        });
        //添加新的不会被替换
        let bbb = this.fileList.map((e) => {
          return e.url;
        });
        this.aaa += res.data.user_qualification.cert_file + ",";
        //文件//文件//文件//文件//文件//文件//文件//文件//文件//文件//文件//文件//文件//文件//文件//文件//文件//文件//文件//文件
      }
    },
    //认证
    onSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.form.cert_file = this.aaa.substring(0, this.aaa.length - 1);
          this.form.province_id = this.renzhenValue[0];
          this.form.city_id = this.renzhenValue[1];
          this.form.area_id = this.renzhenValue[2];

          console.log("提交提交提交", this.fileList);
          this.renzhengApi();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //认证接口
    async renzhengApi() {
      //this.form.ele_area
      delete this.form.ele_area;
      const res = await renzheng(this.form);
      if (res.code == 1) {
        this.shiming = false;
        this.$message.success(res.msg);
      } else {
        this.$message.error(res.msg);
      }
    },
    //上传文件
    uploadfile(el) {
      this.isShowfile_path = true;
      this.upload(el, "file_path");
    },
  },
};
</script>
<style lang="less" scoped>
.certification-box {
  width: 100%;
  margin-top: 60px;
  margin-left: 10px;

  .certification-head {
    display: flex;
    aling-items: center;
    padding-bottom: 18px;
    border-bottom: 1px solid #ececec;
    margin-bottom: 30px;
    .certification-head-zhuangtai {
      margin-right: 40px;
      font-size: 15px !important;
      .certification-head-zhuangtai-r {
        color: #19ab4a;
      }
      .certification-head-zhuangtai-y {
        color: #fb9622;
      }
    }
    .certification-head-zhuangtai-red {
      color: #ef5f5f;
    }
    .certification-head-bohui {
      font-size: 15px !important;
    }
  }
}

.avatar-uploader /deep/.el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 85px;
  height: 48px;
  line-height: 48px;
  text-align: center;
}
.avatar {
  width: 85px;
  height: 48px;
  display: block;
}
/deep/ .el-dialog__header {
  background: linear-gradient(180deg, #dbffef 0%, #ffffff 100%);
  color: #fff;
  overflow: hidden;
  border-radius: 16px 16px 0 0;
}

.el-form {
  width: 90%;
  /deep/ .el-form-item {
    // margin-bottom: 60px;
    margin-bottom: 40px;
    .el-input {
      width: 100%;
    }
    .el-select {
      width: 100%;
    }
  }
}
</style>
