/*
 * @Author: lsh lsh@163.com
 * @Date: 2022-06-29 10:00:09
 * @LastEditors: lsh lsh@163.com
 * @LastEditTime: 2022-07-01 15:48:15
 * @FilePath: /yixinyuan/src/mixin/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from '../utils/request'
// 全局用混入
import {
	getcounts
} from '../api/detail.js'
export const interval = {
	data() {
		return { 
			codetick: '获取验证码',
			inter: '',
			codeticks: '获取验证码',
			inters: ''
		}
	},
	methods: {
		setinterval(type) {
			if (this.ruleForm.phone) {
				console.log(typeof this.codetick)
				this.codetick = 60
				clearInterval(this.inter)
				this.inter = setInterval(() => {
					this.codetick -= 1
					if (this.codetick < 1) {
						clearInterval(this.inter)
						this.codetick = '获取验证码'
					}
				}, 1000)
				this.getcode(type)
			} else {
				this.$message.warning('请输入手机号')
			}
		},
		setintervals(type) {
			if (this.ruleForm.phone) {
				this.codeticks = 60
				clearInterval(this.inters)
				this.inters = setInterval(() => {
					this.codeticks -= 1
					if (this.codeticks < 1) {
						clearInterval(this.inters)
						this.codeticks = '获取验证码'
					}
				}, 1000)
				this.getcode(type)
			} else {
				this.$message.warning('请输入手机号')
			}
		}
	}
}

export const download = {
	methods: {
		//获取个人信息
		async getinfo() {
			const res = await axios.get('user/info')
			if (res.code === 1) {
				this.$store.commit("user/setUsers", res.data.user_info);
			}
		},
		// 下载文件 
		downloads(detail, url, filename) {
			this.getinfo()
			setTimeout(() => {
				this.xiazaiAPI(detail, url, filename)
			}, 700);
		},
		//下载文件api 
		xiazaiAPI(detail, url, filename) {
			axios.post('doc/detail', {
				_id: detail._id
			}).then((res) => {
				if (res.code == 1) {
					//this.$store.state.user.user && this.$store.state.user.user.token
					// 0=未提交资料
					// 1=已提交待认证
					// 2=认证通过
					// 3=认证驳回
					if (res.data.detail.download_per_flag.length != 0 && res.data.detail.download_per_flag.length == 1) {
						//验证 
						let download_yanzheng = res.data.detail.download_per_flag.indexOf('is_qualification')
						//登录
						let download_denglu = res.data.detail.download_per_flag.indexOf('is_login')
						//-1     
						//验证有没有认证   
						if (download_yanzheng != -1) {
							if (this.$store.state.user.user.qualification == 2) {
								console.log("下载")
								//下载  
								const that = this
								var request = new XMLHttpRequest()
								request.responseType = 'blob'
								const fileUrl = url // 文件路径 
								request.open('GET', fileUrl)
								request.onload = function() {
									var url = window.URL.createObjectURL(this.response)
									var a = document.createElement('a')
									document.body.appendChild(a)
									a.href = url
									a.download = filename
									a.click()
									document.body.removeChild(a)
									window.URL.revokeObjectURL(url)
								}
								request.send()
								request.onreadystatechange = function() {
									if (request.readyState === 4 && request.status === 200) {
										that.getcount()
									}
								}
								//下载
							} else {
								this.$message.error('请先认证才能下载文件')
							}
						} //结束

						//验证有没有登录
						if (download_denglu != -1) {
							if (this.$store.state.user.user.token) {
								console.log("下载")
								//下载
								const that = this
								var request = new XMLHttpRequest()
								request.responseType = 'blob'
								const fileUrl = url // 文件路径 
								request.open('GET', fileUrl)
								request.onload = function() {
									var url = window.URL.createObjectURL(this.response)
									var a = document.createElement('a')
									document.body.appendChild(a)
									a.href = url
									a.download = filename
									a.click()
									document.body.removeChild(a)
									window.URL.revokeObjectURL(url)
								}
								request.send()
								request.onreadystatechange = function() {
									if (request.readyState === 4 && request.status === 200) {
										that.getcount()
									}
								}
								//下载
							} else {
								this.$message.error('请先登录才能下载文件')
							}
						}
					} else if (res.data.detail.download_per_flag.length == 2) {
						console.log("认证", this.$store.state.user.user.qualification);
						console.log("认证", this.$store.state.user.user.token);
						if (!this.$store.state.user.user.token) {
							this.$message.error('请先登录才能下载文件')
						} else if (this.$store.state.user.user.qualification != 2) {
							this.$message.error('请先认证才能下载文件')
						} else {
							//下载   
							const that = this
							var request = new XMLHttpRequest()
							request.responseType = 'blob'
							const fileUrl = url // 文件路径  
							request.open('GET', fileUrl)
							request.onload = function() {
								var url = window.URL.createObjectURL(this.response)
								var a = document.createElement('a')
								document.body.appendChild(a)
								a.href = url
								a.download = filename
								a.click()
								document.body.removeChild(a)
								window.URL.revokeObjectURL(url)
							}
							request.send()
							request.onreadystatechange = function() {
								if (request.readyState === 4 && request.status === 200) {
									that.getcount()
								}
							}
						}

          } else {
            //没有任何验证的时候
						console.log("下载")
						//下载 
						const that = this
						var request = new XMLHttpRequest()
						request.responseType = 'blob'
						const fileUrl = url // 文件路径 
						request.open('GET', fileUrl)
						request.onload = function() {
							var url = window.URL.createObjectURL(this.response)
							var a = document.createElement('a')
							document.body.appendChild(a)
							a.href = url
							a.download = filename
							a.click()
							document.body.removeChild(a)
							window.URL.revokeObjectURL(url)
						}
						request.send()
						request.onreadystatechange = function() {
							if (request.readyState === 4 && request.status === 200) {
								that.getcount()
							}
						}
					}
				}
			})
		},

		// 获取次数
		async getcount() {
			const res = await getcounts(this.id)
			if (res.code === 1) {
				this.$message.success('下载成功')
				this.readonlyVisible = false
			} else {
				this.$message.error('下载失败')
			}
		},



		//新编辑的
		// downloads(detail, url, filename) {
		// 	axios.post('doc/detail', {
		// 		_id: detail._id
		// 	}).then((res) => {
		// 		if (res.code == 1) {
		// 			if (res.data.detail.download_per_flag.length != 0) {
		// 				// 验证  
		// 				//is_qualification  认证
		// 				//is_login
		// 				if (res.data.detail.download_per_flag[0] == 'is_qualification') {
		// 					console.log("验证认证");
		// 					this.$message.error('请先认证才能下载文件')

		// 				} else if (res.data.detail.download_per_flag[0] == 'is_login') {
		// 					console.log("验证登录");
		// 					this.$message.error('请先登录才能下载文件')
		// 				} else {
		// 					//下载   
		// 					console.log('下载');
		// 					const that = this
		// 					var request = new XMLHttpRequest()
		// 					request.responseType = 'blob'
		// 					const fileUrl = url // 文件路径 
		// 					request.open('GET', fileUrl)
		// 					request.onload = function() {
		// 						var url = window.URL.createObjectURL(this.response)
		// 						var a = document.createElement('a')
		// 						document.body.appendChild(a)
		// 						a.href = url
		// 						a.download = filename
		// 						a.click()
		// 						document.body.removeChild(a)
		// 						window.URL.revokeObjectURL(url)
		// 					}
		// 					request.send()
		// 					request.onreadystatechange = function() {
		// 						if (request.readyState === 4 && request.status === 200) {
		// 							that.getcount()
		// 						}
		// 					}
		// 				}
		// 				// 验证     
		// 			}
		// 		}
		// 	}) 
		// },
		//下载文件
		//     downloads(detail,url, filename) { 
		//   // console.log(detail,url, filename);  
		//   // console.log('这个是什么', this.$store.state.user.user)
		//   axios.post('doc/detail', { _id: detail._id }).then((res) => {  
		//     if (res.data.detail.download_per_flag) {
		//       // 验证
		//         if (res.data.detail.download_per_flag[0] == 'is_qualification') {
		//           console.log("验证认证");
		//                this.$message.error('请先认证才能下载文件')
		//       }else if (res.data.detail.download_per_flag[0] == 'is_login') {
		//           console.log("验证登录"); 
		//           this.$message.error('请先登录才能下载文件')
		//         }
		//         // 验证 
		//     } else {
		//     //下载  
		//       console.log('下载');

		//     const that = this 
		//     var request = new XMLHttpRequest()
		//     request.responseType = 'blob'
		//     const fileUrl = url // 文件路径 
		//     request.open('GET', fileUrl)
		//     request.onload = function () {
		//       var url = window.URL.createObjectURL(this.response)
		//       var a = document.createElement('a')
		//       document.body.appendChild(a)
		//       a.href = url
		//       a.download = filename
		//       a.click()
		//       document.body.removeChild(a)
		//       window.URL.revokeObjectURL(url)
		//     }
		//     request.send()
		//     request.onreadystatechange = function () {
		//       if (request.readyState === 4 && request.status === 200) {
		//         that.getcount()
		//       }
		//     }
		//   // if (this.$store.state.user.user && this.$store.state.user.user.token) {  
		//   //   const that = this 
		//   //   var request = new XMLHttpRequest()
		//   //   request.responseType = 'blob'
		//   //   const fileUrl = url // 文件路径
		//   //   request.open('GET', fileUrl)
		//   //   request.onload = function () {
		//   //     var url = window.URL.createObjectURL(this.response)
		//   //     var a = document.createElement('a')
		//   //     document.body.appendChild(a)
		//   //     a.href = url
		//   //     a.download = filename
		//   //     a.click()
		//   //     document.body.removeChild(a)
		//   //     window.URL.revokeObjectURL(url)
		//   //   }
		//   //   request.send()
		//   //   request.onreadystatechange = function () {
		//   //     if (request.readyState === 4 && request.status === 200) {
		//   //       that.getcount()
		//   //     }
		//   //   }
		//   // } else {
		//   //   this.$message.error('请先登录才能下载文件')
		//   // }
		//    }
		//   })            
		// },
	}
}