<template>
  <div class="contain">
    <!-- 精选期刊 -->
    <ul class="qikan">
      <div class="v8 qikan-ul">
        <li
          class="qikan-li"
          v-for="(item, index) in shopList"
          :key="index"
          @click="todetail(item._id)"
        >
          <div class="qikan-li-img">
            <img :src="item.cover_img" />
          </div>
          <div class="qikan-li-right">
            <div class="qikan-li-right-text">
              {{ item.title }}
            </div>
            <div class="qikan-li-right-bottom">
              <div class="qikan-li-right-bottom-name">
                作者：{{ item.file_author_name }}
              </div>
              <div class="qikan-li-right-bottom-num">
                <img src="@/assets/image/icon/yan.png" />
                <span>{{ item.view_num }}</span>
              </div>
            </div>
          </div>
        </li>
      </div>
    </ul>
    <!-- 精选期刊 -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      classf: {},
      class2: JSON.parse(localStorage.getItem("class2")) || {},
    };
  },
  created() {
    var classlist = this.$store.state.classfiy;
    classlist.forEach((element) => {
      this.classf[element.file_type] = element.clr;
    });
    console.log("打印一下", this.shopList);
  },
  methods: {
    todetail(id) {
      this.$router.push("/detail?id=" + id);
    },
  },
  props: {
    shopList: {
      default: () => [],
      type: Array,
    },
    show: {
      default: false,
      type: Boolean,
    },
  },
};
</script>

<style lang="less" scoped>
.qikan {
  width: 100%;
  /* 		background: #f4f8f8;
		overflow: hidden;
		margin-bottom: 36px;
		padding: 35px 0; */
  .qikan-ul {
    display: flex;
    flex-wrap: wrap;
  }

  .qikan-li {
    display: flex;
    padding: 18px;
    background-color: #fff;
    margin-bottom: 20px;
    cursor: pointer;
    border-radius: 9px;
    transition: 0.3s;

    // &:nth-child(2) {
    // 	margin: 0 37px 20px;
    // }

    // &:nth-child(5) {
    // 	margin: 0 37px 20px;
    // }

    &:nth-child(3n + 2) {
      margin: 0 37px 20px;
    }

    &:hover {
      transform: scale(102%);
      box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.16);

      .qikan-li-right-text {
        color: red;
      }
    }

    .qikan-li-img {
      width: 123px;
      height: 169px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .qikan-li-right {
      margin-left: 20px;
      width: 309px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .qikan-li-right-text {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 7;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .qikan-li-right-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        color: #1c1c1c;

        .qikan-li-right-bottom-name {
          font-size: 14px;
          color: #999999;
        }

        .qikan-li-right-bottom-num {
          color: #848484;
          display: flex;
          align-items: center;

          img {
            width: 18px;
            height: 18px;
            margin-right: 6px;
          }
        }
      }
    }
  }

  .qikan-btn {
    text-align: center;
    margin-top: 10px;
    .el-button {
      // background-image: linear-gradient(to right, #0ad28f, #00b277);
      background: linear-gradient(130deg, #2fd2c1 0%, #4986fe 100%);
      color: #fff;
      border: 1px solid #2fd2c1;
      border-radius: 21px;
    }
  }
}
</style>
