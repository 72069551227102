<template>
  <div class="contain v8">
    <div class="title">
      <img src="../../../assets/image/jingxuan.png" alt="" />
      资源精选
    </div>
    <div
      @mouseleave="
        listShowlie = false;
        zizijiShow = false;
      "
    >
      <ul class="liebiao">
        <li
          class="quanbu"
          @mouseenter="listShowlie = false"
          @mouseleave="listShowlie = false"
          @click="quanbuClick('')"
        >
          <div class="quanbu-img">
            <img src="../../../assets/image/all.png" alt="" />
          </div>
          <div class="quanbu-text">全部</div>
        </li>
        <li
          class="liebiao-list"
          v-for="(item, index) in list"
          :key="index"
          @mouseenter="jingguo(item, index)"
          @click="listClick(item, index)"
        >
          <div class="liebiao-list-img">
            <img v-if="showIindex == index" :src="hear + item.image" />
            <img v-if="showIindex != index" :src="hear + item.un_active" />
          </div>
          <div class="liebiao-list-text-name">{{ item.name }}</div>
        </li>
      </ul>
      <!-- 子级 -->
      <ul class="ziji-ul" v-if="listShowlie" @mouseleave="zijilikai">
        <li
          class="ziji-ul-li"
          v-for="(item, index) in children"
          :key="index"
          @click="ziListClick(item)"
          @mouseenter="zijijingguo(item, index)"
        >
          {{ item.name }}
        </li>
      </ul>
      <!-- 子子级 -->
      <ul
        class="ziziji-ul"
        v-if="zizijiShow"
        @mouseleave="
          zizijiShow = false;
          zzjChildren = [];
        "
      >
        <li
          class="ziziji-ul-li"
          v-for="(item, index) in zzjChildren"
          :key="index"
          @click="zizijiListClick(item)"
        >
          {{ item.name }}
        </li>
      </ul>
      <!-- 子子级 -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      iconshow: false, // 是否显示图标
      show: false, // 是否下拉
      indexs: 0,
      hear: "https://yxy.netloop.cn",
      //------------
      children: [],
      zzjChildren: [],
      listShowlie: false,
      showIindex: -1,
      zizijiShow: false,
      //-------------
    };
  },
  props: {
    list: {
      // default: [],
      type: Array,
    },
  },
  watch: {
    list() {
      this.$nextTick(() => {
        var listWidth = 0;
        for (var i = 0; i < this.$refs.lists.length; i++) {
          listWidth += this.$refs.lists[i].offsetWidth;
        }
        // 所有li标签左边距
        var leftmargin = (this.$refs.lists.length + 1) * 40;
        // 父亲元素容纳标签的宽度
        var fathorWidth = this.$refs.list_box.offsetWidth - 60;
        // 全部标签的宽度
        var listall = this.$refs.listall.offsetWidth;
        if (fathorWidth < listall + listWidth + leftmargin) {
          this.iconshow = true;
        } else {
          this.iconshow = false;
        }
      });
    },
  },
  methods: {
    activeindex(index, id) {
      this.indexs = index;
      this.$emit("provideId", id);
    },
    //---- --------
    //全部点击
    quanbuClick(id) {
      this.showIindex = -1;
      this.$emit("provideId", id);
    },
    //父级点击
    listClick(item, index) {
      this.showIindex = index;
      this.$emit("provideId", item.id);
    },
    //子级点击
    ziListClick(item) {
      this.$emit("provideId", item.id);
      this.listShowlie = false;
      this.zizijiShow = false;
    },
    //子子级点击
    zizijiListClick(item) {
      this.$emit("provideId", item.id);
      this.listShowlie = false;
      this.zizijiShow = false;
    },
    //父经过
    jingguo(item, index) {
      if (item.children != undefined) {
        this.listShowlie = true;
        this.children = item.children;
      }
      if (item.children == undefined) {
        this.listShowlie = false;
      }
    },
    //子级离开
    zijilikai() {
      if (this.zzjChildren.length != 0) {
        this.listShowlie = true;
      } else {
        this.listShowlie = false;
      }
    },
    //子子级经过
    zijijingguo(item, index) {
      if (item.children != undefined) {
        this.zizijiShow = true;
        this.zzjChildren = item.children;
      }
      if (item.children == undefined) {
        this.zizijiShow = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.contain {
  margin-top: 10px;
  .title {
    font-size: 24px;
    img {
      width: 18px;
      vertical-align: middle;
      margin-right: 12px;
    }
  }
  .classfiy {
    padding-bottom: 17px;
    width: 100%;
    border-radius: 6px;
    background: #f5faff;
    margin-top: 12px;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    position: relative;
    padding-right: 60px;
    li {
      margin-left: 40px;
      height: 35px;
      margin-top: 18px;
      font-size: 16px;
      color: #333333;
      line-height: 35px;
      padding: 0 6px;
      border-radius: 6px;
      cursor: pointer;
      img {
        width: 23px;
        vertical-align: middle;
      }
    }
    .active {
      background-image: linear-gradient(to right, #3fd7e8, #1eb8c9);
      color: #fff;
    }
    .zhankai {
      position: absolute;
      right: 10px;
      bottom: 17px;
    }
  }
}
.active {
  padding: 20px;
  padding: 8px 10px;
  margin-right: 38px;
  background-color: #f5ffff;
  cursor: pointer;
  transition: 0.2s;
  font-size: 15px;
  color: #333333;
  border-radius: 6px;
  background-color: #6fb7f6;
}
.liebiao {
  margin-top: 12px;
  display: flex;
  align-items: center;
  background-color: #f5ffff;
  border-radius: 6px;
  padding: 18px;
  .quanbu {
    display: flex;
    align-items: center;
    cursor: pointer;
    .quanbu-img {
      width: 22px;
      height: 24px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .quanbu-text {
      font-size: 15px;
      color: #333333;
      margin-left: 7px;
      margin-right: 38px;
    }
    &:hover {
      background-color: #f5ffff;
      color: #fff;
      transform: scale(102%);
    }
  }
  .liebiao-list {
    display: flex;
    align-items: center;
    padding: 20px;
    padding: 8px 10px;
    margin-right: 38px;
    background-color: #f5ffff;
    cursor: pointer;
    transition: 0.2s;
    font-size: 15px;
    color: #333333;
    border-radius: 6px;
    &:hover {
      background-color: #6fb7f6;
      color: #fff !important;
      transform: scale(105%);
      box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.16);
      .liebiao-list-text-name {
        color: #fff;
      }
    }

    .liebiao-list-img {
      margin-right: 7px;
      width: 20px;
      height: 20px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.ziji-ul {
  position: relative;
  top: -10px;
  left: 0;
  display: flex;
  padding: 19px 22px 23px 22px;
  background-color: #ffffff;
  box-shadow: 0px 0px 6px 1px #d0f1f1;
  .ziji-ul-li {
    cursor: pointer;
    transition: 0.2s;
    font-size: 14px;
    color: #333333;
    margin-right: 34px;
    padding: 4px 10px;
    border-radius: 3px;
    transition: 0.2s;
    &:hover {
      background-color: #4285c2;
      color: #fff;
    }
  }
}
.ziziji-ul {
  position: relative;
  top: -10px;
  left: 0;
  padding: 19px 22px 23px 22px;
  background-color: #f5f5f5;
  box-shadow: 0px 0px 6px 1px #d0f1f1;
  display: flex;
  .ziziji-ul-li {
    cursor: pointer;
    transition: 0.2s;
    font-size: 14px;
    color: #333333;
    margin-right: 34px;
    padding: 4px 10px;
    border-radius: 3px;
    transition: 0.2s;
    &:hover {
      color: #1356f1;
    }
  }
}
</style>
