<template>
  <div class="contain">
    <h4 class="small_title v8" v-if="shopList.length != 0">
      <slot></slot>
    </h4>
    <div class="contain-box" v-if="shopList.length != 0">
      <goods :shopList="shopList" :show="false"></goods>
      <buttons @tothor="toyxy">查看更多资源</buttons>
    </div>
  </div>
</template>

<script>
import goods from "../../../components/goods.vue";
export default {
  props: {
    shopList: {
      default: () => [],
      type: Array,
    },
    type: {
      default: "",
      type: String,
    },
    cateid: {
      default: "",
    },
  },
  components: {
    goods,
  },
  created() {
    console.log("我就看看有没有数据", this.shopList);
  },
  methods: {
    toyxy() {
      // console.log('aaaa')
      this.$router.push({
        name: "yxy",
        params: {
          type: this.type,
          cataid: this.cateid,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.small_title {
  color: #595959;
  font-size: 20px;
  margin-top: 70px;
  margin-bottom: 12px;

  &::before {
    content: "";
    display: inline-block;
    width: 9px;
    height: 9px;
    background: #cf4549;
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 10px;
    margin-bottom: 3px;
  }
}
.contain-box {
  background: #f4f8f8;
  padding: 35px 0;
  overflow: hidden;
  .el-button {
    background: linear-gradient(302deg, #4986fe 0%, #2fd2c1 100%) !important;
    color: #fff;
    border: 1px solid #2fd2c1;
    border-radius: 21px;
  }
}
</style>
