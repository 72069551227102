<template>
  <div class="copyright">
    <div class="header">
      <h1>图书馆</h1>
      <p class="contain">为不同需求而定制</p>
      <p class="contain line">建设全方位，卓越品质零距离</p>
    </div>
    <div class="main">
      <ul class="left">
        <li
          @click="changeclass(index)"
          :class="active === index ? 'active' : ''"
          v-for="(item, index) in list"
          :key="item.name"
        >
          <i :class="item.icon"></i>
          {{ item.name }}
        </li>
      </ul>
      <div class="right">
        <h4><i :class="list[active].icon"></i>{{ list[active].name }}</h4>
        <div class="text" v-html="bqsm.data" v-if="active === 0"></div>
        <div class="text" v-if="active === 1">
          <ul class="message">
            <li><i class="el-icon-office-building"></i>{{ lxwm.web_name }}</li>
            <li><i class="el-icon-phone-outline"></i>{{ lxwm.mobile }}</li>
            <li><i class="el-icon-mobile-phone"></i>{{ lxwm.phone }}</li>
            <li><i class="el-icon-message"></i>{{ lxwm.email }}</li>
            <li>
              <i class="el-icon-location-information"></i>{{ lxwm.address_bj }}
            </li>
            <li>
              <i class="el-icon-location-information"></i>{{ lxwm.address_sjz }}
            </li>
          </ul>
        </div>
        <div class="text" v-if="active === 2" v-html="wztg.data"></div>
        <!-- 商务合作 -->
        <div class="text" v-if="active === 3">
          <h5>渠道合作</h5>
          <p class="nerong">{{ swhz.hezuo_qd }}</p>
          <h5>代理合作</h5>
          <p class="nerong">{{ swhz.hezuo_dl }}</p>
          <h5>品牌（市场）合作</h5>
          <p class="nerong">{{ swhz.hezuo_sc }}</p>
          <h5>网站合作</h5>
          <p class="nerong">{{ swhz.hezuo_web }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getmessage } from "../../api/contect.js";
export default {
  data() {
    return {
      list: [
        { name: "版权声明", icon: "el-icon-warning-outline" },
        { name: "联系我们", icon: "el-icon-phone-outline" },
        { name: "网站投稿", icon: "el-icon-folder-opened" },
        { name: "商务合作", icon: "el-icon-bangzhu" },
      ],
      active: 0,
      swhz: "",
      lxwm: "",
      bqsm: "",
      wztg: "",
    };
  },
  created() {
    this.getmessage();
  },
  watch: {
    $route(to, from) {
      this.active = Number(this.$route.query.id);
    },
  },
  methods: {
    changeclass(index) {
      this.active = index;
    },
    // 获取用户信息
    async getmessage() {
      const res = await getmessage();
      if (res.code === 1) {
        this.swhz = res.data.bus_work;
        this.lxwm = res.data.concat_us;
        this.bqsm = res.data.sys_copyright;
        this.wztg = res.data.web_post;
        // this.data = res
      }
    },
  },
};
</script>

<style lang="less" scoped>
.copyright {
  width: 100%;
  background: #f5f7f8;
  .header {
    height: 566px;
    background: url("../../assets/image/gb.png") no-repeat top center;
    background-size: auto 100%;
    width: 100%;
    overflow: hidden;
    text-align: center;
    h1 {
      font-size: 84px;
      color: #ffffff;
      margin-top: 102px;
      text-shadow: 0px 3px 6px #6c7dc1;
      letter-spacing: 30px;
    }
    .contain {
      color: #ffffff;
      font-size: 18px;
      margin-top: 37px;
    }
    .line {
      border-top: 1px solid #ffffff;
      border-bottom: 1px solid #ffffff;
      width: 234px;
      margin: 0 auto;
      line-height: 37px;
      margin-top: 21px;
    }
  }
  .main {
    width: 80%;
    margin: 0 auto;
    display: flex;
    margin-top: -120px;
    align-items: flex-start;
    .left {
      width: 250px;
      border-radius: 8px;
      overflow: hidden;
      li {
        height: 69px;
        line-height: 69px;
        background: #fff;
        color: #333333;
        cursor: pointer;
        i {
          margin-left: 25px;
          width: 16px;
          margin-top: 4px;
        }
      }
      .active {
        background: #32d8a0;
        color: #fff;
        i {
          color: #fff;
        }
      }
    }
    .right {
      flex: 1;
      margin-left: 40px;
      background: #fff;
      border-radius: 11px;
      margin-bottom: 60px;
      padding: 54px;
      h4 {
        line-height: 60px;
        border-bottom: 1px solid #e1e1e1;
        margin-bottom: 44px;
        i {
          color: #ff5517;
          margin-right: 6px;
        }
      }
      .message {
        font-size: 14px;
        color: #808080;
        line-height: 45px;
        margin-top: -8px;
        i {
          margin-right: 6px;
          font-size: 14px;
        }
      }
      h5 {
        color: #030303;
        font-size: 14px;
        &::before {
          content: "";
          display: inline-block;
          width: 4px;
          height: 11px;
          background: #32d8a0;
          vertical-align: middle;
          margin-right: 6px;
          border-radius: 2px;
        }
      }
      .nerong {
        margin-bottom: 83px;
        font-size: 14px;
        color: #808080;
        margin-top: 19px;
      }
    }
  }
}
</style>
