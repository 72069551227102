<template>
  <div class="user">
    <el-card class="v8" :body-style="{ padding: 0 }">
      <el-container>
        <el-aside width="200px">
          <div class="header">
            <img :src="$store.state.user.user.avatar" alt="" />
          </div>
          <p class="name">{{ $store.state.user.user.nickname }}</p>
          <!-- 个人中心导航 -->
          <!-- background-color="#F6F7F9" -->
          <el-menu
            :default-active="path"
            class="el-menu-vertical-demo"
            text-color="#66686B"
            active-text-color="#4986FE"
            @select="changehistory"
          >
            <!-- " -->
            <el-menu-item
              :index="item.path"
              v-for="item in munus"
              :key="item.name"
            >
              <i class="iconfont" style="margin-right: 4px">{{ item.icon }}</i>
              <span slot="title">{{ item.name }}</span>
            </el-menu-item>
          </el-menu>
        </el-aside>
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-card>
    <div class="sum" v-if="$route.path === '/user/createrCenter'">
      共 <span>{{ $store.state.sum }}</span> 个文件
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      path: "/user",
      munus: [
        { name: "个人信息", path: "/user", icon: "\ue613" },
        { name: "认证信息", path: "/user/certification", icon: "\ue63e" },
        { name: "下载历史", path: "/user/history", icon: "\ue754" },
        { name: "我的资源单", path: "/user/resource", icon: "\ue609" },
        { name: "投稿中心", path: "/user/createrCenter", icon: "\ue73c" },
        { name: "积分管理", path: "/user/integral", icon: "\ue608" },
      ],
    };
  },
  created() {
    console.log("aaa");
  },
  methods: {
    changehistory(el) {
      this.$router.push(el);
    },
  },
  watch: {
    $route: {
      handler(to, from) {
        this.path = this.$route.path;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="less" scoped>
.user {
  margin-top: 50px;
  .v8 {
    //
  }
  .el-aside {
    background-color: #f6f7f9;
    color: #333;
    min-height: 700px;
    .header {
      margin: 0 auto;
      margin-top: 48px;
      width: 58px;
      height: 58px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
    .name {
      color: #66686b;
      margin-top: 13px;
      text-align: center;
    }
    .el-menu {
      margin-top: 40px;
      text-align: center;
      .el-menu-item {
        padding-left: 20px;
        border-right: 0 none;
        background-color: #f6f7f9;
      }
      .is-active {
        background: #fff;
      }
    }
  }
  .sum {
    text-align: center;
    font-size: 15px;
    margin-top: 50px;
    span {
      color: #4789fa;
    }
  }
}
</style>
