
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 引入basecss样式
import './assets/css/base.less'
// 引入字体图标
import './assets/fonts/iconfont.css'
// 引入蚂蚁金服的组件
import ElementUI from 'element-ui'
// 定制主题
import 'element-ui/lib/theme-chalk/index.css'
import './assets/theme/index.css'
/* 引入swiper */
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'

// 全局注册搜索组件
import componentConfig from '@/utils/componentConfig'
import 'wowjs/css/libs/animate.css'
import wow from 'wowjs'
Vue.prototype.$wow = wow
Vue.use(wow)

Vue.use(componentConfig)
Vue.use(VueAwesomeSwiper /* { default options with global component } */)
Vue.use(ElementUI)

// 引入轮播图
Vue.config.productionTip = false



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
