/*
 * @Author: lsh lsh@163.com
 * @Date: 2022-04-12 13:28:36
 * @LastEditors: lsh lsh@163.com
 * @LastEditTime: 2022-06-24 10:30:40
 * @FilePath: /yixinyuan/src/store/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import Vuex from 'vuex'
import user from './user/index.js'
import { doctype } from '../api/yxy'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    classfiy: JSON.parse(localStorage.getItem('class')) || [],
    sum: 0
  },
  mutations: {
    setstate (state, nums) {
      state.classfiy = nums
    },
    getsum (state, nums) {
      state.sum = nums
    }
  },
  actions: {
    // 获取数据分类
    async getclassfiy ({ commit }) {
      const res = await doctype()
      if (res.code === 1) {
        commit('setstate', res.data)
        const obj = {}
        res.data.forEach((element) => {
          obj[element.file_type] = element.name
        })
        localStorage.setItem('class2', JSON.stringify(obj))
        localStorage.setItem('class', JSON.stringify(res.data))
        console.log('vuex里边的分类', res.data)
      }
    }
  },
  modules: {
    user
  },
  getters: {

  }
})
