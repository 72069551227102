import Vue from 'vue'
import VueRouter from 'vue-router'
import Lable from '../views/Lable.vue'
// import Home from '../views/Home/index.vue'
import Home from '../views/Home/home.vue'
import yxy from '../views/yxy/index.vue' 
import detail from '../views/detail/index.vue'
import user from '../views/user/index.vue'
import usercenter from '../views/usercenter/index.vue'
import history from '../views/history/index.vue'
import resource from '../views/resource/index.vue'
import list from '../views/resource/list.vue'
import searchs from '../views/searchs/index.vue'
import resolve from '../views/resolve/index.vue'
import copyright from '../views/copyright/index.vue'
import createrCenter from '../views/creatorCenter/index.vue'
import centerEditor from '../views/centerEditor/index.vue'
import integral from '../views/integral/index.vue'

//个人中心
import certification from '../views/certification/index.vue'   
import contribute from '../views/contribute/index.vue'  
import contactme from '../views/contactme/index.vue'   


//cehis 
import librarys from '../views/librarys/index.vue'     
Vue.use(VueRouter) 
const originalPush = VueRouter.prototype.push  
VueRouter.prototype.push = function push (location) { 
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/',
    component: Lable,
    children: [
      {
        path: '/',
        name: 'Home',
        component: Home
      },
      {
        path: '/yxy',
        name: 'yxy',
        component: yxy
      },
      { 
        path: '/contribute',
        name: 'contribute',
        component: contribute
      },
      {
        path: '/contactme',
        name: 'contactme',
        component: contactme
      }, 
      //index
      {
        path: '/detail',
        name: 'detail',
        component: detail
      },
      {
        path: '/copyright',
        name: 'copyright',
        component: copyright
      },
      //测试
      { path: '/librarys/:id', component: librarys },
      //测试  
      {
        path: '/user',
        component: user,
        children: [
          {
            path: '/',
            name: 'usercenter',
            component: usercenter
          },
          {
            path: 'history',
            name: 'history',
            component: history
          },
          {
            path: 'resource',
            name: 'resource',
            component: resource
          },
          {
            path: 'list',
            name: 'list', 
            component: list
          },
          {
            path: 'createrCenter', 
            name: 'createrCenter',
            component: createrCenter
          },
          {
            path: 'centerEditor',
            name: 'centerEditor',
            component: centerEditor
          },
          {
            path: 'integral',
            name: 'integral',
            component: integral
          },
          ///----------
          {
            path: 'certification',
            name: 'certification', 
            component: certification
          },  
        ]   
      },  
      { 
        path: '/searchs',
        name: 'searchs',
        component: searchs
      },
      {
        path: '/resolve',
        name: 'resolve',
        component: resolve
      },

    ]
  }
]

const router = new VueRouter({
  routes
}) 
router.beforeEach((to, from, next) => {
  // 让页面回到顶部
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
  // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
  next() 
}) 

export default router
