<template>
  <div class="home">
    <!-- 这里边引入一个轮播图 -->
    <swiper></swiper>
    <!-- 引入搜索页面 -->
    <search :distance="0"></search>
    <!-- 统计数量总数 -->
    <ul class="v8 count">
      <li>
        <div class="icon">
          <i class="iconfont" style="font-size: 38px">&#xe628;</i>
        </div>
        <div class="constName">
          <p>资源数量种类</p>
          <count-to
            v-if="btn_data.total_med_type_num"
            :startVal="startVal"
            :endVal="btn_data.total_med_type_num"
            :duration="1000"
          ></count-to>
        </div>
      </li>
      <li>
        <div class="icon" style="padding: 0; line-height: 55px">
          <i class="iconfont" style="font-size: 48px">&#xe64e;</i>
        </div>
        <div class="constName">
          <p>医信源资源总量</p>
          <count-to
            v-if="btn_data.total_source_num"
            :startVal="startVal"
            :endVal="btn_data.total_source_num"
            :duration="1000"
          ></count-to>
        </div>
      </li>
      <li>
        <div class="icon">
          <i class="iconfont" style="font-size: 20px">&#xe64d;</i>
        </div>
        <div class="constName">
          <p>资源总浏览量</p>
          <count-to
            v-if="btn_data.total_view_num"
            :startVal="startVal"
            :endVal="btn_data.total_view_num"
            :duration="1000"
          ></count-to>
        </div>
      </li>
      <li>
        <div class="icon">
          <i class="iconfont" style="font-size: 30px">&#xe600;</i>
        </div>
        <div class="constName">
          <p>资源总下载量</p>
          <count-to
            v-if="btn_data.total_download_num"
            :startVal="startVal"
            :endVal="btn_data.total_download_num"
            :duration="1000"
          ></count-to>
        </div>
      </li>
      <li>
        <div class="icon">
          <i class="iconfont" style="font-size: 28px">&#xe61c;</i>
        </div>
        <div class="constName">
          <p>本月新增资源数量</p>
          <count-to
            v-if="btn_data.curr_month_data_num"
            :startVal="startVal"
            :endVal="btn_data.curr_month_data_num"
            :duration="1000"
          ></count-to>
        </div>
      </li>
    </ul>
    <!-- 统计数量总数 -->
    <!-- 图书馆 -->
    <div class="v8 tushu-img"><img src="@/assets/image/tushuguan.png" /></div>
    <ul class="v8 tushu">
      <li
        class="tushu-li"
        v-for="(item, index) in tushulist"
        :key="index"
        @click="tushuguanceshi(item)"
      >
        <div class="tushu-li-img">
          <img :src="item.cover" />
        </div>
      </li>
    </ul>
    <!-- 图书馆 -->
    <!-- 资源精选 -->
    <jingxuan :list="category_list" @provideId="provides" />
    <!-- 精选文献-->
    <shops
      :cateid="cateids"
      :type="item.type ? item.type : item.file_type"
      :shopList="item.rec_list"
      v-for="item in data_list"
      :key="item.type"
    >
      <!--  :shopList="item.rec_list" -->
      {{ item.text }}
    </shops>

    <!-- 精选期刊 -->
    <!-- 	<div class="v8 qikan-head">
			<div class="qikan-head-dian"></div>
			<div class="qikan-head-text">精选期刊</div>
		</div>
		<ul class="qikan">
			<div class="v8 qikan-ul">
				<li class="qikan-li" v-for="(item,index) in 5" :key='index'>
					<div class="qikan-li-img">
						<img src="@/assets/image/蒙版组 124@2x.png" />
					</div>
					<div class="qikan-li-right">
						<div class="qikan-li-right-text">
							AI助力医学统计与R语言入门训练营（AI+ R语言+
							统计+顶刊图表复现+Meta分析）召集令！
						</div>
						<div class="qikan-li-right-bottom">
							<div class="qikan-li-right-bottom-name">作者：王鑫慧</div>
							<div class="qikan-li-right-bottom-num">
								<img src="@/assets/image/icon/yan.png">
								<span>8569</span>
							</div>
						</div>
					</div>
				</li>
			</div>
			<div class="v8 qikan-btn">
				<el-button style="width: 240px" plain>查看更多资源</el-button>
			</div>
		</ul> -->
    <!-- 精选期刊 -->

    <!-- 精选学术 -->
    <!-- <div class="v8 qikan-head">
			<div class="qikan-head-dian"></div>
			<div class="qikan-head-text">精选学术</div>
		</div>
		<ul class="qikan">
			<div class="v8 qikan-ul">
				<li class="qikan-li" v-for="(item,index) in 5" :key='index'>
					<div class="qikan-li-img">
						<img src="@/assets/image/蒙版组 124@2x.png" />
					</div>
					<div class="qikan-li-right">
						<div class="qikan-li-right-text">
							AI助力医学统计与R语言入门训练营（AI+ R语言+
							统计+顶刊图表复现+Meta分析）召集令！
						</div>
						<div class="qikan-li-right-bottom">
							<div class="qikan-li-right-bottom-name">作者：王鑫慧</div>
							<div class="qikan-li-right-bottom-num">
								<img src="@/assets/image/icon/yan.png">
								<span>8569</span>
							</div>
						</div>
					</div>
				</li>
			</div>
			<div class="v8 qikan-btn">
				<el-button style="width: 240px" plain>查看更多资源</el-button>
			</div>
		</ul> -->
    <!-- 精选学术 -->

    <!-- 友情链接 -->
    <div class="boxtitle v8" style="margin-top: 80px">
      <i class="iconfont" style="color: #4b76ec">&#xe60b;</i>
      友情链接
    </div>
    <div class="links">
      <ul class="v8 linkbox">
        <li
          v-for="item in f_link"
          :style="{ cursor: item.code_img || item.jump_url ? 'pointer' : '' }"
          :key="item.id"
          @click="toothorwebsite(item.jump_url)"
        >
          <el-popover
            placement="top"
            width="200"
            trigger="hover"
            v-if="item.code_img"
          >
            <!-- 二维码 -->
            <img :src="item.code_img" style="width: 100%" alt="" />
            <div class="linkcenter" slot="reference">
              <img :src="item.banner_img" alt="" />
            </div>
          </el-popover>
          <div class="linkcenter" slot="reference" v-else>
            <img :src="item.banner_img" alt="" />
          </div>
        </li>
      </ul>
    </div>
    <!-- 友情链接 -->
  </div>
</template>

<script>
import CountTo from "vue-count-to";
import swiper from "./components/swiper.vue";
import jingxuan from "./components/jingxuan.vue";
import shops from "./components/shops.vue";
import { getindex, centerList, tushuguan } from "../../api/index";
export default {
  components: {
    CountTo,
    swiper,
    jingxuan,
    shops,
  },
  data() {
    return {
      startVal: 0,
      btn_data: [],
      category_list: [],
      data_list: [],
      f_link: [],
      cateids: "",
      tushulist: [],
    };
  },
  created() {
    this.getindexdata();
    this.$store.dispatch("getclassfiy");

    //------
    this.tushuguanApi(); //图书馆
  },
  methods: {
    // 去其他的官网
    toothorwebsite(url) {
      if (url) {
        window.open(url);
      }
    },
    // 获取首页数据的接口
    async getindexdata() {
      const res = await getindex();
      if (res.code === 1) {
        this.btn_data = res.data.btn_data;
        this.category_list = res.data.category_list;
        this.data_list = res.data.data_list;
        this.f_link = res.data.f_link;

        console.log("测试测试", res);
      }
    },
    async provides(id) {
      this.cateids = id;
      console.log("有没有数据a", this.cateids);
      const res = await centerList(1, id);
      console.log(res);
      if (res.code === 1) {
        this.data_list = res.data;
        // this.data_list = res.data.datalist;
      } else {
        this.data_list = [];
      }
      console.log("有数据吗", this.data_list);
    },
    //-----------------------------------------------------
    //图书馆
    async tushuguanApi() {
      const res = await tushuguan({ type: "collectionType", pid: "0" });
      console.log("图书馆", res);
      this.tushulist = res.data;
    },
    //图书馆点击
    tushuguanceshi(item) {
      let url = item.href.slice(27);
      this.$router.push({
        path: `/librarys/${url}`,
        query: {
          pid: item.value,
          name: item.name,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import url("../../assets/css/title.less");

.home {
  .search {
    position: relative;

    /deep/ .search_center {
      position: absolute;
      top: -40px;
      z-index: 100;
    }
  }

  .count {
    height: 153px;
    // background: #f5faff;
    background-color: #f9f9f9;
    // margin-top: 36px;
    margin-top: 56px;
    display: flex;
    padding: 37px 0;

    li {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #cde1f5;

      &:last-child {
        border-right: 0 none;
      }

      .icon {
        width: 55px;
        height: 55px;
        border-radius: 8px;
        background: #e9e9e9;
        padding: 8px;
        text-align: center;
        line-height: 39px;
      }

      .constName {
        margin-left: 11px;

        p {
          font-size: 16px;
          color: #8b8b8b;
        }

        span {
          font-size: 33px;
          color: #323232;
        }
      }
    }
  }

  .zhezhaos {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      display: inline-block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      color: rgba(0, 0, 0, 0.9);
      background: #fff;

      i {
        font-size: 48px;
        display: inline-block;
        margin-top: -6px;
        margin-left: -4px;
      }
    }
  }

  .links {
    width: 100%;
    overflow: hidden;
    background: #f5faff;
    padding-bottom: 48px;
    margin-top: 35px;

    .linkbox {
      li {
        width: 13%;
        float: left;
        background: #fff;
        margin-right: 4.4%;
        margin-top: 34px;
        border-radius: 8px;

        .linkcenter {
          width: 100%;
          padding-bottom: 36%;
          position: relative;
          overflow: hidden;

          img {
            position: absolute;
            top: 50%;
            left: 50%;
            max-width: 80%;
            max-height: 80%;
            transform: translate(-50%, -50%);
          }
        }

        &:nth-child(6n) {
          margin-right: 0;
        }
      }
    }
  }

  //---
  //图书馆
  .tushu-img {
    width: 345px;
    height: 107px;
    margin: 0 auto;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .tushu {
    margin-top: 70px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .tushu-li {
      margin-right: 98px;
      margin-bottom: 69px;

      &:nth-child(6n) {
        margin-right: 0;
      }

      .tushu-li-img {
        width: 175px;
        height: 240px;
        cursor: pointer;
        transition: 0.3s;

        &:hover {
          transform: scale(104%);
          box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.16);
        }

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  //期刊
  .qikan-head {
    margin-top: 100px;
    margin-bottom: 10px;
    color: #595959;
    display: flex;
    align-items: center;

    .qikan-head-dian {
      width: 9px;
      height: 9px;
      background-color: #cf4549;
      margin-right: 10px;
      border-radius: 50%;
    }

    .qikan-head-text {
      font-size: 20px;
    }
  }

  .qikan {
    width: 100%;
    background: #f4f8f8;
    overflow: hidden;
    margin-bottom: 36px;
    padding: 35px 0;

    .qikan-ul {
      display: flex;
      flex-wrap: wrap;
    }

    .qikan-li {
      display: flex;
      padding: 18px;
      background-color: #fff;
      margin-bottom: 20px;
      cursor: pointer;
      border-radius: 9px;
      transition: 0.3s;

      &:nth-child(2) {
        margin: 0 37px 20px;
      }

      &:nth-child(5) {
        margin: 0 37px 20px;
      }

      &:hover {
        transform: scale(102%);
        box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.16);

        .qikan-li-right-text {
          color: red;
        }
      }

      .qikan-li-img {
        width: 123px;
        height: 169px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .qikan-li-right {
        margin-left: 20px;
        width: 309px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .qikan-li-right-text {
        }

        .qikan-li-right-bottom {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 14px;
          color: #1c1c1c;

          .qikan-li-right-bottom-name {
            font-size: 14px;
            color: #999999;
          }

          .qikan-li-right-bottom-num {
            color: #848484;
            display: flex;
            align-items: center;

            img {
              width: 18px;
              height: 18px;
              margin-right: 6px;
            }
          }
        }
      }
    }

    .qikan-btn {
      text-align: center;
      margin-top: 10px;

      .el-button {
        // background-image: linear-gradient(to right, #0ad28f, #00b277);
        background: linear-gradient(130deg, #2fd2c1 0%, #4986fe 100%);
        color: #fff;
        border: 1px solid #2fd2c1;
        border-radius: 21px;
      }
    }
  }
}
</style>
