<template>
  <div class="jifen">
    <h5 class="usertitle">积分管理</h5>
    <p class="ye">
      账户积分：<i>{{ scoll }}积分</i>
    </p>
    <!-- 以下是一个表格 -->
    <div v-if="tableData.length > 0">
      <el-table :data="tableData" style="width: 100%; margin-top: 34px">
        <el-table-column type="index" label="序号" min-width="10%">
        </el-table-column>
        <el-table-column label="文件名称" min-width="40%">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.remark }}</span>
          </template>
        </el-table-column>
        <el-table-column label="积分" min-width="10%">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.score }}</span>
          </template>
        </el-table-column>
        <el-table-column label="类型" min-width="20%">
          <template slot-scope="scope">
            <el-tag>{{ scope.row.memo }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" min-width="20%">
          <template slot-scope="scope">
            <i class="el-icon-time"></i>
            <span style="margin-left: 10px">{{ scope.row.createtime }}</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 这里边加一个分页 -->
      <div class="changepage">
        <el-pagination
          :hide-on-single-page="true"
          :current-page="this.page"
          :page-size="10"
          background
          layout="prev, pager, next"
          :total="total"
          @current-change="changepage"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 这里是空状态 -->
    <el-empty v-else description="暂无数据"></el-empty>
  </div>
</template>

<script>
import { jifenlist } from "../../api/createCenter";
export default {
  name: "jifen",
  data() {
    return {
      limit: "",
      page: 1,
      total: 0,
      tableData: [],
      scoll: "",
    };
  },
  created() {
    this.getjifen();
  },
  methods: {
    // 修改分页
    changepage(page) {
      this.page = page;
      this.getjifen();
    },
    async getjifen() {
      const res = await jifenlist(this.page);
      if (res.code === 1) {
        console.log(res.data);
        this.scoll = res.data.total_score;
        this.tableData = res.data.list.data;
        this.total = res.data.list.total;
        this.limit = res.data.list.per_page;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.ye {
  color: #b4b4b4;
  margin-top: 40px;
  font-size: 16px;
  margin-left: 12px;
  i {
    font-size: 16px;
    color: #3479f1;
    font-style: normal;
  }
}
.changepage {
  margin-top: 40px;
  text-align: center;
}
.el-tag {
  color: #3479f1;
  background-color: #d5f2ff;
  border: none;
}
</style>
